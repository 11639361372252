import clsx from "clsx";
import React from "react";
import { MdArrowDownward, MdArrowUpward } from "react-icons/md";
import { FaCoins } from "react-icons/fa6";
import {
  formatNumber,
  getCurrencyDataByCode,
  getTotalByTransactionType,
} from "../../helpers/utils";
import { EXPENSE_TYPE, ICON_SIZES, INCOME_TYPE } from "../../helpers/constants";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import Button from "../Button/Button";
import userPreferences from "../../store/userPreferences";
import useMainFilter from "../../hooks/useMainFilter";
import useCurrentUser from "../../hooks/useCurrentUser";

interface ISummaryBlocks {
  transactions: Array<any>;
}

const SummaryBlocks: React.FC<ISummaryBlocks> = ({ transactions }) => {
  const { getAccountById } = useCurrentUser();
  const { blurAmounts } = userPreferences();
  const { setMainFilter, mainFilter } = useMainFilter();

  const accountsCurrencies = transactions?.reduce?.((acc, current) => {
    const accountData = getAccountById(current.account);

    if (accountData) {
      const currencyIndex = _.findIndex(acc, { code: accountData.currency });

      if (currencyIndex !== -1) {
        if (current.type === INCOME_TYPE) {
          acc[currencyIndex].income =
            Number(acc[currencyIndex].income) + Number(current.total);
        } else if (current.type === EXPENSE_TYPE) {
          acc[currencyIndex].expenses =
            Number(acc[currencyIndex].expenses) + Number(current.total);
        }
      } else {
        acc.push({
          code: accountData.currency,
          name: getCurrencyDataByCode({ code: accountData.currency })?.name,
          symbol: getCurrencyDataByCode({ code: accountData.currency })?.symbol,
          income: current.type === INCOME_TYPE ? Number(current.total) : 0,
          expenses: current.type === EXPENSE_TYPE ? Number(current.total) : 0,
        });
      }
    }

    return acc;
  }, []);

  const availableCurrencies = () => {
    return (
      accountsCurrencies?.map?.((currency: any) => ({
        value: currency.code,
        label:
          getCurrencyDataByCode({ code: currency.code })?.name ||
          "Moneda desconocida",
      })) || []
    );
  };

  const totalIncome = getTotalByTransactionType({
    type: INCOME_TYPE,
    transactions,
  });
  const totalExpenses = getTotalByTransactionType({
    type: EXPENSE_TYPE,
    transactions,
  });
  const { t } = useTranslation();

  return (
    <div
      className={clsx([
        "grid",
        "grid-cols-4",
        "gap-2",
        "items-center",
        "justify-center",
        "mb-0",
        "py-2",
      ])}
    >
      <div
        className={clsx([
          "flex",
          "flex-col",
          "justify-center",
          "items-start",
          "rounded-full",
          "p-0",
          "gap-0",
          "col-span-2",
        ])}
      >
        {mainFilter?.type === INCOME_TYPE ? (
          <div
            className={clsx([
              "text-xs",
              "flex",
              "flex-row",
              "gap-1",
              "items-center",
              "justify-start",
              "w-full",
            ])}
            onClick={() => {
              setMainFilter({
                type: "",
              });
            }}
          >
            <div
              className={clsx([
                "text-xs",
                "font-medium",
                "p-2 pl-0",
                "flex",
                "flex-row",
                "gap-1",
                "text-gray-400",
                "capitalize",
              ])}
            >
              {/* <MdInfo size={ICON_SIZES.SM} className="flex-shrink-0 text-yellow-400" /> */}
              <div>{t("shared.showing_only_income")}</div>
            </div>
            <Button
              tooltip="Ver todo"
              icon={<FaCoins size={ICON_SIZES.SM} className="text-red-400" />}
              onClick={() => {
                setMainFilter({
                  type: "",
                });
              }}
              classes={clsx(["bg-transparent", "border-0"])}
            />
          </div>
        ) : (
          <>
            <div
              className={clsx(["flex", "flex-row", "items-center", "gap-1"])}
              onClick={() => {
                if (mainFilter?.type !== EXPENSE_TYPE) {
                  setMainFilter({
                    type: EXPENSE_TYPE,
                  });
                } else {
                  setMainFilter({
                    type: "",
                  });
                }
              }}
            >
              <div
                className={clsx([
                  "font-normal",
                  "text-sm",
                  "text-white",
                  "bg-red-400",
                  "rounded-full",
                  "px-2",
                  // "py-1",
                ])}
              >
                {t("summary.expenses")}
              </div>
              <MdArrowUpward size={32} className="text-red-400 " />
            </div>
            {availableCurrencies()?.length === 1 && (
              <div
                className={clsx([
                  "text-base",
                  "font-medium",
                  "text-red-500",
                  {
                    "blur-sm hover:blur-none": blurAmounts,
                  },
                ])}
              >
                {accountsCurrencies[0].symbol}
                {formatNumber(totalExpenses)}
              </div>
            )}
          </>
        )}
      </div>
      <div
        className={clsx([
          "flex",
          "flex-col",
          "items-center",
          "justify-center",
          "hidden",
        ])}
      >
        <>
          {totalIncome - totalExpenses < 0 && (
            <div
              className={clsx([
                "flex",
                "h-[65px]",
                "w-[65px]",
                "rounded-full",
                "bg-gray-800/90",
                "items-center",
                "justify-center",
                "relative",
                "before:animate-ping",
                "before:-z-10",
                "before:content-['']",
                "before:absolute",
                "before:w-[45px]",
                "before:h-[45px]",
                "before:rounded-full",
                "before:bg-yellow-400",
              ])}
            >
              {/* <FaDollarSign size={32} className={clsx(["text-yellow-400"])} /> */}
            </div>
          )}
        </>
        <>
          {totalIncome - totalExpenses >= 0 && (
            <div
              className={clsx([
                "flex",
                "h-[65px]",
                "w-[65px]",
                "rounded-full",
                "bg-gray-800/90",
                "items-center",
                "relative",
                "justify-center",
                "before:animate-ping",
                "before:-z-10",
                "before:content-['']",
                "before:absolute",
                "before:w-[45px]",
                "before:h-[45px]",
                "before:rounded-full",
                "before:bg-green-400",
              ])}
            >
              {/* <FaDollarSign size={32} className={clsx(["text-green-400"])} /> */}
            </div>
          )}
        </>
      </div>

      <div
        className={clsx([
          "flex",
          "flex-col",
          "justify-center",
          "items-end",
          // "bg-gray-800/90",
          "rounded-full",
          "0",
          "gap-0",
          "col-span-2",
        ])}
      >
        {mainFilter?.type === EXPENSE_TYPE ? (
          <div
            className={clsx([
              "text-xs",
              "flex",
              "flex-row",
              "gap-1",
              "items-center",
              "justify-end",
              "w-full",
            ])}
            onClick={() => {
              setMainFilter({
                type: "",
              });
            }}
          >
            <Button
              tooltip="Ver todo"
              icon={<FaCoins size={ICON_SIZES.SM} className="text-green-400" />}
              onClick={() => {
                setMainFilter({
                  type: "",
                });
              }}
              classes={clsx(["bg-transparent", "border-0"])}
            />
            <div
              className={clsx([
                "text-xs",
                "font-medium",
                "p-2 pr-0",
                "flex",
                "flex-row",
                "gap-1",
                "text-gray-400",
                "capitalize",
                "text-right",
              ])}
            >
              <div>{t("shared.showing_only_expenses")}</div>
              {/* <MdInfo size={ICON_SIZES.SM} className="flex-shrink-0 text-yellow-400" /> */}
            </div>
          </div>
        ) : (
          <>
            <div
              className={clsx(["flex", "flex-row", "items-center", "gap-1"])}
              onClick={() => {
                if (mainFilter?.type !== INCOME_TYPE) {
                  setMainFilter({
                    type: INCOME_TYPE,
                  });
                } else {
                  setMainFilter({
                    type: "",
                  });
                }
              }}
            >
              <MdArrowDownward size={32} className="text-green-400 " />
              <div
                className={clsx([
                  "font-normal",
                  "text-sm",
                  "text-white",
                  "bg-green-400",
                  "rounded-full",
                  "px-2",
                  // "py-1",
                ])}
              >
                {t("summary.incomes")}
              </div>
            </div>
            {availableCurrencies()?.length === 1 && (
              <div
                className={clsx([
                  "text-base",
                  "font-medium",
                  "text-green-500",
                  {
                    "blur-sm hover:blur-none": blurAmounts,
                  },
                ])}
              >
                {accountsCurrencies[0].symbol}
                {formatNumber(totalIncome)}
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default SummaryBlocks;
