import apiClient from "../helpers/api-client";
import {
  API_ENDPOINT_ALL_NOTES,
  API_ENDPOINT_DELETE_TASK,
  API_ENDPOINT_UPDATE_NOTE,
} from "../helpers/constants";

export const getNotes = async () => {
  try {
    return await apiClient.get(API_ENDPOINT_ALL_NOTES);
  } catch (_e) {
    throw _e;
  }
};

export const updateNote = async ({
  noteId,
  fieldsToUpdate = {},
}: {
  fieldsToUpdate: any;
  noteId: string;
}) => {
  try {
    return await apiClient.put(API_ENDPOINT_UPDATE_NOTE, {
      noteId,
      fieldsToUpdate,
    });
  } catch (_e) {
    throw _e;
  }
};

export const deleteNote = async ({ noteId }: { noteId: string }) => {
  try {
    return await apiClient.delete(`${API_ENDPOINT_DELETE_TASK}/${noteId}`);
  } catch (_e) {
    throw _e;
  }
};
