import clsx from "clsx";
import { ReactElement, useState } from "react";
import { SketchPicker } from "react-color";
import { rgbaObjectToString } from "../../helpers/utils";

interface IColor {
  r: any;
  g: any;
  b: any;
  a: any;
}

interface IColorPicker {
  color: IColor;
  onChange: (color: any) => void;
  label?: string | ReactElement;
  required?: boolean;
  textColor: IColor;
}

const ColorPicker: React.FC<IColorPicker> = ({
  color = { r: 0, g: 0, b: 0, a: 1 },
  onChange = () => {},
  label,
  required,
  textColor,
}) => {
  const [showColorPicker, setShowColorPicker] = useState<boolean>(false);

  return (
    <>
      <div>
        {label && (
          <label
            className={clsx(["text-sm", "text-gray-700", "font-medium"])}
            onClick={() => setShowColorPicker(!showColorPicker)}
          >
            {label}
            {required && <span className="ml-1 text-red-500">*</span>}
          </label>
        )}
        <div
          className={clsx([
            "flex",
            "w-full",
            "h-[40px] md:h-[46px]",
            // "p-1",
            "rounded-md",
            "cursor-pointer",
            "border",
            "border-gray-50",
          ])}
          onClick={() => setShowColorPicker(!showColorPicker)}
        >
          <div
            className={clsx([
              "flex",
              "w-full",
              "h-full",
              "rounded-md",
              "text-xs",
              "items-center",
              "justify-center",
            ])}
            style={{
              backgroundColor: rgbaObjectToString(color),
              color: rgbaObjectToString(textColor),
            }}
          >
            R: {color.r} G: {color.g} B: {color.b} A: {color.a}
          </div>
        </div>
        {showColorPicker && (
          <>
            <div
              className={clsx([
                "fixed",
                "top-0",
                "left-0",
                "right-0",
                "bottom-0",
                "bg-black/20",
                "z-10",
              ])}
              onClick={() => setShowColorPicker(false)}
            ></div>
            <div
              className={clsx([
                "md:absolute",
                "z-10",
                "fixed",
                "left-1/2",
                "top-1/2",
                "-translate-x-1/2",
                "-translate-y-1/2",
                "md:left-auto",
                "md:top-auto",
                "md:translate-x-0",
                "md:translate-y-0",
              ])}
            >
              <SketchPicker
                width="270px"
                color={color}
                onChange={(data) => {
                  onChange(data);
                }}
              />
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default ColorPicker;
