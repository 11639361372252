import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import moment from "moment";
import invoicesStore from "../../store/invoicesStore";
import {
  formatNumber,
  getCurrencyDataByCode,
  getDaysBetweenDates,
  rgbaObjectToString,
} from "../../helpers/utils";
import LoadingWithText from "../LoadingWithText/LoadingWithText";
import EndpointError from "../EndpointError/EndpointError";
import {
  CHART_COLORS,
  CHART_TRANSPARENCY_BG_COLOR,
  EXPENSE_TYPE,
  ICON_SIZES,
  INCOME_TYPE,
  PAGINATION_ROWS_PER_PAGE_OPTIONS,
} from "../../helpers/constants";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import clsx from "clsx";
import _ from "lodash";
import CellWithBackground from "../../pages/Transactions/BackgroundCell";
import DataTable from "react-data-table-component";
import { MdArrowDownward, MdFilterAlt, MdFilterAltOff } from "react-icons/md";
import TransactionTypeCell from "../../pages/Transactions/TransactionTypeCell";
import useMainFilter from "../../hooks/useMainFilter";
import ButtonGroupCell from "../../pages/Transactions/ButtonGroupCell";
import { useNavigate } from "react-router-dom";
import useLastSevenDaysFilter from "../../hooks/useLastSevenDaysFilter";
import useCurrentUser from "../../hooks/useCurrentUser";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

interface ILastSevenDaysChart {
  transactions: Array<any>;
  days?: number;
  dateStart?: string;
  onDatasetClick?: (params: any) => void;
  showBalance?: boolean;
  currency?: string;
}

const LastSevenDaysChart: React.FC<ILastSevenDaysChart> = ({
  transactions,
  days = 8,
  dateStart = "",
  onDatasetClick = () => {},
  showBalance = true,
  currency = "",
}) => {
  const {
    accounts: { data: allCurrentUserAccounts },
  } = useCurrentUser();
  const { mainFilter, setMainFilter } = useMainFilter();
  const { lastSevenDaysFilter, setLastSevenDaysFilter } =
    useLastSevenDaysFilter();
  const { isSearchingTransactions, errorSearchingTransactions } =
    invoicesStore();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const currencySymbol =
    getCurrencyDataByCode({
      code: currency,
    })?.symbol || "";

  const today = dateStart ? moment(dateStart) : moment().add(1, "days");
  const lastDay = today.format("yyyy-MM-DD");
  const firstDay = today.subtract(days, "days").format("yyyy-MM-DD");
  const hello = getDaysBetweenDates({
    startDate: firstDay,
    endDate: lastDay,
  })?.reverse?.();

  const labels =
    Array.from(
      new Set(
        hello?.map?.((date: any) => {
          return {
            weekDay: moment(date).weekday(),
            dayNumber: moment(date).format("D"),
            date: moment(date).format("yyyy-MM-DD"),
            dateFormatted:
              moment(date)?.format("yyyy-MM-DD") ===
              moment().format("yyyy-MM-DD")
                ? "Hoy"
                : moment().subtract(1, "day").format("yyyy-MM-DD") ===
                  moment(date)?.format("yyyy-MM-DD")
                ? "Ayer"
                : moment().subtract(2, "day").format("yyyy-MM-DD") ===
                  moment(date)?.format("yyyy-MM-DD")
                ? "Antier"
                : _.capitalize(
                    moment(moment(date)?.format("yyyy-MM-DD"))?.format?.("dddd")
                  ) +
                  " " +
                  moment(moment(date)?.format("yyyy-MM-DD"))?.format?.("DD"),
          };
        }) || []
      )
    ) || [];

  const datasets = () => {
    const test = [
      {
        key: INCOME_TYPE,
        name: t("last_7_days_chart.incomes"),
      },
      {
        key: EXPENSE_TYPE,
        name: t("last_7_days_chart.expenses"),
      },
    ];

    const accountsByCurrency = allCurrentUserAccounts?.data?.reduce?.(
      (acc: any, curr: any) => {
        if (curr?.currency === currency) {
          return [...acc, curr.account];
        }

        return acc;
      },
      []
    );

    const res = test.map(({ key: transactionType, name }) => ({
      label: _.capitalize(name),
      backgroundColor:
        transactionType === INCOME_TYPE
          ? rgbaObjectToString({
              ...CHART_COLORS.INCOME.bg,
              a: CHART_TRANSPARENCY_BG_COLOR,
            })
          : rgbaObjectToString({
              ...CHART_COLORS.EXPENSE.bg,
              a: CHART_TRANSPARENCY_BG_COLOR,
            }),
      borderColor:
        transactionType === INCOME_TYPE
          ? rgbaObjectToString({ ...CHART_COLORS.INCOME.bg })
          : rgbaObjectToString({ ...CHART_COLORS.EXPENSE.bg }),
      data: labels?.map?.((label) => {
        const filteredInvoices =
          transactions?.filter?.(({ date, account: theAccount, type }) => {
            return (
              date === label.date &&
              type === transactionType &&
              accountsByCurrency?.includes?.(theAccount)
            );
          }) || [];

        let total = 0;

        filteredInvoices?.forEach?.(({ total: theTotal }) => {
          if (transactionType === INCOME_TYPE) {
            total += Number(theTotal);
          } else if (transactionType === EXPENSE_TYPE) {
            total -= Number(theTotal);
          }
        });

        return total;
      }),
    }));

    const [INCOME, EXPENSE] = res;
    if (mainFilter?.type) {
      if (mainFilter?.type === EXPENSE_TYPE) {
        return [EXPENSE];
      } else if (mainFilter?.type === INCOME_TYPE) {
        return [INCOME];
      }
    }

    return [INCOME, EXPENSE];
  };

  const stillLoading = isSearchingTransactions;

  const tableData = () => {
    const [income, expense] = datasets();

    return labels
      ?.map?.((label: any, index: number) => {
        return {
          date: label.date,
          income: income?.data?.[index] || 0,
          expenses: Math.abs(expense?.data?.[index]) || 0,
        };
      })
      ?.filter?.(({ date }) =>
        lastSevenDaysFilter?.selectedDay
          ? lastSevenDaysFilter?.selectedDay === date
          : true
      );
  };

  const COLUMNS = [
    {
      name: t("shared.day"),
      selector: (row: any) => row.date,
      sortable: true,
      cell: (row: any) => {
        return (
          <CellWithBackground
            background={"#34d399"}
            color={"#fff"}
            value={
              row.date === moment().format("yyyy-MM-DD")
                ? "Hoy"
                : moment().subtract(1, "day").format("yyyy-MM-DD") === row.date
                ? "Ayer"
                : moment().subtract(2, "day").format("yyyy-MM-DD") === row.date
                ? "Antier"
                : _.capitalize(moment(row.date)?.format?.("dddd")) +
                  " " +
                  moment(row.date)?.format?.("DD")
            }
            onClick={() => {
              setMainFilter({
                dateFrom: row?.date,
                dateTo: row?.date,
              });
              navigate(`/transactions/${mainFilter?.account || ""}`);
            }}
          />
        );
      },
    },
    {
      name: t("shared.income"),
      selector: (row: any) => row.income,
      sortable: true,
      cell: (row: any) => {
        return (
          <TransactionTypeCell currency={currencySymbol} type={INCOME_TYPE}>
            {formatNumber(row.income)}
          </TransactionTypeCell>
        );
      },
      right: true,
      omit: mainFilter?.type === EXPENSE_TYPE,
    },
    {
      name: t("shared.expenses"),
      right: true,
      selector: (row: any) => row.expense,
      sortable: true,
      cell: (row: any) => {
        return (
          <TransactionTypeCell currency={currencySymbol} type={EXPENSE_TYPE}>
            {formatNumber(row.expenses)}
          </TransactionTypeCell>
        );
      },
      omit: mainFilter?.type === INCOME_TYPE,
    },

    {
      name: "Filtrar",
      omit: false,
      cell: (row: any) => {
        return (
          <div
            className={clsx(["flex", "w-full", "justify-end", "items-center"])}
          >
            <ButtonGroupCell
              buttons={[
                {
                  onClick: (e: any) => {
                    if (lastSevenDaysFilter?.selectedDay === row?.date) {
                      setLastSevenDaysFilter({
                        selectedDay: "",
                      });
                    } else {
                      setLastSevenDaysFilter({
                        selectedDay: row?.date,
                      });
                    }
                  },
                  icon:
                    lastSevenDaysFilter?.selectedDay === row?.date ? (
                      <MdFilterAltOff size={ICON_SIZES.SM} />
                    ) : (
                      <MdFilterAlt size={ICON_SIZES.SM} />
                    ),
                },
              ]}
            />
          </div>
        );
      },
    },
  ];

  const options = {
    responsive: true,
    onClick: (e: any, el: any) => {
      const points = e.chart.getElementsAtEventForMode(
        e,
        "nearest",
        { intersect: true },
        true
      );

      if (points.length) {
        const [firstPoint] = points;
        const label = e.chart.data.labels[firstPoint.index];
        const value =
          e.chart.data.datasets[firstPoint.datasetIndex].data[firstPoint.index];

        onDatasetClick({ label, value, details: labels[firstPoint.index] });
      }
    },
    maintainAspectRatio: false,
    pointRadius: 15,
    pointHoverRadius: 30,
    interaction: {
      mode: "index" as const,
      intersect: false,
    },
    borderColor: "#444",
    borderWidth: 1,
    elements: {
      line: {
        tension: 0.5,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      datalabels: {
        display: false,
      },
      tooltip: {
        backgroundColor: rgbaObjectToString(CHART_COLORS.TOOLTIP.bg),
        usePointStyle: false,
        padding: 15,
        boxPadding: 4,
        callbacks: {
          label: (context: any) => {
            const label = context?.dataset?.label || "";
            const value = Number(context?.parsed?.y) || 0;

            const formattedTooltip = formatNumber(Math.abs(Number(value)));

            return `${label}: ${formattedTooltip}`;
          },

          title: (datasets: any) => {
            const [title] = datasets;
            return `${_.capitalize(title.label)}`;
          },

          // footer: (datasets: any) => {
          //   return `Transacciones por día`;
          // },
        },
      },
    },
    animations: {
      tension: {
        duration: 2000,
        easing: "linear" as "linear",
        from: 1,
        to: 0.5,
        loop: true,
      },
    },
    animation: {
      duration: 0,
    },
  };
  const mobileOptions = {
    ...options,
    pointRadius: 15,
    pointHoverRadius: 30,
  };

  const currentLanguage = i18next.language?.toLowerCase?.() || "en";

  return stillLoading ? (
    <LoadingWithText />
  ) : errorSearchingTransactions ? (
    <EndpointError message="No se pudo cargar la lista de transacciones." />
  ) : (
    <div className={clsx("flex", "flex-col", "gap-4")}>
      <div className={clsx(["flex"])}>
        <Line
          height={200}
          options={mobileOptions}
          data={{
            datasets: datasets(),
            labels: labels.map((label) =>
              _.capitalize(`${label?.dateFormatted}`)
            ),
          }}
        />
      </div>
      <DataTable
        // conditionalRowStyles={conditionalStyles}
        // striped
        sortIcon={<MdArrowDownward />}
        columns={COLUMNS}
        data={tableData()}
        // pagination={localSearch?.length > PAGINATION_MIN}
        // progressPending={isLoading}
        // selectableRowSelected={defaultSelectedRows}
        selectableRows
        keyField="invoice"
        selectableRowsHighlight
        // onSelectedRowsChange={onSelectionChange}
        clearSelectedRows
        highlightOnHover={false}
        onChangeRowsPerPage={(rows) => {
          // setTableResultsPerPage(rows);
        }}
        paginationComponentOptions={{
          selectAllRowsItem: true,
          selectAllRowsItemText: t("pagination.all"),
          rowsPerPageText: t("pagination.rows_per_page"),
          rangeSeparatorText: t("pagination.separator"),
        }}
        // paginationPerPage={tableResultsPerPage}
        selectableRowsVisibleOnly
        persistTableHead
        paginationRowsPerPageOptions={PAGINATION_ROWS_PER_PAGE_OPTIONS}
        noDataComponent={
          <div
            className={clsx([
              "px-2",
              "py-4",
              "flex",
              "items-center",
              "justify-center",
              "text-sm",
              "md:text-base",
            ])}
          >
            {t("empty_states.no_records")}
          </div>
        }
      />
    </div>
  );
};

export default LastSevenDaysChart;
