import clsx from "clsx";
import { useEffect, useState } from "react";
import {
  APP_LOCAL_STORAGE_USER_DATA,
  APP_TOKEN_KEY,
  APP_USER_ID_KEY,
} from "../../helpers/constants";
import LoadingWithText from "../../components/LoadingWithText/LoadingWithText";
import { Navigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "@tanstack/react-query";

const LogoutPage: React.FC = () => {
  const [readyToRedirect, setReadyToRedirect] = useState<boolean>(false);
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const resetCache = async () => {
    return await queryClient.resetQueries();
  };

  const cleanUpSession = async () => {
    localStorage?.removeItem?.(APP_TOKEN_KEY);
    localStorage?.removeItem?.(APP_USER_ID_KEY);
    localStorage?.removeItem?.(APP_LOCAL_STORAGE_USER_DATA);
    await resetCache();
    setReadyToRedirect(true);
  };

  useEffect(() => {
    cleanUpSession();
  }, []);

  return (
    <div
      className={clsx([
        "flex",
        "h-screen",
        "w-full",
        "items-center",
        "justify-center",
      ])}
    >
      <LoadingWithText text={t("shared.closing_session")} />
      {readyToRedirect && <Navigate to="/" replace />}
    </div>
  );
};

export default LogoutPage;
