import clsx from "clsx";
import TransactionsByTypeChart from "../Charts/TransactionsByTypeChart";
import AccountsSummaryTable from "../InvoicesChart/AccountsSummaryTable";

interface ITransactionsByTypeReport {
  transactions: Array<any>;
  currency: string;
}

const TransactionsByTypeReport: React.FC<ITransactionsByTypeReport> = ({
  transactions,
  currency,
}) => {
  return (
    <div className={clsx(["flex", "w-full", "flex-col", "gap-4"])}>
      <TransactionsByTypeChart
        transactions={transactions}
        currency={currency}
      />
      <AccountsSummaryTable transactions={transactions} currency={currency} />
    </div>
  );
};

export default TransactionsByTypeReport;
