import { useQuery, useQueryClient } from "@tanstack/react-query";
import { APP_USER_ID_KEY, CACHE_KEYS } from "../helpers/constants";
import { getAllUserAccounts, getUser } from "../api/users";
import _ from "lodash";
import userPreferences from "../store/userPreferences";
import { useMemo } from "react";

export default function useCurrentUser() {
  const queryClient = useQueryClient();
  const currentUserId = localStorage?.getItem?.(APP_USER_ID_KEY) || "";
  const {
    lastUsedAccount,
    setLastUsedAccount,
    defaultAccount,
    setDefaultAccount,
  } = userPreferences();

  const user = useQuery({
    queryFn: async () => await getUser({ _id: currentUserId }),
    queryKey: [CACHE_KEYS.CURRENT_USER],
  });

  const accounts = useQuery({
    queryFn: async () => {
      return getAllUserAccounts({ _id: currentUserId });
    },
    queryKey: [CACHE_KEYS.CURRENT_USER_ACCOUNTS],
  });

  const getAccountById = (account: any) => {
    return _.find(accounts?.data?.data, { account });
  };

  const resetCache = async () => {
    return await Promise.allSettled([
      await queryClient.refetchQueries({
        queryKey: [CACHE_KEYS.CURRENT_USER_ACCOUNTS],
      }),
      await queryClient.refetchQueries({ queryKey: [CACHE_KEYS.CURRENT_USER] }),
    ]);
  };

  const getPreselectedAccountForNewTransaction = useMemo(() => {
    if (lastUsedAccount) {
      return lastUsedAccount;
    }
    if (defaultAccount) {
      return defaultAccount;
    }

    if (accounts?.data?.data?.length) {
      const [firstAccount] = accounts?.data?.data;

      return firstAccount?.account;
    }

    return "";
  }, [lastUsedAccount, defaultAccount, accounts?.data?.data]);

  return {
    id: currentUserId,
    userInfo: user?.data?.data?.data,
    accounts: accounts,
    enabledPaymentMethods:
      user?.data?.data?.data?.settings?.payment_methods || [],
    getAccountById,
    defaultAccount,
    lastUsedAccount,
    setDefaultAccount,
    setLastUsedAccount,
    preselectedAccount: getPreselectedAccountForNewTransaction,
    resetCache,
    accountsAreLoading: !!accounts?.isLoading,
  };
}
