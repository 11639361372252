import { MdCheck, MdClose, MdFavorite } from "react-icons/md";
import Modal from "../Modal/Modal";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { ICON_SIZES } from "../../helpers/constants";

interface IConfirmDialog {
  title: string;
  onClose: () => void;
  onConfirm: () => void;
  description: string;
  confirmKind?: string;
  isLoading?: boolean;
  icon?: ReactElement;
  cancelLabel?: string;
  showCancel?: boolean;
}

const ConfirmDialog: React.FC<IConfirmDialog> = ({
  title,
  onClose,
  onConfirm,
  description,
  confirmKind = "save",
  isLoading = false,
  cancelLabel = "",
  showCancel = true,
  icon = <MdFavorite size={28} className="text-red-100" />,
}) => {
  const { t } = useTranslation();

  return (
    <Modal
      width="max-w-[700px] lg:min-w-[500px]"
      onEscape={() => {
        if (!isLoading && showCancel) {
          onClose();
        }
      }}
      className="!shadow-md !rounded-lg"
    >
      <Modal.Header
        onClose={() => {
          if (!isLoading && showCancel) {
            onClose();
          }
        }}
        isClosable={showCancel}
        title={title}
        // icon={
        //   confirmKind === "danger" && !icon ? (
        //     <MdWarning size={28} className="text-red-500" />
        //   ) : (
        //     icon
        //   )
        // }
      />
      <Modal.Body>
        <p className="text-sm md:text-base">{description}</p>
      </Modal.Body>
      <Modal.Footer
        actions={[
          {
            kind: "normal",
            onClick: onClose,
            text: cancelLabel || t("shared.cancel"),
            icon: <MdClose size={ICON_SIZES.MD} />,
            disabled: isLoading,
            hide: !showCancel,
          },
          {
            kind: confirmKind,
            onClick: onConfirm,
            text: t("shared.confirm"),
            icon: <MdCheck size={ICON_SIZES.MD} />,
            isLoading,
            disabled: isLoading,
            autoFocus: true,
          },
        ]}
      />
    </Modal>
  );
};

export default ConfirmDialog;
