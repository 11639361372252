import DataTable from "react-data-table-component";
import { EXPENSE_TYPE, ICON_SIZES, INCOME_TYPE } from "../../helpers/constants";
import { MdArrowDownward, MdFilterAlt, MdFilterAltOff } from "react-icons/md";
import { useTranslation } from "react-i18next";
import CellWithBackground from "../../pages/Transactions/BackgroundCell";

import TransactionTypeCell from "../../pages/Transactions/TransactionTypeCell";
import {
  formatNumber,
  getCurrencyDataByCode,
  getFirstAndLastDayOfMonth,
  getTotalByMonth,
} from "../../helpers/utils";
import moment from "moment";
import i18next from "i18next";
import _ from "lodash";
import { useNavigate } from "react-router-dom";
import ButtonGroupCell from "../../pages/Transactions/ButtonGroupCell";
import clsx from "clsx";
import userPreferences from "../../store/userPreferences";
import useMainFilter from "../../hooks/useMainFilter";
import TableSearch from "../TableSearch/TableSearch";
import { useCallback, useMemo, useState } from "react";
import useYearFilter from "../../hooks/useYearFilter";

interface IMonthlySummaryTable {
  transactions: Array<any>;
  currency: string;
}

const MonthlySummaryTable: React.FC<IMonthlySummaryTable> = ({
  transactions,
  currency,
}) => {
  const { yearFilter } = useYearFilter();
  const { setMainFilter, mainFilter } = useMainFilter();
  const { blurAmounts } = userPreferences();
  const currentLanguage = i18next.language?.toLowerCase?.() || "en";
  const [searchTerm, setSearchTerm] = useState<string>("");

  const currencySymbol =
    getCurrencyDataByCode({
      code: currency,
    })?.symbol || "";

  const formattedData = useCallback(() => {
    return [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
      ?.map((month: any) => {
        const expenses = getTotalByMonth({
          month: month,
          type: EXPENSE_TYPE,
          transactions,
        });

        const income = getTotalByMonth({
          month: month,
          type: INCOME_TYPE,
          transactions,
        });

        return {
          monthNumber: month,
          month: _.capitalize(
            `${moment(month, "MM").locale(currentLanguage).format("MMMM")}`
          ),
          income,
          expense: expenses,
          balance: income - expenses,
        };
      })
      ?.reduce?.((acc: any, curr: any) => {
        if (!curr.income && !curr.expense) {
          return acc;
        } else {
          return [...acc, curr];
        }
      }, []);
  }, [transactions, currentLanguage]);

  const navigate = useNavigate();

  const { t } = useTranslation();
  const COLUMNS = [
    {
      cell: (row: any) => {
        return (
          <CellWithBackground
            background={"#34d399"}
            color={"#fff"}
            value={_.capitalize(row.month)}
            onClick={() => {
              const { firstDay, lastDay } = getFirstAndLastDayOfMonth({
                year: yearFilter,
                month: row?.monthNumber,
              });

              setMainFilter({ dateFrom: firstDay, dateTo: lastDay });
              navigate(`/transactions/${mainFilter?.account || ""}`);
            }}
          />
        );
      },
      selector: (row: any) => row.month,
      name: t("shared.month"),
      sortable: true,
    },
    {
      name: t("shared.income"),
      selector: (row: any) => row.income,
      sortable: true,
      cell: (row: any) => {
        return (
          <TransactionTypeCell currency={currencySymbol} type={INCOME_TYPE}>
            {formatNumber(row.income)}
          </TransactionTypeCell>
        );
      },
      right: true,
      omit: mainFilter?.type === EXPENSE_TYPE,
    },
    {
      right: true,
      name: t("shared.expenses"),
      selector: (row: any) => row.expense,
      sortable: true,
      cell: (row: any) => {
        return (
          <TransactionTypeCell currency={currencySymbol} type={EXPENSE_TYPE}>
            {formatNumber(row.expense)}
          </TransactionTypeCell>
        );
      },
      omit: mainFilter?.type === INCOME_TYPE,
    },
    {
      right: true,
      cell: (row: any) => {
        const isRed = row.balance < 0;

        return (
          <CellWithBackground
            background={isRed ? "rgb(239, 68, 68)" : "rgb(34, 197, 94)"}
            color={"#fff"}
            value={`${currencySymbol}${formatNumber(row.balance)}`}
            blur={blurAmounts}
          />
        );
      },
      selector: (row: any) => row.balance,
      name: t("shared.difference"),
      sortable: true,
      omit: !!mainFilter?.type,
    },
    {
      omit: false,
      cell: (row: any) => {
        return (
          <div
            className={clsx(["flex", "w-full", "justify-end", "items-center"])}
          >
            <ButtonGroupCell
              buttons={[
                // {
                //   icon: <MdAttachment size={ICON_SIZES.SM} />,
                //   onClick: () => {},
                //   hide: !row?.attachments?.length,
                // },
                {
                  onClick: (e: any) => {
                    const { firstDay, lastDay } = getFirstAndLastDayOfMonth({
                      year: yearFilter,
                      month: row?.monthNumber,
                    });

                    if (
                      mainFilter?.dateFrom === firstDay &&
                      mainFilter?.dateTo === lastDay
                    ) {
                      setMainFilter({ dateFrom: "", dateTo: "" });
                    } else {
                      setMainFilter({ dateFrom: firstDay, dateTo: lastDay });
                    }
                  },

                  icon:
                    mainFilter?.dateFrom ===
                      getFirstAndLastDayOfMonth({
                        year: yearFilter,
                        month: row?.monthNumber,
                      })?.firstDay &&
                    mainFilter?.dateTo ===
                      getFirstAndLastDayOfMonth({
                        year: yearFilter,
                        month: row?.monthNumber,
                      })?.lastDay ? (
                      <MdFilterAltOff size={ICON_SIZES.SM} />
                    ) : (
                      <MdFilterAlt size={ICON_SIZES.SM} />
                    ),
                },
              ]}
            />
          </div>
        );
      },
      selector: (row: any) => row,
      name: "",
      sortable: false,
      right: true,
    },
  ];

  const localSearch = useMemo(() => {
    const lowerCaseTerm = searchTerm?.toLowerCase?.()?.trim?.();
    const data = formattedData();

    if (lowerCaseTerm) {
      return (
        data?.filter?.(({ month }: { month: any }) => {
          return month?.toLowerCase?.()?.includes?.(lowerCaseTerm);
        }) || []
      );
    }

    return data;
  }, [formattedData, searchTerm]);

  return (
    <div className={clsx(["flex", "flex-col", "gap-2"])}>
      <TableSearch
        onChange={(value) => setSearchTerm(value)}
        onSubmit={() => {}}
      />
      <DataTable
        columns={COLUMNS}
        data={localSearch}
        pagination={false}
        highlightOnHover={false}
        persistTableHead
        sortIcon={<MdArrowDownward />}
        selectableRowsVisibleOnly
        paginationRowsPerPageOptions={[]}
        paginationPerPage={12}
        paginationComponentOptions={{
          selectAllRowsItem: true,
          selectAllRowsItemText: t("pagination.all"),
          rowsPerPageText: t("pagination.rows_per_page"),
          rangeSeparatorText: t("pagination.separator"),
        }}
        noDataComponent={
          <div
            className={clsx([
              "px-2",
              "py-4",
              "flex",
              "items-center",
              "justify-center",
              "text-sm",
              "md:text-base",
            ])}
          >
            {t("empty_states.no_records")}
          </div>
        }
        // striped
      />
    </div>
  );
};

export default MonthlySummaryTable;
