import { useQuery, useQueryClient } from "@tanstack/react-query";
import { getAllInvites } from "../api/invites";
import {
  getAcceptedInvites,
  getReceivedPendingInvites,
} from "../helpers/utils";
import { APP_USER_ID_KEY, CACHE_KEYS } from "../helpers/constants";

const REFETCH_MINUTES = 1.5;
const REFETCH_INTERVAL = REFETCH_MINUTES * 1000 * 60;

export default function useInvites() {
  const queryClient = useQueryClient();
  const currentUserId = localStorage?.getItem?.(APP_USER_ID_KEY) || "";

  const invites = useQuery({
    queryFn: async () => await getAllInvites(),
    queryKey: [CACHE_KEYS.ALL_INVITES],
    refetchInterval: REFETCH_INTERVAL,
  });

  const resetCache = async () => {
    return await queryClient.refetchQueries({
      queryKey: [CACHE_KEYS.ALL_INVITES],
    });
  };

  const acceptedInvites = getAcceptedInvites({
    invites: invites?.data?.data?.data || [],
    user: currentUserId,
  });

  const receivedInvites = getReceivedPendingInvites({
    invites: invites?.data?.data?.data || [],
    user: currentUserId,
  });

  return {
    allInviteList: invites,
    resetCache,
    acceptedInvites,
    receivedInvites,
  };
}
