import { useQuery, useQueryClient } from "@tanstack/react-query";
import { getCategories } from "../api/categories";
import _ from "lodash";
import { CACHE_KEYS } from "../helpers/constants";

export default function useCategories() {
  const queryClient = useQueryClient();

  const categories = useQuery({
    queryFn: async () => await getCategories(),
    queryKey: [CACHE_KEYS.ALL_CATEGORIES],
  });

  const getCategoryByName = (category: string) => {
    const allCategories = categories?.data?.data;

    return _.find(allCategories, { category });
  };

  const resetCache = async () => {
    return await queryClient.refetchQueries({
      queryKey: [CACHE_KEYS.ALL_CATEGORIES],
    });
  };

  return {
    allCategoryList: categories,
    getCategoryByName,
    resetCache,
  };
}
