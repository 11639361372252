import {
  MdAccountBox,
  MdArrowBack,
  MdAttachMoney,
  MdCheck,
  MdCompareArrows,
  MdOutlineShoppingCart,
  MdPhotoLibrary,
  MdViewSidebar,
} from "react-icons/md";
import PageTitle from "../../components/PageTitle/PageTitle";
import TwoSidebars from "../../layouts/TwoSidebars/TwoSidebars";
import ButtonGroup from "../../components/ButtonGroup/ButtonGroup";
import { useNavigate } from "react-router-dom";
import userPreferences from "../../store/userPreferences";
import clsx from "clsx";
import { useMediaQuery } from "react-responsive";
import TextInput from "../../components/TextInput/TextInput";
import Select from "../../components/Select/Select";
import { useTranslation } from "react-i18next";
import { useEffect, useId, useMemo, useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import LoadingWithText from "../../components/LoadingWithText/LoadingWithText";
import apiClient from "../../helpers/api-client";
import {
  API_ENDPOINT_UPDATE_PROFILE,
  BUTTON_STYLES,
  FILE_STORAGE_SERVER_URL,
  ICON_SIZES,
  MOBILE_BREAKPOINT_MEDIA_QUERY,
} from "../../helpers/constants";
import {
  COUNTRY_SELECT_OPTIONS,
  getAllTimezones,
  showNotification,
} from "../../helpers/utils";
import SettingsBlocks from "../../components/SettingsBlocks/SettingsBlocks";
import ConfirmDialog from "../../components/ConfirmDialog/ConfirmDialog";
import { CONFIRM_DIALOG_ICON_SIZE } from "../../constants/icons";
import SectionTitle from "../../components/SectionTitle/SectionTitle";
import { QRCodeSVG } from "qrcode.react";
import useCurrentUser from "../../hooks/useCurrentUser";
import IconSelector from "../../components/IconSelector/IconSelector";

const FORM_ID = "update-profile";

const ProfilePage: React.FC = () => {
  const navigate = useNavigate();
  const { userInfo } = useCurrentUser();
  const isMobile = useMediaQuery({ query: MOBILE_BREAKPOINT_MEDIA_QUERY });
  const [country, setCountry] = useState<string>(userInfo?.country || "");
  const [name, setName] = useState<string>(userInfo?.name || "");
  const [picture, setPicture] = useState<string>(
    userInfo?.picture
      ? `${FILE_STORAGE_SERVER_URL}/profile-thumbnail-${userInfo?.picture}`
      : ""
  );
  const [intro, setIntro] = useState<string>(userInfo?.intro || "");
  const [mobilePhone, setMobilePhone] = useState<string>(
    userInfo?.mobile || ""
  );
  const [email, setEmail] = useState<string>(userInfo?.email || "");
  const [timezone, setTimezone] = useState<string>(userInfo?.timezone || "");

  const allTimezones = useMemo(
    () => getAllTimezones()?.map?.((tz: string) => ({ value: tz, label: tz })),
    []
  );

  useEffect(() => {
    setName(userInfo?.name || "");
    setPicture(
      userInfo?.picture
        ? `${FILE_STORAGE_SERVER_URL}/profile-thumbnail-${userInfo?.picture}`
        : ""
    );
    setEmail(userInfo?.email || "");
    setMobilePhone(userInfo?.mobile || "");
    setIntro(userInfo?.intro || "");
    setCountry(userInfo?.country || "");
    setTimezone(userInfo?.timezone);
  }, [userInfo]);

  const [isUpdatingProfile, setIsUpdatingProfile] = useState<boolean>(false);
  const [file, setFile] = useState<any>(null);
  const [showConfirmDialog, setShowConfirmDialog] = useState<boolean>(false);
  const tooltipId = useId();
  const { t } = useTranslation();
  const { resetCache } = useCurrentUser();

  const { rightPanelIsVisible, setRightPanelIsVisible } = userPreferences();

  const updateProfile = async () => {
    setIsUpdatingProfile(true);

    const formData = new FormData();

    if (file) {
      formData.append("file", file);
    }

    formData.append("name", name);
    formData.append("email", email);
    formData.append("country", country);
    formData.append("intro", intro);
    formData.append("mobile", mobilePhone);
    formData.append("timezone", timezone);

    return apiClient
      .put(API_ENDPOINT_UPDATE_PROFILE, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then(async (res: any) => {
        await resetCache();

        showNotification({
          kind: "success",
          message: "Perfil actualizado correctamente",
        });
        setShowConfirmDialog(false);
      })
      .catch((e) => {
        showNotification({
          message: "Error actualizando tu perfil",
          kind: "error",
        });
      })
      .finally(() => {
        setIsUpdatingProfile(false);
      });
  };

  return (
    <>
      <TwoSidebars
        right={
          <>
            {<SettingsBlocks layout="vertical" columns={isMobile ? 2 : 1} />}
            {/* <TasksListPanel />
            <CustomLinks /> */}
          </>
        }
      >
        <div className={clsx(["flex", "w-full", "flex-col", "gap-0"])}>
          <PageTitle
            title={t("settings.title")}
            subtitle={t("profile_page.title")}
            titleLink="/settings"
            isLoading={false}
            rightSide={
              <>
                <ButtonGroup
                  align="right"
                  gap={1}
                  buttons={[
                    {
                      onClick: () => {
                        const el = document?.getElementById?.(
                          "select-file-to-upload"
                        );

                        if (el) {
                          el?.click?.();
                        }
                      },

                      icon: <MdPhotoLibrary size={ICON_SIZES.SM} />,
                      tooltip: "Selecciona una foto",
                      classes: BUTTON_STYLES.HEADING,
                    },
                    {
                      icon: <MdArrowBack size={ICON_SIZES.SM} />,
                      tooltip: "Atras",
                      onClick: () => {
                        navigate("/settings");
                      },
                      hide: !isMobile,
                      classes: isMobile ? BUTTON_STYLES.HEADING : "",
                    },
                    {
                      icon: <MdOutlineShoppingCart size={ICON_SIZES.SM} />,
                      tooltip: "Añadir gasto",
                      onClick: () => {
                        navigate("/transactions/expense");
                      },
                      hide: isMobile,
                    },

                    {
                      icon: <MdAttachMoney size={ICON_SIZES.SM} />,
                      tooltip: "Añadir ingreso",
                      hide: isMobile,
                      onClick: () => {
                        navigate("/transactions/income");
                      },
                    },
                    {
                      icon: <MdCompareArrows size={ICON_SIZES.SM} />,
                      tooltip: "Transacciones",
                      onClick: () => {
                        navigate("/transactions");
                      },
                      hide: isMobile,
                    },

                    {
                      icon: <MdViewSidebar size={ICON_SIZES.SM} />,
                      tooltip: "Panel lateral",
                      kind: rightPanelIsVisible ? "primary" : "secondary",
                      hide: isMobile,
                      onClick: () => {
                        setRightPanelIsVisible(!rightPanelIsVisible);
                      },
                    },
                  ]}
                />
              </>
            }
          />
          <div className="grid grid-cols-1 gap-4">
            <input
              className="hidden"
              accept="image/*"
              type="file"
              id="select-file-to-upload"
              onChange={async (e: any) => {
                const attachment = e?.target?.files;

                if (attachment?.length) {
                  setFile(attachment?.[0]);
                  setPicture(URL.createObjectURL(attachment?.[0]));
                } else {
                  setFile(null);
                  setPicture("");
                }
              }}
              required
            />
            <SectionTitle title={t("profile_page.personal_info_title")} />

            <div className={clsx(["grid", "grid-cols-2", "gap-2"])}>
              <div className={clsx(["flex", "justify-start", "items-center"])}>
                {picture ? (
                  <LazyLoadImage
                    alt=""
                    className={clsx([
                      "w-full max-w-[200px] max-h-[200px] md:w-[200px]",
                      "rounded-md",
                      "shadow-md",
                      "cursor-pointer",
                      // "rounded-full",
                      "bg-gray-300",
                      "!h-auto !w-[100%] !max-h-[200px] !max-w-[200px]",
                      // "mb-5 md:mb-10",
                      "object-cover",
                    ])}
                    data-tooltip-id={tooltipId}
                    data-tooltip-content="Insertar imagen"
                    data-tooltip-float
                    data-tooltip-position-strategy="fixed"
                    data-tooltip-offset={20}
                    src={picture}
                    placeholder={
                      <div
                        className={clsx([
                          "flex",
                          "h-full",
                          "flex",
                          "items-center",
                          "justify-center",
                          "w-full",
                        ])}
                      >
                        <LoadingWithText />
                      </div>
                    }
                    onClick={() => {
                      const el = document?.getElementById?.(
                        "select-file-to-upload"
                      );

                      if (el) {
                        el?.click?.();
                      }
                    }}
                  />
                ) : (
                  <div
                    className={clsx([
                      "w-[150px]",
                      "max-w-[200px]",
                      "inline-flex",
                      "items-center",
                      "justify-start",
                    ])}
                  >
                    <MdAccountBox
                      onClick={() => {
                        const el = document?.getElementById?.(
                          "select-file-to-upload"
                        );

                        if (el) {
                          el?.click?.();
                        }
                      }}
                      size={150}
                      className="text-gray-600"
                    />
                  </div>
                )}
              </div>
              <div
                className={clsx([
                  "flex",
                  "justify-center",
                  "flex-col",
                  "gap-2",
                  "items-center",
                ])}
              >
                <div>
                  <QRCodeSVG
                    value="Hola mundo"
                    title={"eXpenser"}
                    bgColor={"#fff"}
                    fgColor={"#666"}
                    width={90}
                    level={"L"}
                    type="text"
                  />
                </div>
                {/* <ButtonGroup
                  align="center"
                  gap={1}
                  buttons={[
                    {
                      onClick: () => {},
                      icon: <MdShare size={ICON_SIZES.SM} />,
                      hide: true,
                    },
                    {
                      onClick: () => {
                        navigate("/settings/password");
                      },
                      text: "Contraseña",
                      icon: <MdPassword size={ICON_SIZES.SM} />,
                    },
                  ]}
                /> */}
              </div>
            </div>
            <div className="hiddens">
              <details>
                <summary
                  className={clsx([
                    "text-sm",
                    "md:text-base",
                    "text-gray-700",
                    "font-medium",
                    "items-center",
                  ])}
                >
                  Iconos
                </summary>
                <IconSelector
                  onClick={(icon) => {}}
                  background={""}
                  selected={"MdHome"}
                  color={""}
                />
              </details>
            </div>

            <div
              className={clsx(["flex", "flex-col", "gap-4", "items-center"])}
            >
              <div
                className={clsx([
                  "flex",
                  "flex-col",
                  "w-full",
                  "gap-4",
                  // "bg-red-400",
                ])}
              >
                <div>
                  <form
                    className={clsx([
                      "grid",
                      "grid-cols-1",
                      "md:grid-cols-2",
                      "gap-2",
                    ])}
                    id={FORM_ID}
                    onSubmit={async (e: any) => {
                      e?.preventDefault?.();
                      setShowConfirmDialog(true);
                    }}
                  >
                    <div className={clsx(["grid", "grid-cols-2", "gap-2"])}>
                      <TextInput
                        value={name}
                        onChange={(e: any) => setName(e?.target?.value)}
                        label={t("profile_page.name_label")}
                        placeholder={t("profile_page.name_placeholder")}
                        required
                        autoComplete="name nickname"
                      />
                      <TextInput
                        value={mobilePhone}
                        onChange={(e: any) => setMobilePhone(e?.target?.value)}
                        label={t("Teléfono móvil")}
                        placeholder={t("Digita tu número de teléfono móvil")}
                        autoComplete="tel tel-country-code"
                        type="tel"
                      />
                      <Select
                        options={COUNTRY_SELECT_OPTIONS}
                        value={country}
                        onChange={(e: any) => setCountry(e?.target?.value)}
                        label={t("profile_page.country_label")}
                        autoComplete="country"
                      />
                      <Select
                        options={allTimezones}
                        value={timezone}
                        onChange={(e: any) => setTimezone(e?.target?.value)}
                        label={t("Zona horaria")}
                      />
                    </div>
                    <TextInput
                      value={email}
                      onChange={(e: any) => setEmail(e?.target?.value)}
                      label={t("Correo electrónico")}
                      type="email"
                      placeholder={t("Digita tu correo electrónico")}
                      autoComplete="email"
                    />
                    <textarea
                      // x-webkit-speech
                      className={clsx([
                        "w-full",
                        "border",
                        "p-2",
                        "md:p-4",
                        "text-sm",
                        "md:text-base",
                        "rounded-sm",
                        "placeholder:text-gray-400",
                        "placeholder:text-sm",
                        "placeholder:font-light",
                        "h-[80px]",
                        "md:h-[100px]",
                        "mt-2",
                      ])}
                      defaultValue={intro}
                      onChange={(e) => setIntro(e?.target?.value)}
                      placeholder={
                        "Puedes ingresar una descripción breve de quién eres o lo qué haces. Tus contactos podrán ver la información en tu perfil."
                      }
                    />
                  </form>
                </div>
              </div>

              {/* <div className={clsx(["flex", "items-center", "justify-center"])}>
                <Button
                  onClick={() => {
                    const el = document?.getElementById?.(
                      "select-file-to-upload"
                    );

                    if (el) {
                      el?.click?.();
                    }
                  }}
                  icon={<MdPhoto size={ICON_SIZES.SM} />}
                  text="Selecciona una foto"
                />
              </div> */}
            </div>

            <div className={clsx(["flex", "gap-4", "flex-col"])}>
              {/* <InfoBlock message={t("profile_page.personal_info_note")} /> */}
              <div
                className={clsx([
                  "w-full",
                  "flex",
                  "justify-end",
                  "bg-inherit",
                  "md:py-3",
                  "sticky",
                  "bottom-20",
                  "md:bottom-0",
                ])}
              >
                <ButtonGroup
                  buttons={[
                    {
                      text: t("shared.update"),
                      onClick: () => {
                        setShowConfirmDialog(true);
                      },
                      icon: <MdCheck size={32} />,
                      kind: "update",
                      isLoading: isUpdatingProfile,
                      disabled: isUpdatingProfile,
                    },
                  ]}
                />
              </div>
            </div>
          </div>
        </div>
      </TwoSidebars>
      {showConfirmDialog && (
        <ConfirmDialog
          icon={<MdAccountBox size={CONFIRM_DIALOG_ICON_SIZE} />}
          title={t("dialogs.profile.update_dialog_title")}
          description={t("dialogs.profile.update_dialog_question")}
          onClose={() => {
            setShowConfirmDialog(false);
          }}
          confirmKind="save"
          onConfirm={async () => {
            await updateProfile();
          }}
          isLoading={isUpdatingProfile}
        />
      )}
    </>
  );
};

export default ProfilePage;
