import { Link, useLocation, useNavigate } from "react-router-dom";
import clsx from "clsx";
import { useId } from "react";
import { Tooltip } from "react-tooltip";
import userPreferences from "../../store/userPreferences";

interface IBlocksNavigation {
  links: Array<any>;
}

const BlocksNavigation: React.FC<IBlocksNavigation> = ({ links }) => {
  const location = useLocation();
  const { pathname } = location;
  const tooltipId = useId();
  const { navIsCollapsed } = userPreferences();
  const navigate = useNavigate();

  const filteredList = links?.filter?.(({ hide }) => !hide) || [];

  return (
    <div
      className={clsx([
        "flex",
        "flex-row",
        "md:flex-col",
        "gap-4",
        "md:gap-6",
        "items-center",
        {
          "md:items-center": navIsCollapsed,
          "md:items-start": !navIsCollapsed,
        },
        "w-full",
        "md:w-auto",
        "justify-between",
        "md:justify-normal",
      ])}
    >
      {filteredList.map(
        (
          {
            link,
            icon,
            text,
            tooltip,
            onClick = () => {},
            active,
            activeClasses,
          }: {
            link: any;
            icon: any;
            text: any;
            tooltip: any;
            active: boolean;
            onClick: any;
            activeClasses?: string;
          },
          index: number
        ) => (
          <Link
            to={link}
            onClick={(e: any) => {
              if (onClick) {
                e?.preventDefault?.();
                onClick();
                navigate(link);
              }
            }}
            key={index}
            className={clsx([
              "inline-flex",
              "flex-col",
              "md:flex-row",
              "gap-1",
              "md:gap-3",
              "items-center",
              activeClasses,
              // "border-b-4",
              "pb-2",
              "relative",
              {
                "text-red-500": active && !activeClasses,
                "text-gray-700 md:text-white": !active,
                // "border-current": active,
                // "border-transparent": !active,
              },
              "font-normal",
              "transition-all",
              "hover:text-white dark:hover:text-red-500",
              "text-xs",
              "md:text-base",
              "min-w-[75px]",
              "after:content-['']",
              "after:absolute",
              "after:left-[25px]",
              "after:right-[25px]",
              "after:bottom-0",
              {
                "after:bg-current": active,
              },
              "after:h-[3px]",
              "after:rounded-full",
            ])}
            data-tooltip-id={tooltipId}
            data-tooltip-content={tooltip}
            data-tooltip-place="right"
            data-tooltip-float
            data-tooltip-position-strategy="fixed"
            data-tooltip-offset={20}
          >
            {icon && (
              <span
                className={clsx([
                  {
                    activeClasses,
                    "text-red-600": pathname === link && !activeClasses,
                  },
                ])}
              >
                {icon}
              </span>
            )}
            {text && <span>{text}</span>}
            <Tooltip id={tooltipId} />
          </Link>
        )
      )}
    </div>
  );
};

export default BlocksNavigation;
