import { useMemo } from "react";
import transactionsStore from "../store/transactionsStore";

export default function useYearFilter() {
  const { filters, setFilter } = transactionsStore();

  const {
    year = {
      selectedYear: "",
    },
  } = filters || {};

  return useMemo(
    () => ({
      yearFilter: year.selectedYear,
      setYearFilter: (values: any) => {
        setFilter({
          name: "year",
          values,
        });
      },
    }),
    [year?.selectedYear]
  );
}
