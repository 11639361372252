import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

import ChartDataLabels from "chartjs-plugin-datalabels";

import { Line } from "react-chartjs-2";
import {
  formatNumber,
  getCurrencyDataByCode,
  getTotalByCategory,
  getUniqueCategoriesFromTransactions,
  rgbaObjectToString,
} from "../../helpers/utils";
import { useTranslation } from "react-i18next";
import {
  CATEGORIES_DEFAULT_BG_COLOR,
  CHART_COLORS,
  EXPENSE_TYPE,
  INCOME_TYPE,
} from "../../helpers/constants";
import clsx from "clsx";
import useCategories from "../../hooks/useCategories";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels
);

interface ITransactionsByCategoryChart {
  transactions: Array<any>;
  showIncome?: boolean;
  showExpenses?: boolean;
  showBalance?: boolean;
  currency: string;
}

const TransactionsByCategoryChart: React.FC<ITransactionsByCategoryChart> = ({
  transactions,
  showIncome = true,
  showExpenses = true,
  showBalance = true,
  currency,
}) => {
  const { t } = useTranslation();
  const { getCategoryByName } = useCategories();
  const currencySymbol = getCurrencyDataByCode({
    code: currency,
  })?.symbol;

  const options = {
    responsive: true,
    indexAxis: "x" as const,
    maintainAspectRatio: false,
    borderWidth: 1,
    borderRadius: 1,
    pointRadius: 15,
    pointHoverRadius: 30,
    elements: {
      line: {
        tension: 0.5,
      },
    },
    // pointStyle: "triangle",
    plugins: {
      tooltip: {
        backgroundColor: rgbaObjectToString(CHART_COLORS.TOOLTIP.bg),
        usePointStyle: false,
        padding: 15,
        boxPadding: 4,
        callbacks: {
          label: (context: any) => {
            const label = context?.dataset?.label || "";
            const value = Number(context?.parsed?.y) || 0;

            const formattedTooltip = formatNumber(Math.abs(Number(value)));

            return `${label}: ${currencySymbol}${formattedTooltip}`;
          },
        },
      },
      legend: {
        display: false,
      },
      datalabels: {
        display: false,
      },
    },
    // scales: {
    //   x: {
    //     ticks: {
    //       autoSkip: true,
    //       maxRotation: 0,
    //       minRotation: 0,
    //     },
    //   },
    // },
    animations: {
      tension: {
        duration: 2000,
        easing: "linear" as "linear",
        from: 1,
        to: 0.5,
        loop: true,
      },
    },
    animation: {
      duration: 0,
    },
  };

  let labels = [];

  if (showIncome) labels.push(t("summary.incomes"));
  if (showExpenses) labels.push(t("summary.expenses"));
  if (showBalance) labels.push(t("summary.balance"));

  const availableCategories = getUniqueCategoriesFromTransactions({
    transactions,
  });

  const datasets =
    [EXPENSE_TYPE, INCOME_TYPE]?.map((transactionType: any) => {
      return {
        label:
          transactionType === INCOME_TYPE
            ? t("shared.income")
            : t("shared.expenses"),
        data: availableCategories?.map((cat: any) => {
          if (transactionType === INCOME_TYPE) {
            const income = getTotalByCategory({
              category: cat,
              type: transactionType,
              transactions,
            });

            return income || 0;
          } else if (transactionType === EXPENSE_TYPE) {
            const expense = getTotalByCategory({
              category: cat,
              type: transactionType,
              transactions,
            });

            return expense ? -expense : 0;
          }

          return 0;
        }),
        backgroundColor: availableCategories?.map((cat: any) => {
          return rgbaObjectToString(
            getCategoryByName(cat)?.settings?.bg || CATEGORIES_DEFAULT_BG_COLOR
          );
        }),
        borderColor: availableCategories?.map((cat: any) => {
          return rgbaObjectToString(
            getCategoryByName(cat)?.settings?.bg || CATEGORIES_DEFAULT_BG_COLOR
          );
        }),
      };
    }) || [];

  const chartData = { labels: availableCategories, datasets };

  return (
    <div className={clsx(["flex", "w-full"])}>
      <Line
        className="w-full"
        height={200}
        options={options}
        data={chartData}
      />
    </div>
  );
};

export default TransactionsByCategoryChart;
