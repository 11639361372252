import React from "react";

import {
  MdAccountBox,
  MdArrowBack,
  MdAttachMoney,
  MdCheck,
  MdCompareArrows,
  MdOutlineShoppingCart,
  MdViewSidebar,
} from "react-icons/md";
import PageTitle from "../../components/PageTitle/PageTitle";
import TwoSidebars from "../../layouts/TwoSidebars/TwoSidebars";
import ButtonGroup from "../../components/ButtonGroup/ButtonGroup";
import { useNavigate } from "react-router-dom";
import userPreferences from "../../store/userPreferences";
import clsx from "clsx";
import { useMediaQuery } from "react-responsive";
import TextInput from "../../components/TextInput/TextInput";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import apiClient from "../../helpers/api-client";
import {
  API_ENDPOINT_UPDATE_PROFILE,
  BUTTON_STYLES,
  ICON_SIZES,
  MOBILE_BREAKPOINT_MEDIA_QUERY,
} from "../../helpers/constants";
import { showNotification } from "../../helpers/utils";
import SettingsBlocks from "../../components/SettingsBlocks/SettingsBlocks";
import ConfirmDialog from "../../components/ConfirmDialog/ConfirmDialog";
import { CONFIRM_DIALOG_ICON_SIZE } from "../../constants/icons";
import SectionTitle from "../../components/SectionTitle/SectionTitle";

const FORM_ID = "update-profile";

const ChangePasswordPage: React.FC = () => {
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ query: MOBILE_BREAKPOINT_MEDIA_QUERY });
  const [newPassword, setNewPassword] = useState<string>("");

  const [isUpdatingPassword, setIsUpdatingPassword] = useState<boolean>(false);
  const [showConfirmDialog, setShowConfirmDialog] = useState<boolean>(false);
  const { t } = useTranslation();

  const { rightPanelIsVisible, setRightPanelIsVisible } = userPreferences();

  const updatePassword = () => {
    setIsUpdatingPassword(true);

    const formData = new FormData();

    formData.append("password", newPassword?.trim?.());

    apiClient
      .put(API_ENDPOINT_UPDATE_PROFILE, formData, {
        // headers: { "Content-Type": "multipart/form-data" },
      })
      .then(async (res: any) => {
        showNotification({
          kind: "success",

          message: "Contraseña actualizada correctamente",
        });
        setNewPassword("");
        setShowConfirmDialog(false);
      })
      .catch((e) => {
        showNotification({
          message: "Error actualizando tu contraseña",
          kind: "error",
        });
      })
      .finally(() => {
        setIsUpdatingPassword(false);
      });
  };

  return (
    <>
      <TwoSidebars
        right={
          <>
            {<SettingsBlocks layout="vertical" columns={isMobile ? 2 : 1} />}

            {/* <TasksListPanel />
            <CustomLinks /> */}
          </>
        }
      >
        <div className={clsx(["flex", "w-full", "flex-col", "gap-0"])}>
          <PageTitle
            title={t("settings.title")}
            subtitle={t("password_page.title")}
            titleLink="/settings"
            isLoading={false}
            rightSide={
              <>
                <ButtonGroup
                  align="right"
                  gap={1}
                  buttons={[
                    {
                      icon: <MdArrowBack size={ICON_SIZES.SM} />,
                      classes: BUTTON_STYLES.HEADING,

                      tooltip: "Atras",
                      onClick: () => {
                        navigate("/settings");
                      },
                      hide: !isMobile,
                    },
                    {
                      icon: <MdOutlineShoppingCart size={ICON_SIZES.SM} />,
                      tooltip: "Añadir gasto",
                      onClick: () => {
                        navigate("/transactions/expense");
                      },
                      hide: isMobile,
                    },

                    {
                      icon: <MdAttachMoney size={ICON_SIZES.SM} />,
                      tooltip: "Añadir ingreso",
                      hide: isMobile,
                      onClick: () => {
                        navigate("/transactions/income");
                      },
                    },
                    {
                      icon: <MdCompareArrows size={ICON_SIZES.SM} />,
                      tooltip: "Transacciones",
                      onClick: () => {
                        navigate("/transactions");
                      },
                      hide: isMobile,
                    },

                    {
                      icon: <MdViewSidebar size={ICON_SIZES.SM} />,
                      tooltip: "Panel lateral",
                      kind: rightPanelIsVisible ? "primary" : "secondary",
                      hide: isMobile,
                      onClick: () => {
                        setRightPanelIsVisible(!rightPanelIsVisible);
                      },
                    },
                  ]}
                />
              </>
            }
          />
          <div className={clsx(["flex", "gap-4", "flex-col"])}>
            <SectionTitle title={t("profile_page.change_password_title")} />
            <div className="grid grid-cols-1 gap-4">
              <form
                className={clsx(["flex", "flex-col", "gap-6"])}
                onSubmit={(e: any) => {
                  e?.preventDefault?.();
                  setShowConfirmDialog(true);
                }}
                id={FORM_ID}
              >
                <TextInput
                  value={newPassword}
                  onChange={(e: any) => setNewPassword(e?.target?.value)}
                  label={t("change_password_page.new_password_label")}
                  placeholder={t(
                    "change_password_page.new_password_placeholder"
                  )}
                  type="password"
                  required
                  form={FORM_ID}
                />
              </form>
            </div>
            <div
              className={clsx([
                "w-full",
                "flex",
                "justify-end",
                "bg-inherit",
                "md:py-3",
              ])}
            >
              <ButtonGroup
                buttons={[
                  {
                    text: t("shared.update"),
                    onClick: () => {},
                    icon: <MdCheck size={32} />,
                    kind: "update",
                    isLoading: isUpdatingPassword,
                    disabled: isUpdatingPassword,
                    type: "submit",
                    form: FORM_ID,
                  },
                ]}
              />
            </div>
          </div>
        </div>
      </TwoSidebars>
      {showConfirmDialog && (
        <ConfirmDialog
          icon={<MdAccountBox size={CONFIRM_DIALOG_ICON_SIZE} />}
          title={t("dialogs.password.update_dialog_title")}
          description={t("dialogs.password.update_dialog_question")}
          onClose={() => {
            setShowConfirmDialog(false);
          }}
          confirmKind="save"
          onConfirm={() => {
            updatePassword();
          }}
          isLoading={isUpdatingPassword}
        />
      )}
    </>
  );
};

export default ChangePasswordPage;
