import { useQuery, useQueryClient } from "@tanstack/react-query";
import { searchTransactions } from "../api/transactions";
import { useCallback, useMemo } from "react";
import {
  getTodayTransactionsFromTransactions,
  getTotalByTransactionType,
  getUniqueDescriptionsFromTransactions,
  getUniquePaidToFromTransactions,
} from "../helpers/utils";
import { CACHE_KEYS, EXPENSE_TYPE, INCOME_TYPE } from "../helpers/constants";
import useMainFilter from "./useMainFilter";
import _ from "lodash";
import { TRANSACTION_STATUS_PENDING } from "../constants/transaction-status";
import moment from "moment";

export default function useTransactions() {
  const queryClient = useQueryClient();
  const { mainFilter } = useMainFilter();

  const getParams = useCallback(() => {
    const params = new URLSearchParams();
    const {
      dateFrom,
      dateTo,
      payTo,
      type,
      status,
      paymentMethod,
      tags,
      category,
      account,
    } = mainFilter;

    if (dateFrom) {
      params.append("dateFrom", dateFrom);
    }
    if (dateTo) {
      params.append("dateTo", dateTo);
    }
    if (payTo?.length) {
      params.append("payTo", JSON.stringify(payTo));
    }
    if (type) {
      params.append("type", type);
    }

    if (category?.length) {
      params.append("category", JSON.stringify(category));
    }

    if (account) {
      params.append("account", account);
    }

    if (status?.length) {
      params.append("status", JSON.stringify(status));
    }
    if (paymentMethod?.length) {
      params.append("paymentMethod", JSON.stringify(paymentMethod));
    }

    if (tags?.length) {
      params.append("tags", JSON.stringify(tags));
    }

    params.append("deleted", "false");

    return params;
  }, [mainFilter]);

  const resetCache = async () => {
    return await Promise.allSettled([
      await queryClient.refetchQueries({
        queryKey: [CACHE_KEYS.ALL_TRANSACTIONS],
      }),
      await queryClient.refetchQueries({
        queryKey: [CACHE_KEYS.ALL_CATEGORIES],
      }),
      await queryClient.refetchQueries({
        queryKey: [
          CACHE_KEYS.FILTERED_TRANSACTIONS,
          JSON.stringify(mainFilter),
        ],
      }),
    ]);
  };

  const transactions = useQuery({
    queryKey: [CACHE_KEYS.FILTERED_TRANSACTIONS, JSON.stringify(mainFilter)],
    queryFn: async () => {
      try {
        return await searchTransactions({
          fieldsToSearch: getParams(),
        });
      } catch (e) {
        throw e;
      }
    },
  });

  const getTransactionById = ({
    transactions,
    transactionId,
  }: {
    transactions: any;
    transactionId: string;
  }) => {
    return _.find(transactions || [], { invoice: transactionId });
  };

  return {
    transactions,
    getTransactionById,
    resetCache,
  };
}

export function useAllTransactions() {
  const REFETCH_MINUTES = 5;
  const REFETCH_INTERVAL = REFETCH_MINUTES * 1000 * 60;
  const { mainFilter } = useMainFilter();

  const allTransactions: any = useQuery({
    queryKey: [CACHE_KEYS.ALL_TRANSACTIONS],
    queryFn: async () => {
      return await searchTransactions({
        fieldsToSearch: null,
      });
    },
    refetchInterval: REFETCH_INTERVAL,
  });

  const totalIncome = useMemo(
    () =>
      getTotalByTransactionType({
        type: INCOME_TYPE,
        transactions: allTransactions?.data?.data,
      }),
    [allTransactions?.data?.data]
  );

  const totalExpenses = useMemo(
    () =>
      getTotalByTransactionType({
        type: EXPENSE_TYPE,
        transactions: allTransactions?.data?.data,
      }),
    [allTransactions?.data?.data]
  );

  const todayTransactions = useMemo(
    () =>
      getTodayTransactionsFromTransactions({
        transactions: allTransactions?.data?.data?.filter?.(
          ({
            archived,
            shared,
            deleted,
          }: {
            archived: any;
            shared: any;
            deleted: any;
          }) => !archived && !shared && !deleted
        ),
      }),
    [allTransactions?.data?.data]
  );

  const archivedTransactionsList = useMemo(
    () =>
      allTransactions?.data?.data?.filter?.(
        ({
          archived,
          shared,
          deleted,
        }: {
          archived: any;
          shared: any;
          deleted: any;
        }) => archived && !shared && !deleted
      ) || [],
    [allTransactions?.data?.data]
  );

  const deletedTransactionsList = useMemo(
    () =>
      allTransactions?.data?.data?.filter?.(
        ({ deleted }: { deleted: any }) => deleted
      ) || [],
    [allTransactions?.data?.data]
  );

  const unarchivedAndUnsharedTransactionsList = useMemo(
    () =>
      allTransactions?.data?.data?.filter?.(
        ({
          archived,
          shared,
          deleted,
        }: {
          archived: any;
          shared: any;
          deleted: any;
        }) => !archived && !shared && !deleted
      ) || [],
    [allTransactions?.data?.data]
  );

  const totalGeneralIncome = useMemo(
    () =>
      getTotalByTransactionType({
        type: INCOME_TYPE,
        transactions: unarchivedAndUnsharedTransactionsList,
      }),
    [unarchivedAndUnsharedTransactionsList]
  );

  const totalGeneralExpenses = useMemo(
    () =>
      getTotalByTransactionType({
        type: EXPENSE_TYPE,
        transactions: unarchivedAndUnsharedTransactionsList,
      }),
    [unarchivedAndUnsharedTransactionsList]
  );

  const sharedTransactionsList = useMemo(
    () =>
      allTransactions?.data?.data?.filter?.(
        ({ shared, deleted }: { shared: any; deleted: any }) =>
          shared && !deleted
      ) || [],
    [allTransactions?.data?.data]
  );

  const pendingTransactionsList = useMemo(
    () =>
      allTransactions?.data?.data?.filter?.(
        ({
          shared,
          archived,
          status,
          deleted,
        }: {
          shared: any;
          archived: any;
          status: any;
          deleted: any;
        }) =>
          !shared &&
          !archived &&
          !deleted &&
          status === TRANSACTION_STATUS_PENDING
      ) || [],
    [allTransactions?.data?.data]
  );

  const lastSevenDaysTransactionsList = useMemo(() => {
    const today = moment().add(1, "days");
    const lastDay = today.format("yyyy-MM-DD");
    const firstDay = today.subtract(8, "days").format("yyyy-MM-DD");

    return (
      unarchivedAndUnsharedTransactionsList?.filter?.(
        ({ date, account }: { date: any; account: any }) =>
          moment(date)?.format("yyyy-MM-DD") >= firstDay &&
          moment(date)?.format?.("yyyy-MM-DD") <= lastDay &&
          (mainFilter?.account ? account === mainFilter?.account : true)
      ) || []
    );
  }, [unarchivedAndUnsharedTransactionsList, mainFilter?.account]);

  return {
    allTransactions,
    totalExpenses,
    totalIncome,
    todayTotals: {
      income: totalIncome,
      expenses: totalExpenses,
    },
    generalTotals: {
      income: totalGeneralIncome,
      expenses: totalGeneralExpenses,
    },
    todayTransactions,
    archivedTransactions: archivedTransactionsList,
    sharedTransactions: sharedTransactionsList,
    pendingTransactions: pendingTransactionsList,
    unarchivedAndUnsharedTransactionsList,
    lastSevenDaysTransactionsList,
    deletedTransactionsList,
  };
}

export function useAutocomplete() {
  const { allTransactions } = useAllTransactions();

  const payTo = useMemo(
    () =>
      allTransactions?.isLoading
        ? []
        : getUniquePaidToFromTransactions({
            transactions: allTransactions?.data?.data,
          })
            ?.sort?.()
            ?.map((value) => ({
              label: `${value}`?.trim?.(),
              value: `${value}`?.trim?.(),
            })),
    [allTransactions?.data?.data, allTransactions?.isLoading]
  );

  const tags = useMemo(
    () =>
      allTransactions?.isLoading
        ? []
        : allTransactions?.data?.data?.reduce?.((acc: any, current: any) => {
            if (current.tags?.length) {
              return [...acc, ...current.tags];
            }

            return acc;
          }, []),
    [allTransactions?.data?.data, allTransactions?.isLoading]
  );

  const descriptions = useMemo(
    () =>
      allTransactions?.isLoading
        ? []
        : getUniqueDescriptionsFromTransactions({
            transactions: allTransactions?.data?.data,
          })
            ?.sort?.()
            ?.map?.((value) => ({
              label: `${value}`?.trim?.(),
              value: `${value}`?.trim?.(),
            })) || [],
    [allTransactions?.data?.data, allTransactions?.isLoading]
  );

  const getCategoryByDescription = useCallback(
    ({ description }: { description: string }) => {
      const res = allTransactions?.data?.data?.find?.(
        ({ description: originalDescription }: { description: string }) =>
          originalDescription?.toLowerCase?.()?.trim?.() ===
          description?.toLowerCase?.()?.trim?.()
      );

      return res?.category || "";
    },
    [allTransactions?.data?.data]
  );

  return {
    allPayToList: payTo,
    allTagList: Array.from(new Set(tags))?.sort?.(),
    allDescriptionList: descriptions,
    getCategoryByDescription,
  };
}
