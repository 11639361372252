import useModalsStore from "../../store/modalsStore";
import NewAccountModal from "../Button/NewAccountModal/NewAccountModal";
import NewTaskModal from "../NewTaskModal/NewTaskModal";
import NewLinkModal from "../NewLinkModal/NewLinkModal";
import ProfileEditModal from "../ProfileEditModal/ProfileEditModal";
import ConfirmDialog from "../ConfirmDialog/ConfirmDialog";
import useHistoryStore from "../../store/historyStore";
import { useNavigate } from "react-router-dom";
import { showNotification } from "../../helpers/utils";

const ModalsLoader: React.FC = () => {
  const navigate = useNavigate();
  const {
    setShowCreateNewAccount,
    showCreateNewAccount,
    setShowCreateNewTask,
    showCreateNewTask,
    showCreateNewLink,
    setShowCreateNewLink,
    setShowEditProfile,
    showEditProfile,
    showEditAccount,
    setShowEditAccount,
    showDeleteHistoryConfirm,
    setShowDeleteHistoryConfirm,
    setShowLogoutDialog,
    showLogoutDialog,
  } = useModalsStore();

  const { clearHistory } = useHistoryStore();

  return (
    <>
      {/* create new account */}
      {showCreateNewAccount && (
        <NewAccountModal
          onClose={() => setShowCreateNewAccount(false)}
          onCreated={async (data: any) => {
            setShowCreateNewAccount(false);
            showNotification({
              message: "Cuenta creada correctamente",
              kind: "success",
            });
          }}
        />
      )}
      {showEditAccount && (
        <NewAccountModal onClose={() => setShowEditAccount("")} />
      )}
      {/* create new note */}
      {showCreateNewTask && (
        <NewTaskModal onClose={() => setShowCreateNewTask(false)} />
      )}
      {/* create new link */}
      {showCreateNewLink && (
        <NewLinkModal onClose={() => setShowCreateNewLink(false)} />
      )}
      {/* edit profile */}
      {showEditProfile && (
        <ProfileEditModal
          onClose={() => setShowEditProfile(false)}
          onUpdate={() => {}}
        />
      )}

      {showDeleteHistoryConfirm && (
        <ConfirmDialog
          description="¿Deseas limpiar tu historial?"
          title="Limpiar historial"
          onConfirm={() => {
            clearHistory();
            setShowDeleteHistoryConfirm(false);
          }}
          onClose={() => setShowDeleteHistoryConfirm(false)}
          confirmKind="delete"
        />
      )}

      {showLogoutDialog && (
        <ConfirmDialog
          description="¿Deseas cerrar tu sesión?"
          onClose={() => setShowLogoutDialog(false)}
          onConfirm={() => {
            setShowLogoutDialog(false);
            navigate("/logout");
          }}
          title="Salir"
        />
      )}
    </>
  );
};

export default ModalsLoader;
