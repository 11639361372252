import clsx from "clsx";
import { Link, useNavigate } from "react-router-dom";
import ExpenserTriangle from "../components/ExpenserTriangle/ExpenserTriangle";
import {
  MdAccountBox,
  MdCached,
  MdEmojiPeople,
  MdLink,
  MdLogout,
  MdNoteAlt,
  MdOutlineMoveToInbox,
  MdPersonSearch,
  MdSettings,
  MdToggleOff,
  MdToggleOn,
} from "react-icons/md";
import { useTranslation } from "react-i18next";

import { ICON_SIZES } from "../helpers/constants";
import { getShortName } from "../helpers/utils";
import userPreferences from "../store/userPreferences";
import modalsStore from "../store/modalsStore";
import useInvites from "../hooks/useInvites";
import useCurrentUser from "../hooks/useCurrentUser";
import useMainFilter from "../hooks/useMainFilter";
import { useAllTransactions } from "../hooks/useTransactions";
import ButtonGroup from "../components/ButtonGroup/ButtonGroup";
import { useState } from "react";

const ExpenserMobileTopLogo: React.FC = () => {
  const { t } = useTranslation();
  const [isCleaningCache] = useState<boolean>(false);
  const { receivedInvites } = useInvites();
  const { sharedTransactions } = useAllTransactions();
  const navigate = useNavigate();
  const { setShowLogoutDialog } = modalsStore();
  const { setMainFilter } = useMainFilter();
  const { userInfo: currentUserInfo } = useCurrentUser();

  const { blurAmounts, setBlurAmounts } = userPreferences();

  return (
    <div
      className={clsx([
        "fixed",
        "md:hidden",
        "top-0",
        "left-0",
        "right-0",
        "z-40",
        "h-[41px]",
        "bg-[#212f3c]",
        "flex",
        "w-full",
        "items-center",
        "justify-center",
        "px-4",
        "shadow-sm",
      ])}
    >
      <div
        className={clsx([
          "flex",
          "flex-row",
          "gap-2",
          "w-full",
          "rounded-md",
          "justify-between",
          "py-2",
          "mt-0",
        ])}
      >
        <Link
          to="/reports"
          onClick={() => setMainFilter({ account: "" })}
          className={clsx([
            "flex",
            "flex-row",
            "items-center",
            "md:justify-end",
            "gap-1",
          ])}
        >
          <ExpenserTriangle size={22} className={clsx(["text-red-600"])} />
          <h1 className={clsx(["text-xl", "font-medium", "text-white"])}>
            eXpenser
            <span
              className={clsx([
                "text-[11px]",
                "uppercase",
                "text-red-600",
                {
                  hidden: true, //isPwa(),
                },
              ])}
            >
              .app
            </span>
          </h1>
        </Link>
        <ButtonGroup
          gap={0}
          buttons={[
            {
              hide: !sharedTransactions?.length,
              onClick: () => {
                navigate("/shared-transactions");
              },
              icon: (
                <MdOutlineMoveToInbox
                  className={clsx(["text-emerald-400"])}
                  size={ICON_SIZES.SM}
                />
              ),
              classes: clsx([
                "py-0",
                "rounded-none",
                "!bg-transparent",
                "!text-white",
                "border-0",
                {
                  "before:bg-emerald-400": true,
                  [[
                    "relative",
                    "before:animate-ping",
                    "before:-z-10",
                    "before:content-['']",
                    "before:absolute",
                    "before:w-[20px]",
                    "before:h-[20px]",
                    "before:rounded-full",
                  ].join(" ")]: true,
                },
              ]),
            },
            {
              hide: !receivedInvites?.length,
              onClick: () => {
                navigate("/settings/network");
              },
              icon: (
                <MdEmojiPeople
                  className={clsx(["text-emerald-400"])}
                  size={ICON_SIZES.SM}
                />
              ),
              classes: clsx([
                "py-0",
                "rounded-none",
                "!bg-transparent",
                "!text-white",
                "border-0",
                {
                  "before:bg-emerald-400": true,
                  [[
                    "relative",
                    "before:animate-ping",
                    "before:-z-10",
                    "before:content-['']",
                    "before:absolute",
                    "before:w-[20px]",
                    "before:h-[20px]",
                    "before:rounded-full",
                  ].join(" ")]: true,
                },
              ]),
            },
            {
              classes: clsx([
                "py-0",
                "px-2",
                "py-1",
                "border-0",
                // "!text-white",
                "hover:!text-gray-300",
                "!text-white",
                "!bg-transparent",
                "!text-xs",
              ]),
              icon: <MdAccountBox size={ICON_SIZES.SM} />,
              text: getShortName(currentUserInfo?.name) || "...",
              options: [
                {
                  text: t("shared.notes"),
                  onClick: () => {
                    navigate("/notes");
                  },
                  icon: (
                    <MdNoteAlt
                      size={ICON_SIZES.SM}
                      className="text-yellow-400"
                    />
                  ),
                  hide: false,
                },
                {
                  hide: true,
                  text: t("shared.my_links"),
                  onClick: () => {
                    navigate("/links");
                  },
                  icon: (
                    <MdLink size={ICON_SIZES.SM} className="text-orange-400" />
                  ),
                },

                {
                  text: "Contactos",
                  onClick: () => navigate("/settings/network"),
                  icon: (
                    <MdPersonSearch
                      className="text-blue-900"
                      size={ICON_SIZES.SM}
                    />
                  ),
                  hide: false,
                },
                {
                  text: t("shared.privacy_mode"),
                  className: clsx([
                    {
                      "font-medium": blurAmounts,
                    },
                  ]),
                  onClick: () => {
                    setBlurAmounts(!blurAmounts);
                  },
                  icon: blurAmounts ? (
                    <MdToggleOn
                      className="text-pink-400"
                      size={ICON_SIZES.SM}
                    />
                  ) : (
                    <MdToggleOff
                      className="text-gray-400"
                      size={ICON_SIZES.SM}
                    />
                  ),
                  hide: true,
                },
                {
                  text: t("shared.clear_cache"),
                  onClick: async () => {
                    navigate("/settings/cache");
                  },
                  icon: (
                    <MdCached size={ICON_SIZES.SM} className="text-red-400" />
                  ),
                  isLoading: isCleaningCache,
                },
                {
                  text: t("menu.settings"),
                  onClick: () => {
                    navigate("/settings");
                  },
                  icon: (
                    <MdSettings
                      size={ICON_SIZES.SM}
                      className="text-blue-600"
                    />
                  ),
                },
                {
                  text: t("menu.signout"),
                  onClick: () => {
                    setShowLogoutDialog(true);
                    // navigate("/logout");
                  },
                  icon: (
                    <MdLogout
                      size={ICON_SIZES.SM}
                      className="text-purple-600"
                    />
                  ),
                },
              ],
            },
          ]}
          align="right"
        />
      </div>
    </div>
  );
};

export default ExpenserMobileTopLogo;
