import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import moment from "moment";
import { Line } from "react-chartjs-2";
import {
  CHART_COLORS,
  CHART_TRANSPARENCY_BG_COLOR,
  EXPENSE_TYPE,
  INCOME_TYPE,
} from "../../helpers/constants";
import i18next from "i18next";
import clsx from "clsx";
import {
  formatNumber,
  getCurrencyDataByCode,
  getTotalByMonth,
  getUniqueMonthsFromTransactions,
  rgbaObjectToString,
} from "../../helpers/utils";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import useMainFilter from "../../hooks/useMainFilter";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

interface ITransactionsByMonthChart {
  transactions: Array<any>;
  currency: string;
  showIncome?: boolean;
  showExpenses?: boolean;
  showBalance?: boolean;
}

const TransactionsByMonthChart: React.FC<ITransactionsByMonthChart> = ({
  transactions = [],
  currency,
  showIncome = true,
  showExpenses = true,
  showBalance = true,
}) => {
  const { mainFilter } = useMainFilter();
  const currentLanguage = i18next.language?.toLowerCase?.() || "en";
  const { t } = useTranslation();
  const currencySymbol = getCurrencyDataByCode({
    code: currency,
  })?.symbol;

  const options = {
    indexAxis: "x" as const,
    responsive: true,
    pointRadius: 15,
    pointHoverRadius: 30,
    borderColor: "#888",
    // pointStyle: "triangle",
    borderWidth: 1,
    maintainAspectRatio: false,
    interaction: {
      mode: "index" as const,
      intersect: false,
    },
    elements: {
      line: {
        tension: 0.5,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      datalabels: {
        display: false,
      },
      tooltip: {
        backgroundColor: rgbaObjectToString(CHART_COLORS.TOOLTIP.bg),
        usePointStyle: false,
        padding: 15,
        boxPadding: 4,
        callbacks: {
          label: (context: any) => {
            const label = context?.dataset?.label || "";
            const value = Number(context?.parsed?.y) || 0;

            const formattedTooltip = formatNumber(Math.abs(Number(value)));

            return `${label}: ${currencySymbol}${formattedTooltip}`;
          },

          title: (datasets: any) => {
            const [title] = datasets;
            return `${title.label}`;
          },

          footer: (datasets: any) => {
            return `Transacciones por mes`;
          },
        },
      },
    },
    scales: {
      // x: {
      //   ticks: {
      //     autoSkip: true,
      //     maxRotation: 0,
      //     minRotation: 0,
      //   },
      // },
    },
    animations: {
      tension: {
        duration: 2000,
        easing: "linear" as "linear",
        from: 1,
        to: 0.5,
        loop: true,
      },
    },
    animation: {
      duration: 0,
    },
  };

  const mobileOptions = {
    ...options,
    pointRadius: 15,
    pointHoverRadius: 30,
  };

  const labels = getUniqueMonthsFromTransactions({ transactions });
  const dataToInclude = () => {
    if (mainFilter?.type) {
      if (mainFilter.type === INCOME_TYPE) {
        return [INCOME_TYPE];
      } else if (mainFilter.type === EXPENSE_TYPE) {
        return [EXPENSE_TYPE];
      }
    }

    return [EXPENSE_TYPE, INCOME_TYPE];
  };
  const datasets =
    dataToInclude()?.map?.((transactionType: any) => {
      return {
        label:
          transactionType === INCOME_TYPE
            ? t("shared.income")
            : t("shared.expenses"),
        data: labels?.map((cat: any) => {
          if (transactionType === INCOME_TYPE) {
            const income = getTotalByMonth({
              month: cat,
              type: transactionType,
              transactions,
            });

            return income || 0;
          } else if (transactionType === EXPENSE_TYPE) {
            const expense = getTotalByMonth({
              month: cat,
              type: transactionType,
              transactions,
            });

            return expense ? -expense : 0;
          }

          return 0;
        }),
        backgroundColor:
          transactionType === INCOME_TYPE
            ? rgbaObjectToString({
                ...CHART_COLORS.INCOME.bg,
                a: CHART_TRANSPARENCY_BG_COLOR,
              })
            : rgbaObjectToString({
                ...CHART_COLORS.EXPENSE.bg,
                a: CHART_TRANSPARENCY_BG_COLOR,
              }),
        borderColor:
          transactionType === INCOME_TYPE
            ? rgbaObjectToString(CHART_COLORS.INCOME.bg)
            : rgbaObjectToString(CHART_COLORS.EXPENSE.bg),
      };
    }) || [];

  const chartData = {
    labels: labels.map((label) =>
      _.capitalize(
        `${moment(label, "MM").locale(currentLanguage).format("MMMM")}`
      )
    ),
    datasets,
  };

  return (
    <div className={clsx("flex")}>
      <Line
        options={mobileOptions}
        className="w-full"
        height={200}
        data={chartData}
      />
    </div>
  );
};

export default TransactionsByMonthChart;
