import {
  MdArrowBack,
  MdAttachMoney,
  MdCheck,
  MdCheckCircle,
  MdCompareArrows,
  MdOutlineShoppingCart,
  MdRadioButtonUnchecked,
} from "react-icons/md";
import PageTitle from "../../components/PageTitle/PageTitle";
import TwoSidebars from "../../layouts/TwoSidebars/TwoSidebars";
import ButtonGroup from "../../components/ButtonGroup/ButtonGroup";
import { useNavigate } from "react-router-dom";
import clsx from "clsx";
import { useMediaQuery } from "react-responsive";
import { useTranslation } from "react-i18next";
import {
  API_ENDPOINT_UPDATE_PROFILE,
  BUTTON_STYLES,
  ICON_SIZES,
  MOBILE_BREAKPOINT_MEDIA_QUERY,
  PAYMENT_METHODS,
} from "../../helpers/constants";
import SectionTitle from "../../components/SectionTitle/SectionTitle";
import InfoBlock from "../../components/InfoBlock/InfoBlock";
import { useState } from "react";
import ConfirmDialog from "../../components/ConfirmDialog/ConfirmDialog";
import apiClient from "../../helpers/api-client";
import SettingsBlocks from "../../components/SettingsBlocks/SettingsBlocks";
import { showNotification } from "../../helpers/utils";
import DataTable from "react-data-table-component";
import TableSearch from "../../components/TableSearch/TableSearch";
import useCurrentUser from "../../hooks/useCurrentUser";

const PaymentMethodsPage: React.FC = () => {
  const isMobile = useMediaQuery({ query: MOBILE_BREAKPOINT_MEDIA_QUERY });

  const navigate = useNavigate();
  const { t } = useTranslation();
  const [showConfirmDialog, setShowConfirmDialog] = useState<boolean>(false);
  const [isUpdatingProfile, setIsUpdatingProfile] = useState<boolean>(false);

  const COLUMNS = [
    {
      name: "ID",
      selector: (row: any) => row.id,
      sortable: true,
      omit: true,
    },
    {
      selector: (row: any) => row.name,
      name: "Nombre",
      sortable: true,
    },
    {
      selector: (row: any) => row.enabled,
      name: "Enabled",
      sortable: true,
      cell: (row: any) => {
        if (row?.enabled) {
          return <MdRadioButtonUnchecked size={ICON_SIZES.SM} />;
        }
        return (
          <MdCheckCircle size={ICON_SIZES.SM} className="text-green-400" />
        );
      },
    },
  ];

  const { enabledPaymentMethods } = useCurrentUser();

  const isEnabled = (methodId: string) => {
    return !!enabledPaymentMethods.includes?.(methodId);
  };

  const data = PAYMENT_METHODS().map((method: any) => ({
    name: method.name,
    id: method.id,
    enabled: isEnabled(method.id),
  }));

  const updateProfile = () => {
    setIsUpdatingProfile(true);

    const formData = new FormData();

    formData.append(
      "paymentMethods",
      JSON.stringify([...enabledPaymentMethods])
    );

    apiClient
      .put(API_ENDPOINT_UPDATE_PROFILE, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then(async (res: any) => {
        showNotification({
          message: "Métodos de pago actualizados correctamente",
          kind: "success",
        });

        setShowConfirmDialog(false);
        // onUpdate();
      })
      .catch((e) => {
        showNotification({
          message: "Error actualizando tus métodos de pago",
          kind: "error",
        });
      })
      .finally(() => {
        setIsUpdatingProfile(false);
      });
  };

  // const toggleMethod = (methodId: string) => {
  //   if (isEnabled(methodId)) {
  //     setSelectedMethods(
  //       selectedMethods.filter?.((method) => method !== methodId)
  //     );
  //   } else {
  //     setSelectedMethods([...selectedMethods, methodId]);
  //   }
  // };

  return (
    <>
      <TwoSidebars
        right={
          <>
            {<SettingsBlocks layout="vertical" columns={isMobile ? 2 : 1} />}

            {/* <SettingsBlocks layout="vertical" /> */}
          </>
        }
      >
        <div className={clsx(["flex", "w-full", "flex-col", "gap-0"])}>
          <PageTitle
            title={t("settings.title")}
            subtitle={t("settings.payment_methods_title")}
            titleLink="/settings"
            isLoading={false}
            rightSide={
              <>
                <ButtonGroup
                  align="right"
                  gap={1}
                  buttons={[
                    {
                      classes: isMobile ? BUTTON_STYLES.HEADING : "",

                      icon: <MdArrowBack size={ICON_SIZES.SM} />,
                      tooltip: "Atras",
                      onClick: () => {
                        navigate("/settings");
                      },
                      hide: !isMobile,
                    },
                    {
                      icon: <MdOutlineShoppingCart size={ICON_SIZES.SM} />,
                      tooltip: "Añadir gasto",
                      onClick: () => {
                        navigate("/transactions/expense");
                      },
                      hide: isMobile,
                    },

                    {
                      icon: <MdAttachMoney size={ICON_SIZES.SM} />,
                      tooltip: "Añadir ingreso",
                      hide: isMobile,
                      onClick: () => {
                        navigate("/transactions/income");
                      },
                    },
                    {
                      icon: <MdCompareArrows size={ICON_SIZES.SM} />,
                      tooltip: "Transacciones",
                      onClick: () => {
                        navigate("/transactions");
                      },
                      hide: isMobile,
                    },
                  ]}
                />
              </>
            }
          />

          <div className={clsx(["flex", "flex-col", "w-full", "gap-4"])}>
            <SectionTitle title={t("payment_methods_page.table_title")} />
            <InfoBlock message={t("settings.payment_methods_note")} />
            <TableSearch onChange={() => {}} onSubmit={() => {}} />
            <DataTable
              columns={COLUMNS}
              data={data}
              persistTableHead
              selectableRows
              // striped
              paginationComponentOptions={{
                selectAllRowsItem: true,
                selectAllRowsItemText: t("pagination.all"),
                rowsPerPageText: t("pagination.rows_per_page"),
                rangeSeparatorText: t("pagination.separator"),
              }}
              noDataComponent={
                <div
                  className={clsx([
                    "px-2",
                    "py-4",
                    "flex",
                    "items-center",
                    "justify-center",
                    "text-sm",
                    "md:text-base",
                  ])}
                >
                  {t("empty_states.no_records")}
                </div>
              }
            />

            {/* <table className="max-w-full table-fixed">
              <thead
                className={clsx([
                  "border-b-4",
                  "border-gray-500",
                  "h-[50px]",
                  "bg-gray-100",
                ])}
              >
                <tr>
                  <th
                    className={clsx([
                      "font-medium",
                      "text-sm",
                      "text-left",
                      "px-3",
                      "uppercase",
                      "text-gray-800",
                      "whitespace-nowrap",
                    ])}
                  >
                    {t("payment_methods_page.name_column")}
                  </th>
                  <th
                    className={clsx([
                      "w-[80px]",
                      "font-medium",
                      "text-sm",
                      "text-center",
                      "px-3",
                      "uppercase",
                      "text-gray-800",
                      "whitespace-nowrap",
                    ])}
                  >
                    {t("payment_methods_page.active_column")}
                  </th>
                </tr>
              </thead>
              <tbody className={clsx(["text-base", "text-gray-900"])}>
                {PAYMENT_METHODS().map((method, index) => {
                  const isOdd = index % 2 === 0;

                  return (
                    <tr
                      key={index}
                      className={clsx([
                        "transition-all",
                        "border-b",
                        "border-b-primary/6",
                        "cursor-pointer",
                        "hover:text-red-600",
                        "hover:bg-gray-100",
                        {
                          "bg-white": isOdd && !isEnabled(method.id),
                          "bg-gray-50/30": !isOdd && !isEnabled(method.id),
                          "text-red-600": isEnabled(method.id),
                          "text-red-400 hover:bg-gray-100 bg-gray-100":
                            isEnabled(method.id),
                        },
                      ])}
                      onClick={() => toggleMethod(method.id)}
                    >
                      <td
                        className={clsx(["px-1", "whitespace-nowrap", "py-2"])}
                      >
                        <div>{method.name}</div>
                      </td>
                      <td
                        className={clsx(["px-1", "whitespace-nowrap", "py-2"])}
                      >
                        <div
                          className={clsx([
                            "flex",
                            "items-center",
                            "justify-center",
                          ])}
                        >
                          <Button
                            onClick={() => {}}
                            classes={clsx([
                              "!bg-transparent",
                              "border-0",
                              "rounded-none",
                              "!text-gray-900",
                            ])}
                            icon={
                              <>
                                {isEnabled(method.id) ? (
                                  <MdCheckBox
                                    size={32}
                                    className="text-red-400"
                                  />
                                ) : (
                                  <MdCheckBoxOutlineBlank size={32} />
                                )}
                              </>
                            }
                          />
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table> */}
          </div>
          <div
            className={clsx([
              "w-full",
              "flex",
              "justify-end",
              "bg-inherit",
              "md:py-3",
              "sticky",
              "bottom-20",
              "md:bottom-0",
            ])}
          >
            <ButtonGroup
              buttons={[
                {
                  text: t("shared.update"),
                  onClick: () => {
                    if (enabledPaymentMethods?.length) {
                      setShowConfirmDialog(true);
                    }
                  },
                  icon: <MdCheck size={32} />,
                  kind: "update",
                  isLoading: isUpdatingProfile,
                  disabled: isUpdatingProfile || !enabledPaymentMethods?.length,
                },
              ]}
            />
          </div>
        </div>
      </TwoSidebars>
      {showConfirmDialog && (
        <ConfirmDialog
          isLoading={isUpdatingProfile}
          onConfirm={async () => {
            updateProfile();
          }}
          onClose={() => setShowConfirmDialog(false)}
          description={t("dialogs.payment_methods.update_dialog_question")}
          title={t("dialogs.payment_methods.update_dialog_title")}
        />
      )}
    </>
  );
};

export default PaymentMethodsPage;
