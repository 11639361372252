import { MdCheck, MdClose, MdInfo } from "react-icons/md";
import Modal from "../Modal/Modal";
import TextInput from "../TextInput/TextInput";
import { FormEvent, ReactElement, useId, useState } from "react";
import clsx from "clsx";
import { CONFIRM_DIALOG_ICON_SIZE } from "../../constants/icons";
import { useTranslation } from "react-i18next";
import { ICON_SIZES } from "../../helpers/constants";

interface IInputDialog {
  title: string;
  onClose: () => void;
  onConfirm: (answer: string) => void;
  description: string;
  confirmKind?: string;
  icon?: ReactElement;
  type?: string;
  placeholder?: string;
  note?: string;
  value?: any;
  isLoading?: boolean;
}

const InputDialog: React.FC<IInputDialog> = ({
  title,
  onClose,
  onConfirm,
  description,
  confirmKind = "save",
  icon = <MdInfo size={CONFIRM_DIALOG_ICON_SIZE} />,
  type = "number",
  placeholder = "",
  note = "",
  value = "",
  isLoading = false,
}) => {
  const [answer, setAnswer] = useState<string>(value);
  const formId = useId();
  const { t } = useTranslation();

  return (
    <Modal
      width="md:max-w-[540px] md:min-w-[450px]"
      onEscape={() => {
        if (!isLoading) {
          onClose();
        }
      }}
      className="!shadow-md !rounded-lg"
    >
      <Modal.Header
        icon={icon}
        onClose={() => {
          if (!isLoading) {
            onClose();
          }
        }}
        title={title}
      />
      <Modal.Body>
        <div className={clsx(["flex", "flex-col", "gap-2"])}>
          <p className="text-sm">{description}</p>
          <form
            id={formId}
            onSubmit={(e: FormEvent) => {
              e?.preventDefault?.();
              onConfirm(answer);
            }}
          >
            <TextInput
              value={answer}
              onChange={(e: any) => setAnswer(e?.target?.value)}
              required
              placeholder={placeholder}
              type={type}
            />
          </form>
          {note && <div className="text-xs">{note}</div>}
        </div>
      </Modal.Body>
      <Modal.Footer
        actions={[
          {
            kind: "normal",
            onClick: () => {
              if (!isLoading) {
                onClose();
              }
            },
            disabled: isLoading,
            text: t("shared.cancel"),
            icon: <MdClose size={ICON_SIZES.MD} />,
          },
          {
            kind: confirmKind,
            onClick: () => {},
            text: t("shared.confirm"),
            icon: <MdCheck size={ICON_SIZES.MD} />,
            form: formId,
            type: "submit",
            disabled: isLoading,
            isLoading,
          },
        ]}
      />
    </Modal>
  );
};

export default InputDialog;
