import clsx from "clsx";
import TwoSidebars from "../../layouts/TwoSidebars/TwoSidebars";
import { MdArrowBack, MdCheck, MdRefresh } from "react-icons/md";
import ButtonGroup from "../../components/ButtonGroup/ButtonGroup";
import { useTranslation } from "react-i18next";
import PageTitle from "../../components/PageTitle/PageTitle";
import {
  BUTTON_STYLES,
  ICON_SIZES,
  MOBILE_BREAKPOINT_MEDIA_QUERY,
} from "../../helpers/constants";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import SectionTitle from "../../components/SectionTitle/SectionTitle";
import Stepper from "../../components/Stepper/Stepper";
import InfoBlock from "../../components/InfoBlock/InfoBlock";
import { useState } from "react";
import { isPwa, showNotification, sleep } from "../../helpers/utils";
import { useQueryClient } from "@tanstack/react-query";
import SettingsBlocks from "../../components/SettingsBlocks/SettingsBlocks";

const ResetCachePage: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ query: MOBILE_BREAKPOINT_MEDIA_QUERY });
  const [isCleaningCache, setIsCleaningCache] = useState<boolean>(false);
  const queryClient = useQueryClient();
  const [cleaningNotifications, setCleaningNotifications] = useState<any>({
    done: false,
    isLoading: false,
  });

  const [cleaningServiceWorker, setCleaningServiceWorker] = useState<any>({
    done: false,
    isLoading: false,
  });

  const [cleaningReactQuery, setCleaningReactQuery] = useState<any>({
    done: false,
    isLoading: false,
  });

  const clearCache = async () => {
    setIsCleaningCache(true);

    setCleaningNotifications({ isLoading: true, done: false });
    setCleaningServiceWorker({ isLoading: false, done: false });
    setCleaningReactQuery({ isLoading: false, done: false });

    if (isPwa()) {
      if ("clearAppBadge" in navigator) {
        (navigator as any).clearAppBadge();
      }
    }
    await sleep(1000);
    setCleaningNotifications({ isLoading: false, done: true });

    setCleaningServiceWorker({ isLoading: true, done: false });
    await caches.keys().then(function (cacheNames) {
      return Promise.allSettled(
        cacheNames.map(async function (cacheName) {
          return await caches.delete(cacheName);
        })
      );
    });
    await sleep(1000);

    setCleaningServiceWorker({ isLoading: false, done: true });

    setCleaningReactQuery({ isLoading: true, done: false });
    await queryClient.refetchQueries();
    await sleep(1000);
    setCleaningReactQuery({ isLoading: false, done: true });
    showNotification({
      message: t("Caché borrada correctamente"),
      kind: "success",
    });

    setIsCleaningCache(false);
    // window?.location?.reload?.();
  };

  return (
    <TwoSidebars
      right={
        <>{<SettingsBlocks layout="vertical" columns={isMobile ? 2 : 1} />}</>
      }
    >
      <div className={clsx(["flex", "w-full", "flex-col", "gap-0"])}>
        <div className={clsx(["flex", "w-full", "flex-col", "text-gray-500"])}>
          <PageTitle
            title={t("Ajustes")}
            subtitle={t("Borrar caché")}
            rightSide={
              <ButtonGroup
                align="right"
                gap={1}
                buttons={[
                  {
                    icon: <MdArrowBack size={ICON_SIZES.SM} />,
                    onClick: () => {
                      navigate("/settings/");
                    },
                    classes: isMobile ? BUTTON_STYLES.HEADING : "",
                  },
                ]}
              />
            }
          />
          <div className="pb-4">
            <div className={clsx(["flex", "flex-col", "w-full"])}>
              <SectionTitle title={t("¿Todo bien?")} />
            </div>
            <div className="text-gray-800 text-sm flex w-full">
              Si estás teniendo algún tipo de problema borra la caché. En la
              mayoría de los casos se solucionará después de borrar la caché y
              recargar la aplicación.
            </div>
          </div>
          <div className="w-full flex  flex-col gap-6">
            <Stepper
              steps={[
                {
                  title: "Notificaciones",
                  description: "Limpiar badges de notificaciones",
                  ...cleaningNotifications,
                },
                {
                  title: "Caché PWA",
                  description: "Limpiar caché del service worker",
                  ...cleaningServiceWorker,
                },
                {
                  title: "Caché API",
                  description: "Limpiar caché del API",
                  ...cleaningReactQuery,
                },
              ]}
            />
            <InfoBlock
              message="La limpieza de caché puede tardar un momento. Te recomendamos que no abandones esta pantalla hasta que la limpieza haya finalizado."
              title="Importante"
            />
            <div className={clsx(["flex", "w-full"])}>
              <ButtonGroup
                align="right"
                gap={1}
                buttons={[
                  {
                    icon: <MdRefresh size={ICON_SIZES.SM} />,
                    onClick: () => {
                      showNotification({
                        message: t("Recargando la aplicación"),
                        kind: "info",
                      });
                      window?.location?.reload?.();
                    },
                    text: "Recargar",
                    kind: "normal",
                    disabled: isCleaningCache,
                  },
                  {
                    icon: <MdCheck size={ICON_SIZES.MD} />,
                    onClick: async () => {
                      await clearCache();
                    },
                    kind: "save",
                    text: isCleaningCache ? "Espera..." : "Iniciar",
                    isLoading: isCleaningCache,
                    disabled: isCleaningCache,
                  },
                ]}
              />
            </div>
          </div>
        </div>
      </div>
    </TwoSidebars>
  );
};

export default ResetCachePage;
