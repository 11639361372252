import apiClient from "../helpers/api-client";
import {
  API_ENDPOINT_ALL_ACCOUNTS,
  API_ENDPOINT_GET_USERS,
} from "../helpers/constants";

export const getAllUsers = async () => {
  try {
    return await apiClient.get(API_ENDPOINT_GET_USERS);
  } catch (_e) {
    throw _e;
  }
};

export const getUser = async ({ _id }: { _id: string }) => {
  try {
    return await apiClient.get(`/user`, {
      params: { _id },
    });
  } catch (_e) {
    throw _e;
  }
};

export const getAllUserAccounts = async ({ _id }: { _id: string }) => {
  try {
    return await apiClient.get(API_ENDPOINT_ALL_ACCOUNTS, {
      params: { _id },
    });
  } catch (_e) {
    throw _e;
  }
};
