import { useMemo } from "react";
import transactionsStore from "../store/transactionsStore";

export default function useLastSevenDaysFilter() {
  const { filters, setFilter } = transactionsStore();

  const {
    lastSevenDaysTransactions = {
      selectedDay: "",
    },
  } = filters || {};

  const lastSevenDaysFilter = useMemo(
    () => lastSevenDaysTransactions,
    [lastSevenDaysTransactions]
  );
  const islastSevenDaysFilterActive = useMemo(
    () => !!lastSevenDaysFilter.selectedDay,
    [lastSevenDaysFilter]
  );

  return {
    lastSevenDaysFilter,
    setLastSevenDaysFilter: (values: any) => {
      setFilter({
        name: "lastSevenDaysTransactions",
        values,
      });
    },
    islastSevenDaysFilterActive,
  };
}
