import { saveAs } from "file-saver";
import * as XLSX from "xlsx";
import moment from "moment-timezone";

import {
  API_ENDPOINT_GET_INVOICE,
  EXPENSE_TYPE,
  FILE_TYPE_IMG,
  FILE_TYPE_PDF,
  FILE_TYPE_XML,
  INCOME_TYPE,
  INVOICE_NOTES,
  PAYMENT_METHODS,
} from "./constants";
import JSON_CURRENCY_INFO from "../json/curriencies.json";
import JSON_COUNTRY_INFO from "../json/countries.json";
import _ from "lodash";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import apiClient from "./api-client";
import { transactionStatus } from "./transactions";
import toast from "react-hot-toast";
import i18n from "../i18n";

export const formatNumber = (value: string | number) => {
  return new Intl.NumberFormat()?.format?.(Number(value)) || value;
};

export const getRandomColor = (alpha = 1) => {
  const [r, g, b, a] = [
    Math.floor(Math.random() * 256),
    Math.floor(Math.random() * 256),
    Math.floor(Math.random() * 256),
    alpha,
  ];

  return `rgba(${r}, ${g}, ${b}, ${a})`;
};

export const getRandomRgbaColor = (alpha = 1) => {
  const [r, g, b, a] = [
    Math.floor(Math.random() * 256),
    Math.floor(Math.random() * 256),
    Math.floor(Math.random() * 256),
    alpha,
  ];

  return { r, g, b, a };
};

export const transformToSelectOptions = ({
  data,
  labelField,
  valueField,
}: {
  data: Array<any>;
  labelField: string | any;
  valueField: string | any;
}) => {
  if (data?.length) {
    return data.map((option) => ({
      value: option[valueField],
      label: option[labelField],
    }));
  }

  return [];
};

export const findInArrayOfObjects = ({
  data,
  fieldToCompare,
  valueToFind,
}: {
  data: Array<any>;
  fieldToCompare: string;
  valueToFind: string;
}) => {
  return data?.find?.((row) => row[fieldToCompare] === valueToFind) || null;
};

export const existsInArrayOfObjects = ({
  data,
  fieldToCompare,
  valueToFind,
}: {
  data: Array<any>;
  fieldToCompare: string;
  valueToFind: string;
}) => {
  if (data?.length) {
    const exists = data.find?.(
      (row: any) => row?.[fieldToCompare] === valueToFind
    );

    return !!exists;
  }

  return false;
};

export const uniqueList = ({
  data,
  referenceField,
}: {
  data: Array<any>;
  referenceField: string;
}) => {
  if (data?.length) {
    const res: Array<any> = [];

    data.forEach?.((row: any) => {
      if (
        !existsInArrayOfObjects({
          data: res,
          fieldToCompare: referenceField,
          valueToFind: row[referenceField],
        })
      ) {
        res.push(row);
      }
    });
  }

  return [];
};

export const getDaysBetweenDates = function ({
  startDate,
  endDate,
}: {
  startDate: any;
  endDate: any;
}) {
  var dates = [];

  var currDate = moment(startDate).startOf("day");
  var lastDate = moment(endDate).startOf("day");

  while (currDate.add(1, "days").diff(lastDate) < 0) {
    dates.push(currDate.clone().toDate());
  }

  return dates;
};

export const getDomainName = (url: string) => {
  try {
    const { hostname } = new URL(url);

    if (hostname) {
      if (hostname.toLowerCase().startsWith("www.")) {
        return hostname.replace("wwww.", "");
      }

      return hostname;
    }

    return null;
  } catch (_e) {
    return null;
  }
};

export const isFullscreen = document?.fullscreenElement;

export const toggleFullscreen = () => {
  let elem: any = document.documentElement;
  const isFullscreen = document?.fullscreenElement;

  if (isFullscreen) {
    document.exitFullscreen();
  } else {
    elem
      .requestFullscreen({ navigationUI: "show" })
      .then(() => {})
      .catch((err: any) => {
        alert(
          `An error occurred while trying to switch into fullscreen mode: ${err.message} (${err.name})`
        );
      });
  }
};

export const hasFullscreenSupport = () => {
  return !!document?.fullscreenEnabled;
};

export const getFileTypeByExtension = (filename: string) => {
  const theFilename = `${filename}`.toLowerCase?.();

  if (
    theFilename.endsWith(".jpg") ||
    theFilename.endsWith(".png") ||
    theFilename.endsWith(".jpeg") ||
    theFilename.endsWith(".webp")
  ) {
    return FILE_TYPE_IMG;
  }

  if (theFilename.endsWith(".xml")) {
    return FILE_TYPE_XML;
  }

  if (theFilename.endsWith(".pdf")) {
    return FILE_TYPE_PDF;
  }
};

export const CURRENCY_SELECT_OPTIONS = _.sortBy(
  transformToSelectOptions({
    data: JSON_CURRENCY_INFO,
    labelField: "name",
    valueField: "code",
  }),
  ["label"]
);

export const COUNTRY_SELECT_OPTIONS = _.sortBy(
  transformToSelectOptions({
    data: JSON_COUNTRY_INFO,
    labelField: "name",
    valueField: "code",
  }),
  ["label"]
);

export const getCurrencyDataByCode = ({ code }: { code: string }) => {
  return JSON_CURRENCY_INFO.find(({ code: theCode }) => theCode === code);
};

export const isDarkMode = () => {
  localStorage.setItem("theme", "dark");

  return localStorage?.theme === "dark";
};

export const setMode = () => {
  if (isDarkMode()) {
    document.documentElement.classList.add("dark");
  } else {
    document.documentElement.classList.remove("dark");
  }

  var link = document.createElement("meta");
  link.setAttribute("name", "theme-color");
  if (isDarkMode()) {
    link.content = "#4b5563";
  } else {
    link.content = "red";
  }
  document.getElementsByTagName("head")[0].appendChild(link);
};

export const getTotalByTransactionType = ({
  type,
  account,
  transactions,
}: {
  type: "INCOME" | "EXPENSE";
  account?: string;
  transactions: Array<any>;
}) => {
  let total = 0;

  transactions
    ?.filter?.(
      ({ type: documentType, account: transactionAccount }) =>
        documentType === type &&
        (account ? transactionAccount === account : true)
    )
    ?.forEach?.(({ total: nada = 0 }) => {
      total += Number(nada);
    });

  return total;
};

export const getTotalByCategory = ({
  type,
  account,
  transactions,
  category,
}: {
  type: "INCOME" | "EXPENSE";
  account?: string;
  transactions: Array<any>;
  category: string;
}) => {
  let total = 0;

  transactions
    ?.filter?.(
      ({
        type: documentType,
        account: transactionAccount,
        category: transactionCategory,
      }) =>
        documentType === type &&
        (account ? transactionAccount === account : true) &&
        category === transactionCategory
    )
    ?.forEach?.(({ total: nada = 0 }) => {
      total += Number(nada);
    });

  return total;
};

export const getTotalByPaymentMethod = ({
  type,
  account,
  transactions,
  paymentMethod,
}: {
  type: "INCOME" | "EXPENSE";
  account?: string;
  transactions: Array<any>;
  paymentMethod: string;
}) => {
  let total = 0;

  transactions
    ?.filter?.(
      ({
        type: documentType,
        account: transactionAccount,
        paymentMethod: transactionMethod,
      }) =>
        documentType === type &&
        (account ? transactionAccount === account : true) &&
        (paymentMethod ? transactionMethod === paymentMethod : true)
    )
    ?.forEach?.(({ total: nada = 0 }) => {
      total += Number(nada);
    });

  return total;
};

export const getTotalByPayTo = ({
  type,
  account,
  transactions,
  payTo,
}: {
  type: "INCOME" | "EXPENSE";
  account?: string;
  transactions: Array<any>;
  payTo: string;
}) => {
  let total = 0;

  transactions
    ?.filter?.(
      ({
        type: documentType,
        account: transactionAccount,
        payTo: transactionPayTo,
      }) =>
        documentType === type &&
        (account ? transactionAccount === account : true) &&
        (payTo ? transactionPayTo === payTo : true)
    )
    ?.forEach?.(({ total: nada = 0 }) => {
      total += Number(nada);
    });

  return total;
};

export const getTotalByMonth = ({
  type,
  account,
  transactions,
  month,
}: {
  type: "INCOME" | "EXPENSE";
  account?: string;
  transactions: Array<any>;
  month: number;
}) => {
  let total = 0;

  transactions
    ?.filter?.(
      ({
        type: documentType,
        account: transactionAccount,
        date: transactionDate,
      }) =>
        documentType === type &&
        (account ? transactionAccount === account : true) &&
        (month ? month === new Date(transactionDate).getMonth() + 1 : true)
    )
    ?.forEach?.(({ total: nada = 0 }) => {
      total += Number(nada);
    });

  return total;
};

export const getUniqueAccountsFromTransactions = ({
  transactions,
}: {
  transactions: Array<any>;
}) =>
  Array.from(
    new Set(transactions?.map?.(({ account }) => account) || [])
  )?.filter?.((account) => account);

export const rgbaObjectToString = ({
  r,
  g,
  b,
  a,
}: {
  r: any;
  g: any;
  b: any;
  a: any;
}) => {
  return `rgba(${r}, ${g}, ${b}, ${a})`;
};

export const getUniqueYearsFromTransactions = ({
  transactions,
}: {
  transactions: Array<any>;
}) => {
  return _.sortBy(
    Array.from(
      new Set(
        transactions?.map?.((yearDate) =>
          new Date(yearDate.date).getFullYear()
        ) || []
      )
    ),
    ["value"]
  );
};

export const getTodayTransactionsFromTransactions = ({
  transactions,
}: {
  transactions: Array<any>;
}) => {
  const today = moment().format("yyyy-MM-DD");

  return (
    transactions?.filter?.(
      (transaction) => moment(transaction.date).format("yyyy-MM-DD") === today
    ) || []
  );
};

export const getSentPendingInvites = ({
  invites,
  user,
}: {
  invites: Array<any>;
  user: string;
}) => {
  return (
    invites?.filter?.(
      (invite) => invite.status === "SENT" && invite.from === user
    ) || []
  );
};

export const getReceivedPendingInvites = ({
  invites,
  user,
}: {
  invites: Array<any>;
  user: string;
}) => {
  return (
    invites?.filter?.(
      (invite) => invite.status === "SENT" && invite.to === user
    ) || []
  );
};

export const getAcceptedInvites = ({
  invites,
  user,
}: {
  invites: Array<any>;
  user: string;
}) => {
  return (
    invites
      ?.filter?.(
        (invite) =>
          invite.status === "ACCEPTED" &&
          (invite.from === user || invite.to === user)
      )
      ?.map?.((invite) => {
        return {
          ...invite,
          iSentTheInvite: invite.to === user,
        };
      }) || []
  );
};

export const getUniqueCategoriesFromTransactions = ({
  transactions,
}: {
  transactions: Array<any>;
}) => {
  const categories = transactions?.reduce?.((acc, curr) => {
    // if (curr.details?.length) {
    //   acc.concat(
    //     curr.details?.map(
    //       (detail: any) => detail.category?.trim?.() || "Sin categ"
    //     )
    //   );
    // } else {
    // }
    acc.push(curr.category?.trim?.() || "Sin cate");

    return acc;
  }, []);

  return Array.from(new Set(categories))?.sort?.() || [];
};

export const getUniqueDescriptionsFromTransactions = ({
  transactions,
}: {
  transactions: Array<any>;
}) => {
  const descriptions = transactions?.reduce?.((acc, curr) => {
    acc.push(curr.description?.trim?.() || "Sin cate");

    return acc;
  }, []);

  return Array.from(new Set(descriptions))?.sort?.() || [];
};

export const getUniquePaymentMethodsFromTransactions = ({
  transactions,
}: {
  transactions: Array<any>;
}) => {
  const methods = transactions
    ?.map?.((transaction) => transaction.paymentMethod)
    ?.filter?.((method) => method);

  return Array.from(new Set(methods))?.sort?.() || [];
};

export const getUniquePaidToFromTransactions = ({
  transactions,
}: {
  transactions: Array<any>;
}) => {
  const paidTo = transactions
    ?.map?.((transaction) => transaction.payTo)
    ?.filter?.((payTo) => payTo);

  return Array.from(new Set(paidTo))?.sort?.() || [];
};

export const getUniqueMonthsFromTransactions = ({
  transactions,
}: {
  transactions: Array<any>;
}) => {
  return [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
};

export const getPaymentMethodById = (paymentMethod: string) => {
  return PAYMENT_METHODS()?.find?.(({ id }) => id === paymentMethod);
};

export const exportToExcel = ({
  data,
  fileName = "test",
}: {
  data: any;
  fileName?: string;
}) => {
  const worksheet = XLSX.utils.json_to_sheet(data);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
  const excelBuffer = XLSX.write(workbook, {
    bookType: "xlsx",
    type: "array",
  });
  const blob = new Blob([excelBuffer], {
    type: "application/octet-stream",
  });
  saveAs(blob, `${fileName}.xlsx`);
};

export const exportToJson = ({
  data,
  fileName = "test",
}: {
  data: any;
  fileName?: string;
}) => {
  const jsonStringify = JSON.stringify(data);
  var blob = new Blob([jsonStringify], { type: "application/json" });

  saveAs(blob, `${fileName}.json`);
};

export const exportToCsv = ({
  data = null,
  columnDelimiter = ",",
  lineDelimiter = "\n",
  fileName = "",
}: {
  data: any;
  columnDelimiter?: string;
  lineDelimiter?: string;
  fileName?: string;
}) => {
  let result: any;
  let ctr: any;
  let keys: any;

  if (data === null || !data.length) {
    return null;
  }

  keys = Object.keys(data[0]);

  result = "";
  result += keys.join(columnDelimiter);
  result += lineDelimiter;

  data.forEach((item: any) => {
    ctr = 0;
    keys.forEach((key: any) => {
      if (ctr > 0) {
        result += columnDelimiter;
      }

      result +=
        typeof item[key] === "string" && item[key].includes(columnDelimiter)
          ? `"${item[key]}"`
          : item[key];
      ctr++;
    });
    result += lineDelimiter;
  });

  var blob = new Blob([result], { type: "text/csv" });

  saveAs(blob, `${fileName}.csv`);

  // return result;
};

/* eslint-disable */

export const exportTransactionToPdf = ({
  transactionId,
}: {
  transactionId: any;
}) => {
  apiClient
    .get(`${API_ENDPOINT_GET_INVOICE}/${transactionId}`)
    .then((res: any) => {
      const transaction = res.data;

      if (transaction) {
        const doc = new jsPDF("p", "mm");
        doc.internal.pageSize.width = 48;
        const logo = new Image();
        logo.src = "/apple/favicon-196.png";
        logo.onload = () => {
          // doc.addImage(logo, "", 5, 5, 40, 40);

          const isInvoice = transaction?.details?.length;

          autoTable(doc, {
            margin: {
              left: 1,
              right: 1,
            },
            body: [
              [
                {
                  content: "Cali Rojas",
                  styles: {
                    halign: "left",
                    // fontSize: 16,
                    // textColor: "#ffffff",
                  },
                },
                // {
                //   content: transaction?.date,
                //   styles: {
                //     halign: "right",
                //     fontSize: 12,
                //     // textColor: "#ffffff",
                //   },
                // },
              ],
              [
                {
                  content:
                    (isInvoice ? "Factura" : "Recibo") +
                    ` #INV0001\nFecha: ${transaction?.date}`,
                  styles: {
                    halign: "left",
                  },
                },
              ],
            ],
            theme: "plain",
            styles: {
              // fillColor: "#3366ff",
            },
          });

          autoTable(doc, {
            margin: {
              left: 1,
              right: 1,
            },
            body: [
              [
                {
                  content:
                    "Facturar a:" +
                    ` ${transaction?.payTo}` +
                    "\nEscazú, San José, Costa Rica",
                  styles: {
                    halign: "left",
                  },
                },
              ],
            ],
            theme: "plain",
          });

          // autoTable(doc, {
          //   body: [
          //     [
          //       {
          //         content: "Monto a pagar:",
          //         styles: {
          //           halign: "right",
          //           fontSize: 14,
          //         },
          //       },
          //     ],
          //     [
          //       {
          //         content: `$${formatNumber(transaction?.total)}`,
          //         styles: {
          //           halign: "right",
          //           fontSize: 20,
          //           textColor: "#212f3c",
          //           fontStyle: "bold",
          //         },
          //       },
          //     ],
          //     // [
          //     //   {
          //     //     content: "Fecha de vencimiento: 2022-02-01",
          //     //     styles: {
          //     //       halign: "right",
          //     //     },
          //     //   },
          //     // ],
          //   ],
          //   theme: "plain",
          // });

          autoTable(doc, {
            margin: {
              left: 1,
              right: 1,
            },
            body: [
              [
                {
                  content: "Productos y Servicios",
                  styles: {
                    halign: "left",
                    // fontSize: 14,
                  },
                },
              ],
            ],
            theme: "plain",
          });

          if (isInvoice) {
            autoTable(doc, {
              margin: {
                left: 1,
                right: 1,
              },
              head: [["Descripción", "Cantidad", "Precio", "Total"]],
              body: transaction?.details?.map?.((row: any) => [
                row?.description || "-",
                row?.quantity || "-",
                formatNumber(row?.unitPrice || 0),
                `${formatNumber(
                  Number(row?.quantity) * Number(row?.unitPrice)
                )}`,
              ]),
              theme: "striped",
              headStyles: {
                fillColor: "#212f3c",
              },
            });
          }

          autoTable(doc, {
            margin: {
              left: 1,
              right: 1,
            },
            body: [
              [
                {
                  content: "Subtotal:",
                  styles: {
                    halign: "right",
                  },
                },
                {
                  content: `$${formatNumber(transaction?.subTotal)}`,
                  styles: {
                    halign: "right",
                    fontStyle: "bold",
                    // fontSize: 12,
                  },
                },
              ],
              [
                {
                  content: "Impuestos:",
                  styles: {
                    halign: "right",
                  },
                },
                {
                  content: `$${formatNumber(transaction?.taxes)}`,
                  styles: {
                    halign: "right",
                    fontStyle: "bold",
                    // fontSize: 12,
                  },
                },
              ],
              [
                {
                  content: "Descuento:",
                  styles: {
                    halign: "right",
                  },
                },
                {
                  content: `$${formatNumber(transaction?.discount)}`,
                  styles: {
                    halign: "right",
                    fontStyle: "bold",
                    // fontSize: 12,
                  },
                },
              ],
              [
                {
                  content: "Total:",
                  styles: {
                    halign: "right",
                  },
                },
                {
                  content: `$${formatNumber(transaction?.total)}`,
                  styles: {
                    halign: "right",
                    fontStyle: "bold",
                    // fontSize: 14,
                  },
                },
              ],
            ],
            theme: "plain",
          });

          if (transaction?.notes) {
            autoTable(doc, {
              margin: {
                left: 1,
                right: 1,
              },
              body: [
                [
                  {
                    content: "Notas",
                    styles: {
                      halign: "left",
                      // fontSize: 14,
                    },
                  },
                ],
                [
                  {
                    content: transaction?.notes,
                    styles: {
                      halign: "left",
                    },
                  },
                ],
              ],
              theme: "plain",
            });
          }

          return doc.save("invoice");
        };
      }
    });

  // autoTable(doc, {
  //   body: [
  //     [
  //       {
  //         content: "This is a centered footer",
  //         styles: {
  //           halign: "center",
  //         },
  //       },
  //     ],
  //   ],
  //   theme: "plain",
  // });
};

export const exportTransactionToPdfOriginal = async ({
  transactionId,
  share = false,
}: {
  transactionId: any;
  share?: boolean;
}) => {
  await apiClient
    .get(`${API_ENDPOINT_GET_INVOICE}/${transactionId}`)
    .then((res: any) => {
      const transaction = res.data;

      if (transaction) {
        const doc = new jsPDF("p", "pt");
        const logo = new Image();
        logo.src = "/apple/favicon-196.png";
        logo.onload = async () => {
          doc.addImage(logo, "", 40, 40, 85, 85);

          const isInvoice = transaction?.details?.length;

          autoTable(doc, {
            body: [
              [
                {
                  content: "",
                  styles: {
                    cellWidth: 90,
                  },
                },
                {
                  content: "Cali Rojas",
                  styles: {
                    halign: "left",
                    fontSize: 20,
                    // textColor: "#ffffff",
                  },
                },
                {
                  content: (isInvoice ? "Factura" : "Recibo")?.toUpperCase?.(),
                  styles: {
                    halign: "right",
                    fontSize: 20,
                    fontStyle: "bold",
                    textColor: "#ffffff",
                    fillColor: "#000",
                    cellPadding: {
                      top: 10,
                      right: 10,
                      bottom: 10,
                      left: 10,
                    },
                  },
                },
              ],
              [
                {
                  content: "",
                  styles: {
                    cellWidth: 90,
                  },
                },
                {
                  content:
                    "Teléfono: +506 1234-5678\nEmail: dev@calirojas.com\nSitio web: www.calirojas.com",
                },
                {
                  content: `${
                    isInvoice ? "Factura" : "Recibo"
                  }: #INV0001\nFecha: ${transaction?.date}`,
                  styles: {
                    halign: "right",
                  },
                },
              ],
            ],
            theme: "plain",
            styles: {
              // fillColor: "#3366ff",
            },
          });

          autoTable(doc, {
            body: [
              [
                {
                  content:
                    "Facturar a:" +
                    `\n${transaction?.payTo}` +
                    "\nEscazú, San José" +
                    "\nCosta Rica",
                  styles: {
                    halign: "left",
                    fillColor: "#f1f1f1",
                    cellPadding: {
                      top: 10,
                      right: 10,
                      bottom: 10,
                      left: 10,
                    },
                  },
                },
                {
                  content:
                    "Dirección de envío:" +
                    "\nJohn Doe" +
                    "\nShipping Address line 1" +
                    "\nShipping Address line 2" +
                    "\nZip code - City" +
                    "\nMexico",
                  styles: {
                    halign: "left",
                    fillColor: "#f1f1f1",
                    cellPadding: {
                      top: 10,
                      right: 10,
                      bottom: 10,
                      left: 10,
                    },
                  },
                },
                {
                  content:
                    "De:" +
                    "\nCali Rojas" +
                    "\nShipping Address line 1" +
                    "\nShipping Address line 2" +
                    "\nZip code - City" +
                    "\nCosta Rica",
                  styles: {
                    halign: "right",
                    fillColor: "#f1f1f1",
                    cellPadding: {
                      top: 10,
                      right: 10,
                      bottom: 10,
                      left: 10,
                    },
                  },
                },
              ],
            ],
            theme: "plain",
          });

          // autoTable(doc, {
          //   body: [
          //     [
          //       {
          //         content: "Monto a pagar:",
          //         styles: {
          //           halign: "right",
          //           fontSize: 14,
          //         },
          //       },
          //     ],
          //     [
          //       {
          //         content: `$${formatNumber(transaction?.total)}`,
          //         styles: {
          //           halign: "right",
          //           fontSize: 20,
          //           textColor: "#212f3c",
          //           fontStyle: "bold",
          //         },
          //       },
          //     ],
          //     // [
          //     //   {
          //     //     content: "Fecha de vencimiento: 2022-02-01",
          //     //     styles: {
          //     //       halign: "right",
          //     //     },
          //     //   },
          //     // ],
          //   ],
          //   theme: "plain",
          // });

          if (isInvoice) {
            autoTable(doc, {
              body: [
                [
                  {
                    content: "Productos y Servicios",
                    styles: {
                      halign: "left",
                      fontSize: 14,
                    },
                  },
                ],
              ],
              theme: "plain",
            });

            autoTable(doc, {
              head: [["Descripción", "Cantidad", "Precio", "Subtotal"]],
              body: transaction?.details?.map?.((row: any) => [
                row?.description || "-",
                row?.quantity || "-",
                formatNumber(row?.unitPrice || 0),
                `${formatNumber(
                  Number(row?.quantity) * Number(row?.unitPrice)
                )}`,
              ]),
              theme: "striped",
              headStyles: {
                fillColor: "#212f3c",
              },
            });

            autoTable(doc, {
              body: [
                [
                  {
                    content: "Subtotal:",
                    styles: {
                      halign: "right",
                    },
                  },
                  {
                    content: `$${formatNumber(transaction?.subTotal)}`,
                    styles: {
                      halign: "right",
                      fontStyle: "bold",
                      fontSize: 12,
                    },
                  },
                ],
                [
                  {
                    content: "Impuestos:",
                    styles: {
                      halign: "right",
                    },
                  },
                  {
                    content: `$${formatNumber(transaction?.taxes)}`,
                    styles: {
                      halign: "right",
                      fontStyle: "bold",
                      fontSize: 12,
                    },
                  },
                ],
                [
                  {
                    content: "Descuento:",
                    styles: {
                      halign: "right",
                    },
                  },
                  {
                    content: `$${formatNumber(transaction?.discount)}`,
                    styles: {
                      halign: "right",
                      fontStyle: "bold",
                      fontSize: 12,
                    },
                  },
                ],
                [
                  {
                    content: "Total:",
                    styles: {
                      halign: "right",
                    },
                  },
                  {
                    content: `$${formatNumber(transaction?.total)}`,
                    styles: {
                      halign: "right",
                      fontStyle: "bold",
                      fontSize: 14,
                    },
                  },
                ],
              ],
              theme: "plain",
            });
          } else {
            autoTable(doc, {
              body: [
                [
                  {
                    content: "Descripción:",
                    styles: {
                      halign: "left",
                      fontSize: 12,
                      fontStyle: "bold",
                      cellPadding: {
                        bottom: 0,
                        left: 0,
                      },
                    },
                  },
                ],
                [
                  {
                    content: transaction?.description,
                    styles: {
                      halign: "left",
                      cellPadding: {
                        top: 0,
                        left: 0,
                      },
                      fontSize: 11,
                      // fontSize: 14,
                    },
                  },
                ],
                [
                  {
                    content: "Total:",
                    styles: {
                      halign: "right",
                      fontSize: 12,
                      cellPadding: {
                        top: 10,
                        bottom: 0,
                        left: 0,
                      },
                    },
                  },
                ],
                [
                  {
                    content: `$${formatNumber(transaction?.total)}`,
                    styles: {
                      halign: "right",
                      fontStyle: "bold",
                      fontSize: 14,
                      cellPadding: {
                        top: 0,
                        left: 0,
                      },
                    },
                  },
                ],
              ],
              theme: "plain",
            });
          }

          if (transaction?.notes) {
            autoTable(doc, {
              body: [
                [
                  {
                    content: "Notas",
                    styles: {
                      halign: "left",
                      fontSize: 14,
                    },
                  },
                ],
                [
                  {
                    content: transaction?.notes,
                    styles: {
                      halign: "left",
                    },
                  },
                ],
              ],
              theme: "plain",
            });
          }

          autoTable(doc, {
            body: [
              [
                {
                  content: INVOICE_NOTES,
                  styles: {
                    halign: "center",
                  },
                },
              ],
            ],
            theme: "plain",
          });

          if (share) {
            const elBlob = new File(
              [doc.output("arraybuffer")],
              "Transaction.pdf",
              {
                type: "application/pdf",
              }
            );

            if (
              navigator.canShare({
                text: i18n.t("share.share_pdf"),
                files: [elBlob],
              })
            ) {
              await navigator
                .share({
                  text: i18n.t("share.share_pdf"),
                  files: [elBlob],
                })
                .then((a) => {})
                .catch((e) => {
                  console.log(e);
                });
            }
          } else {
            return doc.save("invoice");
          }
        };
      }
    });
};

export const shareTransactionInPdf = async ({
  transactionId,
}: {
  transactionId: any;
}) => {
  await apiClient
    .get(`${API_ENDPOINT_GET_INVOICE}/${transactionId}`)
    .then(async (res: any) => {
      const transaction = res.data;

      const doc = new jsPDF("p", "pt");
      const logo = new Image();
      logo.src = "/apple/favicon-196.png";

      doc.addImage(logo, "", 40, 40, 85, 85);

      const isInvoice = transaction?.details?.length;

      autoTable(doc, {
        body: [
          [
            {
              content: "",
              styles: {
                cellWidth: 90,
              },
            },
            {
              content: "Cali Rojas",
              styles: {
                halign: "left",
                fontSize: 20,
                // textColor: "#ffffff",
              },
            },
            {
              content: (isInvoice ? "Factura" : "Recibo")?.toUpperCase?.(),
              styles: {
                halign: "right",
                fontSize: 20,
                fontStyle: "bold",
                textColor: "#ffffff",
                fillColor: "#000",
                cellPadding: {
                  top: 10,
                  right: 10,
                  bottom: 10,
                  left: 10,
                },
              },
            },
          ],
          [
            {
              content: "",
              styles: {
                cellWidth: 90,
              },
            },
            {
              content:
                "Teléfono: +506 1234-5678\nEmail: dev@calirojas.com\nSitio web: www.calirojas.com",
            },
            {
              content: `${isInvoice ? "Factura" : "Recibo"}: #INV0001\nFecha: ${
                transaction?.date
              }`,
              styles: {
                halign: "right",
              },
            },
          ],
        ],
        theme: "plain",
        styles: {
          // fillColor: "#3366ff",
        },
      });

      autoTable(doc, {
        body: [
          [
            {
              content:
                "Facturar a:" +
                `\n${transaction?.payTo}` +
                "\nEscazú, San José" +
                "\nCosta Rica",
              styles: {
                halign: "left",
                fillColor: "#f1f1f1",
                cellPadding: {
                  top: 10,
                  right: 10,
                  bottom: 10,
                  left: 10,
                },
              },
            },
            {
              content:
                "Dirección de envío:" +
                "\nJohn Doe" +
                "\nShipping Address line 1" +
                "\nShipping Address line 2" +
                "\nZip code - City" +
                "\nMexico",
              styles: {
                halign: "left",
                fillColor: "#f1f1f1",
                cellPadding: {
                  top: 10,
                  right: 10,
                  bottom: 10,
                  left: 10,
                },
              },
            },
            {
              content:
                "De:" +
                "\nCali Rojas" +
                "\nShipping Address line 1" +
                "\nShipping Address line 2" +
                "\nZip code - City" +
                "\nCosta Rica",
              styles: {
                halign: "right",
                fillColor: "#f1f1f1",
                cellPadding: {
                  top: 10,
                  right: 10,
                  bottom: 10,
                  left: 10,
                },
              },
            },
          ],
        ],
        theme: "plain",
      });

      // autoTable(doc, {
      //   body: [
      //     [
      //       {
      //         content: "Monto a pagar:",
      //         styles: {
      //           halign: "right",
      //           fontSize: 14,
      //         },
      //       },
      //     ],
      //     [
      //       {
      //         content: `$${formatNumber(transaction?.total)}`,
      //         styles: {
      //           halign: "right",
      //           fontSize: 20,
      //           textColor: "#212f3c",
      //           fontStyle: "bold",
      //         },
      //       },
      //     ],
      //     // [
      //     //   {
      //     //     content: "Fecha de vencimiento: 2022-02-01",
      //     //     styles: {
      //     //       halign: "right",
      //     //     },
      //     //   },
      //     // ],
      //   ],
      //   theme: "plain",
      // });

      autoTable(doc, {
        body: [
          [
            {
              content: "Productos y Servicios",
              styles: {
                halign: "left",
                fontSize: 14,
              },
            },
          ],
        ],
        theme: "plain",
      });

      if (isInvoice) {
        autoTable(doc, {
          head: [["Descripción", "Cantidad", "Precio", "Subtotal"]],
          body: transaction?.details?.map?.((row: any) => [
            row?.description || "-",
            row?.quantity || "-",
            formatNumber(row?.unitPrice || 0),
            `${formatNumber(Number(row?.quantity) * Number(row?.unitPrice))}`,
          ]),
          theme: "striped",
          headStyles: {
            fillColor: "#212f3c",
          },
        });
      }

      autoTable(doc, {
        body: [
          [
            {
              content: "Subtotal:",
              styles: {
                halign: "right",
              },
            },
            {
              content: `$${formatNumber(transaction?.subTotal)}`,
              styles: {
                halign: "right",
                fontStyle: "bold",
                fontSize: 12,
              },
            },
          ],
          [
            {
              content: "Impuestos:",
              styles: {
                halign: "right",
              },
            },
            {
              content: `$${formatNumber(transaction?.taxes)}`,
              styles: {
                halign: "right",
                fontStyle: "bold",
                fontSize: 12,
              },
            },
          ],
          [
            {
              content: "Descuento:",
              styles: {
                halign: "right",
              },
            },
            {
              content: `$${formatNumber(transaction?.discount)}`,
              styles: {
                halign: "right",
                fontStyle: "bold",
                fontSize: 12,
              },
            },
          ],
          [
            {
              content: "Total:",
              styles: {
                halign: "right",
              },
            },
            {
              content: `$${formatNumber(transaction?.total)}`,
              styles: {
                halign: "right",
                fontStyle: "bold",
                fontSize: 14,
              },
            },
          ],
        ],
        theme: "plain",
      });

      if (transaction?.notes) {
        autoTable(doc, {
          body: [
            [
              {
                content: "Notas",
                styles: {
                  halign: "left",
                  fontSize: 14,
                },
              },
            ],
            [
              {
                content: transaction?.notes,
                styles: {
                  halign: "left",
                },
              },
            ],
          ],
          theme: "plain",
        });
      }

      const elBlob = new File([doc.output("arraybuffer")], "Transaction.pdf", {
        type: "application/pdf",
      });

      if (
        navigator.canShare({
          text: i18n.t("share.share_transaction"),
          files: [elBlob],
        })
      ) {
        await navigator
          .share({
            text: i18n.t("share.share_transaction"),
            files: [elBlob],
          })
          .then((a) => {})
          .catch((e) => {
            console.log(e);
          });
      } else {
        return doc.output("blob");
      }
    });

  // autoTable(doc, {
  //   body: [
  //     [
  //       {
  //         content: "This is a centered footer",
  //         styles: {
  //           halign: "center",
  //         },
  //       },
  //     ],
  //   ],
  //   theme: "plain",
  // });
};

export const exportTransactionsReportToPdf = async ({
  transactions,
  fileName = "transactions",
  share = false,
}: {
  transactions: Array<any>;
  fileName?: string;
  share?: boolean;
}) => {
  const doc = new jsPDF();

  autoTable(doc, {
    body: [
      [
        {
          content: "Cali Rojas",
          styles: {
            halign: "left",
            fontSize: 20,
            // textColor: "#ffffff",
          },
        },
        {
          content: "",
          styles: {
            halign: "right",
          },
        },
        {
          content: "Transacciones".toUpperCase(),
          styles: {
            halign: "right",
            fontSize: 20,
            fontStyle: "bold",
            textColor: "#ffffff",
            fillColor: "#000",
            cellPadding: {
              top: 5,
              right: 5,
              bottom: 5,
              left: 5,
            },
            cellWidth: 80,
          },
        },
      ],
      [
        {
          content: "dev@calirojas.com",
          styles: {
            halign: "left",
          },
        },
      ],
    ],
    theme: "plain",
    styles: {
      // fillColor: "#3366ff",
    },
  });

  autoTable(doc, {
    head: [["Fecha", "Origen/Destino", "Ingreso", "Gasto", "Estado"]],
    body: transactions?.map?.((row: any) => [
      row?.date || "-",
      row?.payTo || "-",
      row?.type === INCOME_TYPE ? formatNumber(row?.total) : "-",
      row?.type === EXPENSE_TYPE ? formatNumber(row?.total) : "-",
      transactionStatus.getNameById(row?.status),
    ]),
    theme: "striped",
    headStyles: {
      fillColor: "#212f3c",
    },
  });

  // autoTable(doc, {
  //   body: [
  //     [
  //       {
  //         content: "Subtotal:",
  //         styles: {
  //           halign: "right",
  //         },
  //       },
  //       {
  //         content: `$${formatNumber(transaction?.subTotal)}`,
  //         styles: {
  //           halign: "right",
  //           fontStyle: "bold",
  //           fontSize: 12,
  //         },
  //       },
  //     ],
  //     [
  //       {
  //         content: "Impuestos:",
  //         styles: {
  //           halign: "right",
  //         },
  //       },
  //       {
  //         content: `$${formatNumber(transaction?.taxes)}`,
  //         styles: {
  //           halign: "right",
  //           fontStyle: "bold",
  //           fontSize: 12,
  //         },
  //       },
  //     ],
  //     [
  //       {
  //         content: "Descuento:",
  //         styles: {
  //           halign: "right",
  //         },
  //       },
  //       {
  //         content: `$${formatNumber(transaction?.discount)}`,
  //         styles: {
  //           halign: "right",
  //           fontStyle: "bold",
  //           fontSize: 12,
  //         },
  //       },
  //     ],
  //     [
  //       {
  //         content: "Total:",
  //         styles: {
  //           halign: "right",
  //         },
  //       },
  //       {
  //         content: `$${formatNumber(transaction?.total)}`,
  //         styles: {
  //           halign: "right",
  //           fontStyle: "bold",
  //           fontSize: 14,
  //         },
  //       },
  //     ],
  //   ],
  //   theme: "plain",
  // });
  if (share) {
    const elBlob = new File([doc.output("arraybuffer")], `${fileName}.pdf`, {
      type: "application/pdf",
    });

    if (
      navigator.canShare({
        text: i18n.t("share.share_pdf"),
        files: [elBlob],
      })
    ) {
      await navigator
        .share({
          text: i18n.t("share.share_pdf"),
          files: [elBlob],
        })
        .then((a) => {})
        .catch((e) => {
          console.log(e);
        });
    }
  } else {
    return doc.save(fileName);
  }
};

export const isPwa = () => {
  return ["fullscreen", "standalone", "minimal-ui"].some(
    (displayMode) => matchMedia(`(display-mode: ${displayMode})`).matches
  );
};

export const showNativeNotification = async ({
  message,
  kind = "info",
  title = "",
  id = "",
}: {
  message: string;
  kind: "info" | "error" | "success";
  title?: string;
  id?: string;
}) => {
  const leTitle = isPwa() ? "Notificación" : "eXpenser";
  const registration = await navigator.serviceWorker.getRegistration();
  const icon = "/apple/favicon-196.png";

  if (Notification.permission === "granted") {
    if (registration && "showNotification" in registration) {
      registration.showNotification(title || leTitle, {
        body: message,
        icon,
        ...(id ? { tag: id } : {}),
        // requireInteraction: true,
      });
    } else {
      new Notification(title || leTitle, {
        body: message,
        icon,
        ...(id ? { tag: id } : {}),
        // requireInteraction: true,
      });
    }
  } else {
    if (kind !== "info") {
      toast[kind](message);
    } else {
      toast(message);
    }
  }
};

export const showErrorNotification = ({
  message,
  kind = "error",
  title = "",
  id = "",
}: {
  message: string;
  kind: "info" | "error" | "success";
  title?: string;
  id?: string;
}) => {
  if ("setAppBadge" in navigator) {
    (navigator as any).setAppBadge(1);
  }
  showNativeNotification({ message, kind, title, id });
};

/* eslint-enable */
export const showNotification = ({
  kind = "info",
  message,
}: {
  kind: "info" | "error" | "success";
  message: string;
}) => {
  if ("Notification" in window && Notification.permission === "granted") {
    if (kind !== "info") {
      toast[kind](message);
    } else {
      toast(message);
    }
    // showNativeNotification({ message });
  } else {
    if (kind !== "info") {
      toast[kind](message);
    } else {
      toast(message);
    }
  }
};

export const getShortName = (name: string) => {
  if (name) {
    const [shortName] = name?.split(" ");

    return shortName;
  }

  return name;
};

export const getFirstAndLastDayOfMonth = ({
  year,
  month,
}: {
  year: any;
  month: any;
}) => {
  const leMonth = month > 9 ? month : `0${month}`;
  const firstDay = moment(`${year}-${leMonth}-01`)
    ?.startOf?.("month")
    ?.format?.("yyyy-MM-DD");
  const lastDay = moment(`${year}-${leMonth}-01`)
    ?.endOf?.("month")
    ?.format?.("yyyy-MM-DD");

  return {
    firstDay,
    lastDay,
  };
};

export const sleep = (ms: number) => new Promise((r) => setTimeout(r, ms));

export const getCountryDataByCode = ({ code }: { code: string }) => {
  return JSON_COUNTRY_INFO?.find?.(
    ({ code: countryCode }) => code === countryCode
  );
};

export const getAllTimezones = () => {
  return moment.tz.names();
};
