import { create } from "zustand";
import { v4 as uuidv4 } from "uuid";
import apiClient from "../helpers/api-client";
import {
  API_ENDPOINT_ADD_NOTE,
  API_ENDPOINT_ALL_NOTES,
} from "../helpers/constants";

const tasksStore = (set: any, get: any) => ({
  tasks: [],
  isLoading: false,
  isAddingTask: false,
  isDeletingTask: false,
  isLoadingTasks: false,
  errorLoadingTasks: false,

  getTasks: async ({
    onResolved = () => {},
    onError = () => {},
  }: {
    onResolved: (e: any) => void;
    onError: (e: any) => void;
  }) => {
    set({
      isLoading: true,
      isLoadingTasks: true,
      errorLoadingTasks: false,
    });

    apiClient
      .get(API_ENDPOINT_ALL_NOTES)
      .then((tasks) => {
        set({ tasks: tasks.data, isLoading: false, isLoadingTasks: false });
        // set({ tasks, isLoading: false, isLoadingTasks: false }); cali

        onResolved(tasks.data);
      })
      .catch((e: any) => {
        set({
          isLoading: false,
          isLoadingTasks: false,
          errorLoadingTasks: true,
        });
        // set({
        //   isLoading: false,
        //   isLoadingTasks: false,
        //   errorLoadingTasks: true,
        // }); cali

        onError(e);
      });
  },

  addTask: async ({
    onResolved = () => {},
    onError = () => {},
    content = "",
  }: {
    onResolved: (e: any) => void;
    onError: (e: any) => void;
    content: string;
  }) => {
    const taskId = uuidv4();

    set({ isAddingTask: true });

    apiClient
      .post(API_ENDPOINT_ADD_NOTE, {
        task: taskId,
        content,
      })
      .then((res: any) => {
        const data = res.data;

        set({ isAddingTask: false });

        onResolved(data);
      })
      .catch((e: any) => {
        set({ isAddingTask: false });

        onError(e);
      });
  },

  // deleteTask: async ({
  //   onResolved = () => {},
  //   onError = () => {},
  //   taskId = "",
  // }: {
  //   onResolved: (e: any) => void;
  //   onError: (e: any) => void;
  //   taskId: string;
  // }) => {
  //   set({ isDeletingTask: true });

  //   apiClient
  //     .delete(`${API_ENDPOINT_DELETE_TASK}/${taskId}`)
  //     .then((res: any) => {
  //       const data = res.data;
  //       set({ isDeletingTask: false });
  //       onResolved(data);
  //     })
  //     .catch((e) => {
  //       set({ isDeletingTask: false });

  //       onError(e);
  //     });
  // },
});

export default create(tasksStore);
