import DataTable from "react-data-table-component";
import {
  formatNumber,
  getCurrencyDataByCode,
  getTotalByTransactionType,
  getUniqueAccountsFromTransactions,
} from "../../helpers/utils";
import { useTranslation } from "react-i18next";
import CellWithBackground from "../../pages/Transactions/BackgroundCell";
import { getColorFromAccountSettings } from "../../helpers/accounts";
import {
  MdArrowDownward,
  MdCreditCard,
  MdFilterAlt,
  MdFilterAltOff,
} from "react-icons/md";
import TransactionTypeCell from "../../pages/Transactions/TransactionTypeCell";
import {
  EXPENSE_TYPE,
  ICON_SIZES,
  INCOME_TYPE,
  PAGINATION_MIN,
  PAGINATION_ROWS_PER_PAGE_OPTIONS,
} from "../../helpers/constants";
import useUserPreferences from "../../store/userPreferences";
import { useMemo, useState } from "react";
import clsx from "clsx";
import ButtonGroupCell from "../../pages/Transactions/ButtonGroupCell";
import { useNavigate } from "react-router-dom";
import useMainFilter from "../../hooks/useMainFilter";
import useCurrentUser from "../../hooks/useCurrentUser";
import TableSearch from "../TableSearch/TableSearch";

interface IAccountsSummaryTable {
  transactions: Array<any>;
  showIncome?: boolean;
  showExpenses?: boolean;
  showBalance?: boolean;
  showAccount?: boolean;
  currency: string;
}

const AccountsSummaryTable: React.FC<IAccountsSummaryTable> = ({
  transactions,
  showAccount = true,
  showIncome = true,
  showExpenses = true,
  showBalance = true,
  currency,
}) => {
  const [searchTerm, setSearchTerm] = useState<string>("");
  const { t } = useTranslation();
  const { tableResultsPerPage, setTableResultsPerPage, blurAmounts } =
    useUserPreferences();
  const { mainFilter, setMainFilter } = useMainFilter();

  const { getAccountById } = useCurrentUser();
  const currencySymbol =
    getCurrencyDataByCode({
      code: currency,
    })?.symbol || "";

  const availableAccounts = useMemo(() => {
    return getUniqueAccountsFromTransactions({
      transactions,
    })?.map?.((accountId) => getAccountById(accountId));
  }, [transactions, getAccountById]);
  const navigate = useNavigate();

  const COLUMNS = [
    {
      name: "ID",
      selector: (row: any) => row.account,
      sortable: true,
      omit: true,
    },
    {
      cell: (row: any) => {
        const { background, color } = getColorFromAccountSettings(row.settings);

        return (
          <CellWithBackground
            background={background}
            color={color}
            value={row.name}
            icon={<MdCreditCard size={ICON_SIZES.XS} />}
            onClick={() => {
              setMainFilter({ account: row?.account });
              navigate(`/transactions/${mainFilter?.account || ""}`);
            }}
          />
        );
      },
      selector: (row: any) => row.name,
      name: t("shared.account"),
      sortable: true,
      // omit: !showAccount,
    },
    {
      name: t("summary.incomes"),
      selector: (row: any) => {
        return getTotalByTransactionType({
          transactions,
          account: row.account,
          type: INCOME_TYPE,
        });
      },
      omit: mainFilter?.type === EXPENSE_TYPE,
      sortable: true,
      right: true,
      cell: (row: any) => {
        return (
          <TransactionTypeCell currency={currencySymbol} type={INCOME_TYPE}>
            {formatNumber(
              getTotalByTransactionType({
                transactions,
                account: row.account,
                type: INCOME_TYPE,
              })
            )}
          </TransactionTypeCell>
        );
      },
    },
    {
      omit: mainFilter?.type === INCOME_TYPE,
      name: t("summary.expenses"),
      right: true,
      selector: (row: any) => {
        return getTotalByTransactionType({
          transactions,
          account: row.account,
          type: EXPENSE_TYPE,
        });
      },
      cell: (row: any) => {
        return (
          <TransactionTypeCell currency={currencySymbol} type={EXPENSE_TYPE}>
            {formatNumber(
              getTotalByTransactionType({
                transactions,
                account: row.account,
                type: EXPENSE_TYPE,
              })
            )}
          </TransactionTypeCell>
        );
      },
      sortable: true,
    },
    {
      name: t("shared.difference"),
      right: true,
      omit: !!mainFilter?.type,
      cell: (row: any) => {
        const expenses = getTotalByTransactionType({
          transactions,
          account: row.account,
          type: EXPENSE_TYPE,
        });
        const income = getTotalByTransactionType({
          transactions,
          account: row.account,
          type: INCOME_TYPE,
        });

        const isRed = Number(income) - Number(expenses) < 0;

        return (
          <CellWithBackground
            background={isRed ? "rgb(239, 68, 68)" : "rgb(34, 197, 94)"}
            color={"#fff"}
            blur={blurAmounts}
            value={`${currencySymbol}${formatNumber(
              Number(income) + Number(-expenses)
            )}`}
          />
        );
      },
      selector: (row: any) => {
        const expenses = getTotalByTransactionType({
          transactions,
          account: row.account,
          type: EXPENSE_TYPE,
        });
        const income = getTotalByTransactionType({
          transactions,
          account: row.account,
          type: INCOME_TYPE,
        });

        return Number(income) + Number(-expenses);
      },
      sortable: true,
    },
    {
      cell: (row: any) => {
        return (
          <div
            className={clsx(["flex", "w-full", "justify-end", "items-center"])}
          >
            <ButtonGroupCell
              buttons={[
                // {
                //   icon: <MdAttachment size={ICON_SIZES.SM} />,
                //   onClick: () => {},
                //   hide: !row?.attachments?.length,
                // },
                {
                  onClick: (e: any) => {
                    if (mainFilter?.account === row?.account) {
                      setMainFilter({ account: "" });
                      navigate("/reports");
                    } else {
                      setMainFilter({ account: row?.account });
                      navigate(`/reports/${row?.account}`, { replace: true });
                      // navigate(`/transactions/${mainFilter?.account || ""}`);
                    }

                    // if (e?.metaKey || e?.altKey) {
                    //   window.open(
                    //     `/transactions/${mainFilter?.account || ""}`,
                    //     "_blank"
                    //   );
                    // } else {
                    //   navigate(`/transactions/${mainFilter?.account || ""}`);
                    // }
                  },
                  icon: mainFilter?.account ? (
                    <MdFilterAltOff size={ICON_SIZES.SM} />
                  ) : (
                    <MdFilterAlt size={ICON_SIZES.SM} />
                  ),
                },
              ]}
            />
          </div>
        );
      },
      selector: (row: any) => row,
      name: "Filtrar",
      sortable: false,
      right: true,
    },
  ];

  const localSearch = useMemo(() => {
    const lowerCaseTerm = searchTerm?.toLowerCase?.()?.trim?.();
    const data = availableAccounts;

    if (lowerCaseTerm) {
      return (
        data?.filter?.(({ name }: { name: any }) => {
          return name?.toLowerCase?.()?.includes?.(lowerCaseTerm);
        }) || []
      );
    }

    return data;
  }, [availableAccounts, searchTerm]);

  return (
    <div className={clsx(["flex", "flex-col", "gap-2"])}>
      <TableSearch
        onChange={(value) => setSearchTerm(value)}
        onSubmit={() => {}}
      />
      <DataTable
        columns={COLUMNS}
        data={localSearch}
        pagination={availableAccounts?.length > PAGINATION_MIN}
        highlightOnHover={false}
        sortIcon={<MdArrowDownward />}
        selectableRowsVisibleOnly
        paginationRowsPerPageOptions={PAGINATION_ROWS_PER_PAGE_OPTIONS}
        paginationPerPage={tableResultsPerPage}
        paginationComponentOptions={{
          selectAllRowsItem: true,
          selectAllRowsItemText: t("pagination.all"),
          rowsPerPageText: t("pagination.rows_per_page"),
          rangeSeparatorText: t("pagination.separator"),
        }}
        onChangeRowsPerPage={(rows) => {
          setTableResultsPerPage(rows);
        }}
        noDataComponent={
          <div
            className={clsx([
              "px-2",
              "py-4",
              "flex",
              "items-center",
              "justify-center",
              "text-sm",
              "md:text-base",
            ])}
          >
            {t("empty_states.no_records")}
          </div>
        }
      />
    </div>
  );
};

export default AccountsSummaryTable;
