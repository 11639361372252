import { useEffect, useState } from "react";
import clsx from "clsx";
import {
  MdArrowBack,
  MdArrowForward,
  MdCategory,
  MdCheck,
} from "react-icons/md";

import TextInput from "../TextInput/TextInput";
import ButtonGroup from "../ButtonGroup/ButtonGroup";
import categoriesStore from "../../store/categoriesStore";
import IconSelector from "../IconSelector/IconSelector";
import ConfirmDialog from "../ConfirmDialog/ConfirmDialog";
import { CategoryIcon } from "../../pages/Categories/Categories";
import { CONFIRM_DIALOG_ICON_SIZE, UPDATE_ICON } from "../../constants/icons";
import { useTranslation } from "react-i18next";
import { rgbaObjectToString, showNotification } from "../../helpers/utils";
import ColorPicker from "../ColorPicker/ColorPicker";
import {
  CATEGORIES_DEFAULT_BG_COLOR,
  CATEGORIES_DEFAULT_ICON,
  CATEGORIES_DEFAULT_TEXT_COLOR,
} from "../../helpers/constants";
import _ from "lodash";
import useCategories from "../../hooks/useCategories";
import { useNavigate } from "react-router-dom";
import useTransactions from "../../hooks/useTransactions";

interface ICategoryEditPanel {
  category: any;
}

const CategoryEditPanel: React.FC<ICategoryEditPanel> = ({
  category: categoryData,
}) => {
  const navigate = useNavigate();
  const { addCategory } = categoriesStore();
  const [textColor, setTextColor] = useState<any>(
    CATEGORIES_DEFAULT_TEXT_COLOR
  );
  const [bgColor, setBgColor] = useState<any>(CATEGORIES_DEFAULT_BG_COLOR);
  const [icon, setIcon] = useState<string>(CATEGORIES_DEFAULT_ICON);
  const [categoryName, setCategoryName] = useState<string>("");
  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  const [showUpdateDialog, setShowUpdateDialog] = useState<boolean>(false);
  const [iconSearchTerm, setIconSearchTerm] = useState<string>("");
  const { t } = useTranslation();
  const { resetCache } = useTransactions();
  const { allCategoryList } = useCategories();

  const categoryNavigation = () => {
    const cats = allCategoryList?.data?.data || [];

    const currentIndex = _.findIndex(cats, {
      category: categoryData?.category,
    });

    if (currentIndex !== -1) {
      const nextCat = cats?.[currentIndex + 1] || null;
      const prevCat = cats?.[currentIndex - 1] || null;

      return {
        nextCat,
        prevCat,
      };
    }

    return { nextCat: null, prevCat: null };
  };

  useEffect(() => {
    const { category, settings } = categoryData || {};
    const {
      color = CATEGORIES_DEFAULT_TEXT_COLOR,
      bg = CATEGORIES_DEFAULT_BG_COLOR,
      icon = CATEGORIES_DEFAULT_ICON,
    } = settings || {};

    setTextColor(color);
    setBgColor(bg);
    setCategoryName(category || "");
    setIcon(icon);
  }, [categoryData]);

  return (
    <>
      <div className="grid grid-cols-1 gap-4">
        <div className={clsx(["flex", "flex-col", "gap-4"])}>
          <div
            className={clsx([
              "flex",
              "w-full",
              "gap-1",
              "bg-white",
              "py-0 md:p-0",
            ])}
          >
            <div className={clsx(["bg-checkers", "w-full", "rounded-lg"])}>
              <div
                className={clsx([
                  "flex",
                  "w-full",
                  "flex-col",
                  "items-center",
                  "justify-start",
                  "p-4",
                  "md:py-10 md:p-6",
                  "cursor-pointer",
                  "rounded-lg",
                  "transition-all",
                  "gap-1",
                  "shadow-sm",
                ])}
                style={{
                  backgroundColor: rgbaObjectToString(bgColor),
                  color: rgbaObjectToString(textColor),
                }}
              >
                {!icon ? (
                  <>
                    <MdCategory size={128} />
                  </>
                ) : (
                  <CategoryIcon name={icon} size={128} />
                )}
                <div
                  className={clsx([
                    "text-center",
                    "capitalize",
                    "font-medium",
                    "text-base",
                  ])}
                >
                  {categoryName}
                </div>
              </div>
            </div>
          </div>
          <form className={clsx(["flex", "flex-col", "gap-4", "w-full"])}>
            <TextInput
              placeholder={t("categories_page.category_placeholder")}
              onChange={(e: any) => setCategoryName(e?.target?.value)}
              value={categoryName}
              label={t("categories_page.category_label")}
              required
            />
            <div className={clsx(["grid", "grid-cols-2", "gap-2"])}>
              <ColorPicker
                textColor={textColor}
                onChange={(color: any) => {
                  setBgColor(color.rgb);
                }}
                color={bgColor}
                label={t("categories_page.bg_color_label")}
              />
              <ColorPicker
                textColor={bgColor}
                onChange={(color: any) => {
                  setTextColor(color.rgb);
                }}
                color={textColor}
                label={t("categories_page.text_color_label")}
              />
            </div>
          </form>
          <div className={clsx(["flex", "flex-col"])}>
            <TextInput
              value={iconSearchTerm}
              onChange={(e: any) => setIconSearchTerm(e?.target?.value)}
              placeholder={t("categories_page.search_icon_placeholder")}
              disabled={false}
              label="Icono"
            />
            <IconSelector
              onClick={(icon) => setIcon(icon)}
              background={rgbaObjectToString(bgColor)}
              selected={icon}
              color={rgbaObjectToString(textColor) || ""}
            />
          </div>
          <div
            className={clsx([
              "w-full",
              "flex",
              "justify-between",
              "bg-inherit",
              "md:py-3",
              "sticky",
              "bottom-20",
              "md:bottom-0",
            ])}
          >
            <ButtonGroup
              align="left"
              buttons={[
                {
                  icon: <MdArrowBack size={32} />,
                  onClick: () => {
                    navigate(
                      `/settings/categories/${
                        categoryNavigation().prevCat?.category
                      }`,
                      { replace: true }
                    );
                  },
                  disabled: !categoryNavigation()?.prevCat || isUpdating,
                  hide: !categoryNavigation()?.prevCat,
                },
                {
                  icon: <MdArrowForward size={32} />,
                  onClick: () => {
                    navigate(
                      `/settings/categories/${
                        categoryNavigation().nextCat?.category
                      }`,
                      { replace: true }
                    );
                  },
                  disabled: !categoryNavigation()?.nextCat || isUpdating,
                  hide: !categoryNavigation()?.nextCat,
                },
              ]}
              gap={1}
            />
            <ButtonGroup
              gap={1}
              buttons={[
                {
                  text: categoryData ? t("shared.update") : t("shared.save"),
                  icon: <MdCheck size={32} />,
                  kind: categoryData ? "update" : "save",
                  onClick: async () => {
                    setShowUpdateDialog(true);
                  },
                  disabled: isUpdating,
                  isLoading: isUpdating,
                },
              ]}
            />
          </div>
        </div>
      </div>
      {showUpdateDialog && (
        <ConfirmDialog
          title={t("dialogs.categories.update_dialog_title")}
          description={t("dialogs.categories.update_dialog_question")}
          onClose={() => {
            setShowUpdateDialog(false);
          }}
          isLoading={isUpdating}
          confirmKind="save"
          onConfirm={async () => {
            setIsUpdating(true);
            await addCategory({
              onResolved: async (res: any) => {
                await resetCache();
                showNotification({
                  message: t("categories_page.category_updated_message"),
                  kind: "success",
                });

                setIsUpdating(false);
                setShowUpdateDialog(false);
              },
              onError: (err: any) => {
                showNotification({
                  message: t("categories_page.category_update_error_message"),
                  kind: "error",
                });
                setIsUpdating(false);
              },
              params: {
                category: categoryName,
                settings: {
                  color: textColor,
                  bg: bgColor,
                  icon,
                },
                originalCategory: categoryData?.category || categoryData,
              },
            });
          }}
          icon={<UPDATE_ICON size={CONFIRM_DIALOG_ICON_SIZE} />}
        />
      )}
    </>
  );
};

export default CategoryEditPanel;
