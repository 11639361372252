import clsx from "clsx";
import moment from "moment";
import ButtonGroup from "../ButtonGroup/ButtonGroup";
import { IButton } from "../Button/Button";

interface ICommentBubble {
  text: string;
  date: string;
  actions?: Array<IButton>;
}

const CommentBubble: React.FC<ICommentBubble> = ({
  text,
  date,
  actions = [],
}) => {
  return (
    <div
      className={clsx(["flex", "items-start", "flex-col", "gap-1", "w-full"])}
    >
      <div className={clsx(["flex", "flex-col", "flex-shrink-0"])}>
        <div
          className={clsx([
            "font-normal",
            "text-xs",
            "bg-gray-800",
            "text-white",
            "rounded-full",
            "px-2 py-1",
          ])}
        >
          {moment(date).format("yyyy-MM-DD")} {moment(date).format("HH:mm:ss")}
        </div>
      </div>
      <div
        className={clsx([
          "flex",
          "flex-row",
          "items-center",
          "w-full",
          "p-2",
          "gap-2",
          "border-gray-200",
          "bg-yellow-50",
          "rounded-md",
        ])}
      >
        <div
          className={clsx([
            "text-xs",
            "text-gray-900",
            "break-all",
            "flex",
            "w-full",
          ])}
        >
          {text}
        </div>
        <div className={clsx(["flex", "flex-shrink-0"])}>
          {actions?.length > 0 && <ButtonGroup gap={1} buttons={actions} />}
        </div>
      </div>
    </div>
  );
};

export default CommentBubble;
