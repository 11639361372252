import clsx from "clsx";
import { MdArrowDownward, MdArrowUpward } from "react-icons/md";
import {
  formatNumber,
  getCurrencyDataByCode,
  getTotalByTransactionType,
} from "../../helpers/utils";
import { EXPENSE_TYPE, INCOME_TYPE } from "../../helpers/constants";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import moment from "moment";
import i18next from "i18next";
import { useAllTransactions } from "../../hooks/useTransactions";
import useCurrentUser from "../../hooks/useCurrentUser";

const TodayTotalsBar: React.FC = () => {
  const currentLanguage = i18next.language?.toLowerCase?.() || "en";

  const { todayTransactions, allTransactions } = useAllTransactions();
  const { t } = useTranslation();
  const { getAccountById } = useCurrentUser();

  const accountsCurrencies = todayTransactions?.reduce?.(
    (acc: any, current: any) => {
      const accountData = getAccountById(current.account);

      if (accountData) {
        const currencyIndex = _.findIndex(acc, { code: accountData.currency });

        if (currencyIndex !== -1) {
          if (current.type === INCOME_TYPE) {
            acc[currencyIndex].income =
              Number(acc[currencyIndex].income) + Number(current.total);
          } else if (current.type === EXPENSE_TYPE) {
            acc[currencyIndex].expenses =
              Number(acc[currencyIndex].expenses) + Number(current.total);
          }
        } else {
          acc.push({
            code: accountData.currency,
            name: getCurrencyDataByCode({ code: accountData.currency })?.name,
            symbol: getCurrencyDataByCode({ code: accountData.currency })
              ?.symbol,
            income: current.type === INCOME_TYPE ? Number(current.total) : 0,
            expenses: current.type === EXPENSE_TYPE ? Number(current.total) : 0,
          });
        }
      }

      return acc;
    },
    []
  );

  const income = getTotalByTransactionType({
    transactions: todayTransactions,
    type: INCOME_TYPE,
  });
  const expenses = getTotalByTransactionType({
    transactions: todayTransactions,
    type: EXPENSE_TYPE,
  });

  if (!!allTransactions?.isLoading) {
    return null;
  }

  return (
    <div
      className={clsx([
        "flex",
        "justify-center",
        "w-full",
        "sticky",
        "top-[100px]",
        "z-20",
        "-mt-3",
      ])}
    >
      <ul
        className={clsx([
          "rounded-full",
          "bg-gray-800/90",
          "text-white",
          "text-xs",
          "px-3",
          "py-1",
          "inline-flex",
          "flex-row",
          "gap-1",
          "justify-center",
          "shadow-md",
          "uppercase",
          "font-normal",
        ])}
      >
        <li className={clsx(["flex", "flex-row", "gap-1", "items-center"])}>
          <div>{moment().locale(currentLanguage).format("dddd D")}</div>
        </li>
        {income === 0 && expenses === 0 ? (
          <>
            <li className={clsx(["flex", "flex-row", "gap-1", "items-center"])}>
              <MdArrowDownward className={clsx(["text-green-400"])} size={16} />
              <MdArrowUpward className={clsx(["text-red-400"])} size={16} />
              <div>{t("shared.no_movements")}</div>
            </li>
          </>
        ) : (
          <>
            {income > 0 && (
              <li
                className={clsx(["flex", "flex-row", "gap-1", "items-center"])}
              >
                <MdArrowDownward
                  className={clsx(["text-green-400"])}
                  size={16}
                />
                <div>
                  {accountsCurrencies?.length === 1
                    ? accountsCurrencies[0].symbol
                    : ""}
                  {formatNumber(income)}
                </div>
              </li>
            )}

            {expenses > 0 && (
              <li
                className={clsx(["flex", "flex-row", "gap-1", "items-center"])}
              >
                <MdArrowUpward className={clsx(["text-red-400"])} size={16} />
                <div>
                  {accountsCurrencies?.length === 1
                    ? accountsCurrencies[0].symbol
                    : ""}
                  {formatNumber(expenses)}
                </div>
              </li>
            )}
          </>
        )}
      </ul>
    </div>
  );
};

export default TodayTotalsBar;
