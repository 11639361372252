import clsx from "clsx";
import TwoSidebars from "../../layouts/TwoSidebars/TwoSidebars";
import { MdOutlineInbox, MdPersonSearch, MdSync } from "react-icons/md";
import ButtonGroup from "../../components/ButtonGroup/ButtonGroup";
import { useTranslation } from "react-i18next";
import PageTitle from "../../components/PageTitle/PageTitle";
import {
  BUTTON_STYLES,
  ICON_SIZES,
  MOBILE_BREAKPOINT_MEDIA_QUERY,
} from "../../helpers/constants";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import TransactionsCards from "../Transactions/TransactionsCards";
import { useAllTransactions } from "../../hooks/useTransactions";
import SectionTitle from "../../components/SectionTitle/SectionTitle";

const ReceivedTransactionsPage: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { sharedTransactions } = useAllTransactions();
  const isMobile = useMediaQuery({ query: MOBILE_BREAKPOINT_MEDIA_QUERY });

  return (
    <TwoSidebars>
      <div
        className={clsx([
          "flex",
          "items-center",
          "justify-center",
          "w-full",
          "gap-2",
          "flex-col",
          "text-gray-500",
        ])}
      >
        <PageTitle
          title={t("")}
          subtitle={t("Transacciones recibidas")}
          rightSide={
            <ButtonGroup
              align="right"
              gap={1}
              buttons={[
                {
                  icon: <MdSync size={ICON_SIZES.SM} />,
                  onClick: () => {
                    // navigate("/settings/network");
                  },
                  classes: isMobile ? BUTTON_STYLES.HEADING : "",
                },
                {
                  icon: <MdPersonSearch size={ICON_SIZES.SM} />,
                  onClick: () => {
                    navigate("/settings/network");
                  },
                  classes: isMobile ? BUTTON_STYLES.HEADING : "",
                },
              ]}
            />
          }
        />

        {sharedTransactions?.length ? (
          <>
            <div className={clsx(["flex", "flex-col", "w-full"])}>
              <SectionTitle title={t("Transacciones recibidas")} />
            </div>
            <div className="text-gray-800 text-sm flex w-full">
              Importa las transacciones recibidas a una de tus cuentas.
            </div>
            <TransactionsCards
              transactions={sharedTransactions}
              onSelectionChange={() => {}}
            />
          </>
        ) : (
          <>
            <div className={clsx(["flex", "flex-col", "gap-2", "w-full"])}>
              <div
                className={clsx([
                  "flex",
                  "items-center",
                  "justify-center",
                  "py-8",
                ])}
              >
                <MdOutlineInbox
                  size={ICON_SIZES.XL * 2}
                  className="text-gray-400"
                />
              </div>
              <div className="text-gray-800 text-center pb-10">
                No hay transacciones compartidas
              </div>
              <div className="flex flex-col w-full pb-10">
                <ButtonGroup
                  align="center"
                  buttons={[
                    {
                      icon: <MdPersonSearch size={ICON_SIZES.MD} />,
                      onClick: () => {
                        navigate("/settings/network");
                      },
                      text: "Contactos",
                      kind: "save",
                    },
                  ]}
                />
              </div>
            </div>
          </>
        )}
      </div>
    </TwoSidebars>
  );
};

export default ReceivedTransactionsPage;
