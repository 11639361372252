import clsx from "clsx";
import TwoSidebars from "../../layouts/TwoSidebars/TwoSidebars";
import { MdAdd, MdOutlineArchive, MdOutlineNoteAlt } from "react-icons/md";
import ButtonGroup from "../../components/ButtonGroup/ButtonGroup";
import { useTranslation } from "react-i18next";
import TasksListPanel from "../../components/TasksListPanel/TasksListPanel";
import PageTitle from "../../components/PageTitle/PageTitle";
import useModalsStore from "../../store/modalsStore";
import {
  BUTTON_STYLES,
  ICON_SIZES,
  MOBILE_BREAKPOINT_MEDIA_QUERY,
} from "../../helpers/constants";
import { useMediaQuery } from "react-responsive";
import LinksList from "../../components/LinksList/LinksList";
import userPreferences from "../../store/userPreferences";

const NotesPage: React.FC = () => {
  const { t } = useTranslation();
  const { setShowCreateNewTask } = useModalsStore();
  const isMobile = useMediaQuery({ query: MOBILE_BREAKPOINT_MEDIA_QUERY });
  const { notesActiveTab = 0, setNotesActiveTab } = userPreferences();

  return (
    <TwoSidebars>
      <div
        className={clsx([
          "flex",
          "items-center",
          "justify-center",
          "w-full",
          "gap-4",
          "flex-col",
          "text-gray-500",
        ])}
      >
        <div className={clsx(["w-full"])}>
          <PageTitle
            title={t("notes.title")}
            subtitle={t("notes.notes_page_subtitle")}
            rightSide={
              <ButtonGroup
                align="right"
                gap={1}
                buttons={[
                  {
                    icon: <MdAdd size={ICON_SIZES.SM} />,
                    tooltip: t("shared.add_note"),
                    onClick: () => {
                      setShowCreateNewTask(true);
                    },
                    classes: isMobile ? BUTTON_STYLES.HEADING : "",
                  },
                ]}
              />
            }
          />
          <LinksList
            links={[
              {
                text: "Activas",
                onClick: () => setNotesActiveTab(0),
                active: notesActiveTab === 0,
                icon: <MdOutlineNoteAlt size={ICON_SIZES.MD} />,
              },
              {
                text: "Archivadas",
                onClick: () => setNotesActiveTab(1),
                active: notesActiveTab === 1,
                icon: <MdOutlineArchive size={ICON_SIZES.MD} />,
              },
            ]}
          />
          <TasksListPanel
            kind={notesActiveTab === 0 ? "unarchived" : "archived"}
          />
        </div>
      </div>
    </TwoSidebars>
  );
};

export default NotesPage;
