import { MdCheck, MdClose, MdLink } from "react-icons/md";
import { useState } from "react";
import Modal from "../Modal/Modal";

import clsx from "clsx";
import TextInput from "../TextInput/TextInput";
import Select from "../Select/Select";
import linksStore from "../../store/linksStore";
import ConfirmDialog from "../ConfirmDialog/ConfirmDialog";
import { showNotification } from "../../helpers/utils";
import { CONFIRM_DIALOG_ICON_SIZE, PLUS_ONE_ICON } from "../../constants/icons";
import { useTranslation } from "react-i18next";
import IconSelector from "../IconSelector/IconSelector";
import { ICON_SIZES } from "../../helpers/constants";

interface INewLinkModal {
  onClose?: () => void;
  onLinkAdded?: (e: any) => void;
}

const NewLinkModal: React.FC<INewLinkModal> = ({
  onClose = () => {},
  onLinkAdded = () => {},
}) => {
  const { addLink, refreshStoreLinks } = linksStore();
  const [link, setLink] = useState<string>("");
  const [target, setTarget] = useState<"_blank" | "">("_blank");
  const [name, setName] = useState<string>("");
  const [linkType, setLinkType] = useState<"url" | "email" | "tel">("url");
  const [showCreateConfirm, setShowCreateConfirm] = useState<boolean>(false);
  const [icon, setIcon] = useState<string>();
  const [isAddingLink, setIsAddingLink] = useState<boolean>(false);
  const { t } = useTranslation();

  const getLinkFieldData = () => {
    switch (linkType) {
      case "url": {
        return {
          label: t("links_page.web_label"),
          placeholder: t("links_page.web_placeholder"),
        };
      }
      case "tel": {
        return {
          label: t("links_page.phone_label"),
          placeholder: t("links_page.phone_placeholder"),
        };
      }
      default: {
        return {
          label: t("links_page.email_label"),
          placeholder: t("links_page.email_placeholder"),
        };
      }
    }
  };

  return (
    <>
      <Modal width="md:min-w-[500px]" onEscape={() => onClose()}>
        <Modal.Header
          title={t("links_page.add_title")}
          onClose={() => onClose()}
          icon={<MdLink size={CONFIRM_DIALOG_ICON_SIZE} />}
        />
        <Modal.Body>
          <form
            id="add-link"
            onSubmit={async (e) => {
              e?.preventDefault?.();

              setShowCreateConfirm(true);
              // onClose();
            }}
          >
            <div className="flex flex-col gap-4">
              <div className={clsx(["flex", "flex-row", "gap-4"])}>
                <TextInput
                  label={t("links_page.name_label")}
                  value={name}
                  onChange={(e: any) => setName(e?.target?.value)}
                  required
                  placeholder={t("links_page.name_placeholder")}
                />
                <Select
                  required
                  label={t("links_page.type_label")}
                  emptyOption={{ show: false }}
                  options={[
                    { label: t("links_page.type_phone"), value: "tel" },
                    { label: t("links_page.type_email"), value: "email" },
                    { label: t("links_page.type_web"), value: "url" },
                  ]}
                  value={linkType}
                  onChange={(e: any) => setLinkType(e?.target?.value)}
                />
              </div>
              <TextInput
                label={getLinkFieldData().label}
                value={link}
                onChange={(e: any) => setLink(e?.target?.value)}
                required
                type={linkType}
                placeholder={getLinkFieldData().placeholder}
              />
              <label className={clsx("inline-flex", "items-center", "gap-2")}>
                <input
                  type="checkbox"
                  checked={target === "_blank"}
                  onChange={(e: any) => {
                    if (e?.target?.checked) {
                      setTarget("_blank");
                    } else {
                      setTarget("");
                    }
                  }}
                />
                <span>{t("links_page.open_new_window")}</span>
              </label>

              <IconSelector
                onClick={(icon: string) => {
                  setIcon(icon);
                }}
                selected={icon}
                background="#f87171"
                color="#fff"
              />
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer
          actions={[
            {
              text: t("shared.save"),
              onClick: () => {},
              kind: "save",
              icon: <MdCheck size={ICON_SIZES.MD} />,
              type: "submit",
              form: "add-link",
            },
            {
              text: t("shared.close"),
              onClick: () => onClose(),
              kind: "normal",
              icon: <MdClose size={ICON_SIZES.MD} />,
            },
          ]}
        />
      </Modal>
      {showCreateConfirm && (
        <ConfirmDialog
          icon={<PLUS_ONE_ICON size={CONFIRM_DIALOG_ICON_SIZE} />}
          description={t("dialogs.links.save_dialog_question")}
          onClose={() => setShowCreateConfirm(false)}
          onConfirm={async () => {
            setIsAddingLink(true);
            await addLink({
              onError: (e) => {
                console.log("Error", e);
                setIsAddingLink(false);
              },
              params: {
                target,
                url: link,
                type: linkType,
                name,
                icon,
              },
              onResolved: (e) => {
                setLink("");
                setName("");
                setIcon("");
                onLinkAdded(e);
                setShowCreateConfirm(false);
                showNotification({
                  message: t("notifications.links.added"),
                  kind: "success",
                });

                refreshStoreLinks({});
                setIsAddingLink(false);
              },
            });
          }}
          isLoading={isAddingLink}
          title={t("dialogs.links.save_dialog_title")}
          confirmKind="save"
        />
      )}
    </>
  );
};

export default NewLinkModal;
