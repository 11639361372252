import { MdCheck, MdClose, MdNoteAlt } from "react-icons/md";
import { useState } from "react";
import Modal from "../Modal/Modal";
import tasksStore from "../../store/tasksStore";

import clsx from "clsx";
import { CONFIRM_DIALOG_ICON_SIZE } from "../../constants/icons";
import { useTranslation } from "react-i18next";
import { showNotification } from "../../helpers/utils";
import { ICON_SIZES } from "../../helpers/constants";
import useNotes from "../../hooks/useNotes";

interface INewTaskModal {
  onClose?: () => void;
  onCreated?: (e: any) => void;
}

const NewTaskModal: React.FC<INewTaskModal> = ({
  onClose = () => {},
  onCreated = () => {},
}) => {
  const [isAddingTask, setIsAddingTask] = useState<boolean>(false);
  const [task, setTask] = useState<string>("");
  const { addTask, getTasks } = tasksStore();
  const { t } = useTranslation();
  const { resetCache } = useNotes();

  return (
    <>
      <Modal
        width="md:min-w-[600px]"
        onEscape={() => {
          if (!isAddingTask) {
            onClose();
          }
        }}
      >
        <Modal.Header
          title={t("notes.add_note_title")}
          onClose={() => {
            if (!isAddingTask) {
              onClose();
            }
          }}
          icon={<MdNoteAlt size={CONFIRM_DIALOG_ICON_SIZE} />}
        />
        <Modal.Body>
          <form
            id="create-task"
            onSubmit={async (e) => {
              e?.preventDefault?.();
              setIsAddingTask(true);

              await addTask({
                onError: (e) => {
                  setIsAddingTask(false);
                  showNotification({
                    message: t("notes.error_adding_note"),
                    kind: "error",
                  });
                },
                content: task,
                onResolved: async (e) => {
                  setIsAddingTask(false);
                  showNotification({
                    message: t("notes.note_added_message"),
                    kind: "success",
                  });
                  setTask("");
                  await resetCache();
                  onClose();
                },
              });
            }}
          >
            <div className="flex flex-col gap-4">
              <textarea
                // x-webkit-speech
                required
                rows={6}
                disabled={isAddingTask}
                className={clsx([
                  "w-full",
                  "border",
                  "text-sm",
                  "md:text-base",
                  "rounded-sm",
                  "p-1",
                  "placeholder:text-gray-400",
                  "placeholder:text-sm",
                  "placeholder:font-light",
                ])}
                placeholder={t("notes.add_note_placeholder")}
                onChange={(e) => setTask(e?.target?.value)}
                value={task}
              />
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer
          actions={[
            {
              text: t("shared.save"),
              onClick: () => {},
              kind: "save",
              icon: <MdCheck size={ICON_SIZES.MD} />,
              type: "submit",
              form: "create-task",
              disabled: isAddingTask,
              isLoading: isAddingTask,
            },
            {
              text: t("shared.close"),
              onClick: () => onClose(),
              kind: "normal",
              icon: <MdClose size={ICON_SIZES.MD} />,
              disabled: isAddingTask,
            },
          ]}
        />
      </Modal>
    </>
  );
};

export default NewTaskModal;
