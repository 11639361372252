import clsx from "clsx";
import { MdDone, MdHourglassEmpty } from "react-icons/md";
import { ICON_SIZES } from "../../helpers/constants";

interface IStepper {
  steps: Array<any>;
}

const Stepper: React.FC<IStepper> = ({ steps }) => {
  return (
    <ol className={clsx(["overflow-hidden", "space-y-4", "w-full"])}>
      {steps?.map?.(({ title, description, isLoading, done }, stepNumber) => (
        <li
          className={clsx(["flex", "flex-row", "gap-2", "w-full"])}
          key={`step_${stepNumber}`}
        >
          <div className={clsx(["flex", "items-center", "w-full"])}>
            <div
              className={clsx([
                "w-8 h-8 min-w-8 min-h-8 max-w-8 max-h-8 shrink-0",
                "border",
                "border-transparent",
                "rounded-full",
                "flex",
                "justify-center",
                "items-center",
                "mr-3",
                "text-xs",
                "font-medium",
                "lg:w-10",
                "lg:h-10",
                "relative",
                // "overflow-hidden",
                {
                  "text-white": done,
                  "bg-green-400": done,
                  // "border-indigo-800": !done && !isLoading,
                  "bg-gray-200": !done && !isLoading,
                  // "bg-gray-100": isLoading,
                },
              ])}
            >
              {isLoading ? (
                <div
                  className={clsx([
                    `h-[${ICON_SIZES.MD}px]`,
                    `w-[${ICON_SIZES.MD}px]`,
                    "flex",
                    "items-center",
                    "justify-center",
                  ])}
                >
                  <div
                    className={clsx([
                      "inline-block",
                      `h-[${ICON_SIZES.MD}px]`,
                      `w-[${ICON_SIZES.MD}px]`,
                      "animate-spin",
                      "rounded-full",
                      "border-4",
                      "border-solid",
                      "border-current",
                      "border-e-transparent",
                      "align-[-0.125em]",
                      "text-surface",
                      "motion-reduce:animate-[spin_1.5s_linear_infinite]",
                      "text-red-400",
                      "text-sm",
                      "md:text-base",
                    ])}
                  />
                </div>
              ) : (
                <>
                  {done && <MdDone size={ICON_SIZES.MD} />}
                  {!done && (
                    <>
                      <MdHourglassEmpty
                        size={ICON_SIZES.SM}
                        className="text-gray-400"
                      />
                    </>
                  )}
                </>
              )}
            </div>
            <div
              className={clsx([
                "flex",
                "flex-col",
                "text-sm",
                "gap-0",
                "w-full",
              ])}
            >
              <div className={clsx(["font-medium"])}>{title}</div>
              <div className={clsx([""])}>{description}</div>
            </div>
          </div>
        </li>
      )) || ""}
    </ol>
  );
};

export default Stepper;
