import clsx from "clsx";
import {
  MdAccountBox,
  MdArrowDownward,
  MdArrowForward,
  MdArrowUpward,
  MdAttachMoney,
  MdOutlineShoppingCart,
} from "react-icons/md";
import { useNavigate } from "react-router-dom";
import {
  findInArrayOfObjects,
  formatNumber,
  getCurrencyDataByCode,
  getShortName,
  rgbaObjectToString,
} from "../../helpers/utils";
import ButtonGroup from "../../components/ButtonGroup/ButtonGroup";
import useHistoryStore from "../../store/historyStore";

import { useCallback, useState } from "react";
import SelectionTotals from "../../components/SelectionTotals/SelectionTotals";
import invoicesStore from "../../store/invoicesStore";
import LoadingWithText from "../../components/LoadingWithText/LoadingWithText";
import EmptyComponentState from "../../components/EmptyComponentState/EmptyComponentState";
import EndpointError from "../../components/EndpointError/EndpointError";
import {
  CHART_COLORS,
  EXPENSE_TYPE,
  FILE_STORAGE_SERVER_URL,
  INCOME_TYPE,
} from "../../helpers/constants";
import { t } from "i18next";
import userPreferences from "../../store/userPreferences";
import useCurrentUser from "../../hooks/useCurrentUser";
import useUsers from "../../hooks/useUsers";
import moment from "moment";
import NetworkProfile from "../../components/NetworkProfile/NetworkProfile";
import { LazyLoadImage } from "react-lazy-load-image-component";

interface ITransactionsCards {
  transactions: Array<any>;
  columnsToHide?: Array<string>;
  onSelectionChange: (params: any) => void;
  selected?: Array<string>;
  searchTerm?: string;
}

const TransactionsCards: React.FC<ITransactionsCards> = ({
  transactions,
  columnsToHide = [],
  onSelectionChange = () => {},
  selected = [],
  searchTerm = null,
}) => {
  const { blurAmounts } = userPreferences();
  const { getAccountById } = useCurrentUser();
  const { getUserById } = useUsers();
  const [openedProfileUserData, setOpenedProfileUserData] = useState<any>(null);

  const getUserData = (_id: string) => {
    return getUserById({ _id }) || {};
  };

  const { isTransactionInHistory, markTransactionAsOpened, historyIsEnabled } =
    useHistoryStore();
  const { errorSearchingTransactions, isSearchingTransactions } =
    invoicesStore();
  const navigate = useNavigate();
  const [selectedTransactions, setSelectedTransactions] =
    useState<Array<string>>(selected);
  const isHidden = (columnName: string) => {
    return !!columnsToHide?.includes?.(columnName);
  };

  const selectedTransactionsSummary = () => {
    const taxes: any = [];
    const discounts: any = [];
    const subTotal = selectedTransactions?.map((transaction: string) => {
      const info = findInArrayOfObjects({
        data: transactions,
        fieldToCompare: "invoice",
        valueToFind: transaction,
      });

      if (info) {
        // if (info.details?.length) {
        //   const algo = info.details.reduce?.((acc: any, currentValue: any) => {
        //     const lineCalculation =
        //       Number(currentValue.quantity) * Number(currentValue.unitPrice);
        //     return lineCalculation + acc;
        //   }, 0);

        //   taxes.push(Number(info.taxes));
        //   discounts.push(Number(info.discount));

        //   return algo;
        // } else {
        taxes.push(Number(info.taxes));
        discounts.push(Number(info.discount));

        return Number(info.subTotal);
        // }
      } else {
        return 0;
      }
    });

    return {
      subTotal:
        subTotal?.reduce?.(
          (acc, currentValue) => Number(acc) + Number(currentValue),
          0
        ) || 0,
      taxes:
        taxes?.reduce?.(
          (acc: any, currentValue: any) => Number(acc) + Number(currentValue),
          0
        ) || 0,
      discounts:
        discounts?.reduce?.(
          (acc: any, currentValue: any) => Number(acc) + Number(currentValue),
          0
        ) || 0,
    };
  };

  const getCurrencySymbol = useCallback(({ account }: { account: string }) => {
    const accountData = getAccountById(account);

    if (accountData) {
      return getCurrencyDataByCode({ code: accountData?.currency })?.symbol;
    }

    return "";
  }, []);

  const onRowClick = (
    event: any,
    {
      transactionId,
      transactionType,
    }: { transactionId: any; transactionType: string }
  ) => {
    event?.stopPropagation?.();

    if (selectedTransactions?.length) {
      const isSelected = selectedTransactions?.includes(transactionId);
      if (isSelected) {
        setSelectedTransactions(
          Array.from(
            new Set(
              selectedTransactions?.filter?.(
                (transaction: string) => transaction !== transactionId
              )
            )
          )
        );
      } else {
        setSelectedTransactions(
          Array.from(new Set([...selectedTransactions, transactionId]))
        );
      }
    } else {
      if (historyIsEnabled && !isTransactionInHistory(transactionId)) {
        markTransactionAsOpened(transactionId);
      }
      navigate(
        `/transactions/${transactionType?.toLowerCase?.()}/${transactionId}`
      );
    }
  };

  return (
    <>
      <div className={clsx(["flex", "flex-col", "gap-10", "w-full"])}>
        {selectedTransactions?.length > 0 && (
          <SelectionTotals
            {...selectedTransactionsSummary()}
            selectionCount={selectedTransactions.length}
          />
        )}
        {isSearchingTransactions ? (
          <LoadingWithText
            text={t("shared.loading_transactions", { count: 1111 })}
          />
        ) : errorSearchingTransactions ? (
          <EndpointError message="No se pudo cargar la lista de transacciones." />
        ) : (
          <>
            {transactions?.length ? (
              <>
                <div
                  className={clsx([
                    "max-w-full",
                    "overflow-auto",
                    // "max-h-[60vh] md:max-h-none",
                  ])}
                >
                  <div
                    className={clsx([
                      "w-full",
                      "max-w-full",
                      "grid grid-cols-1 xl:grid-cols-3 2xl:grid-cols-4 gap-2",
                    ])}
                  >
                    {transactions.map(
                      (
                        {
                          date,
                          invoice,
                          documentNumber,
                          account,
                          payTo,
                          taxes,
                          details,
                          subTotal: manualSubTotal,
                          type,
                          status,
                          fee,
                          paymentMethod,
                          discount,
                          total,
                          sharedBy = {},
                        },
                        index
                      ) => {
                        return (
                          <div
                            onDragStart={(e) => {
                              e.dataTransfer.setData(
                                "transaction",
                                JSON.stringify({
                                  contentType: "transaction",
                                  id: invoice,
                                  status,
                                  account,
                                  type,
                                })
                              );
                            }}
                            id={
                              index === 0 ? "transaction-cards-first-item" : ""
                            }
                            key={index}
                            draggable
                            className={clsx([
                              "transition-all",
                              "relative",
                              "p-2",
                              "shadow-sm",
                              "rounded-md md:rounded-lg",
                              "cursor-pointer",
                              "hover:text-white",
                              "hover:bg-gray-700",
                              "text-gray-800",
                              {
                                "line-through": isTransactionInHistory(invoice),
                                "bg-green-200": type === INCOME_TYPE,
                                "bg-red-200": type === EXPENSE_TYPE,
                              },
                              "text-gray-600",
                              "flex flex-row justify-between items-center gap-1",
                            ])}
                            onClick={(e: any) => {
                              onRowClick(e, {
                                transactionId: invoice,
                                transactionType: type,
                              });
                            }}
                          >
                            <div
                              className={clsx([
                                "flex-shrink-0",
                                "w-[80px]",
                                "h-[80px]",
                                "inline-flex",
                                "items-center",
                                "justify-center",
                                "rounded-md",
                                "shadow-sm",
                                "bg-white",
                                "overflow-hidden",
                              ])}
                              onClick={(e: any) => {
                                e?.stopPropagation?.();
                                setOpenedProfileUserData(
                                  getUserData(sharedBy?.user)
                                );
                              }}
                            >
                              {getUserData(sharedBy?.user)?.picture ? (
                                <LazyLoadImage
                                  alt=""
                                  className={clsx([
                                    "w-full md:w-[200px]",
                                    "rounded-md",
                                    "shadow-md",
                                    "cursor-pointer",
                                    // "rounded-full",
                                    "bg-white",
                                    "!h-full !w-full !max-w-[80px] !max-h-[80px]",
                                    // "mb-5 md:mb-10",
                                    "object-cover",
                                  ])}
                                  src={`${FILE_STORAGE_SERVER_URL}/profile-thumbnail-${
                                    getUserData(sharedBy?.user)?.picture
                                  }`}
                                  placeholder={
                                    <div
                                      className={clsx([
                                        "flex",
                                        "h-full",
                                        "flex",
                                        "items-center",
                                        "justify-center",
                                        "w-full",
                                      ])}
                                    >
                                      <LoadingWithText />
                                    </div>
                                  }
                                />
                              ) : (
                                <div
                                  className={clsx([
                                    "w-[80px]",
                                    "inline-flex",
                                    "items-center",
                                    "justify-start",
                                  ])}
                                >
                                  <MdAccountBox
                                    size={100}
                                    className="text-gray-600"
                                  />
                                </div>
                              )}
                            </div>
                            <div
                              className={clsx(["flex", "flex-col", "w-full"])}
                            >
                              <div
                                className={clsx([
                                  "px-1",
                                  "whitespace-nowrap",
                                  "text-xs md:text-sm",
                                  "shrink-0",
                                ])}
                              >
                                {date}
                              </div>

                              <div
                                className={clsx([
                                  "hidden",
                                  "absolute",
                                  "top-0",
                                  "left-2",
                                  "bottom-0",
                                  "pointer-events-none",
                                  "opacity-60",
                                  "overflow-hidden",
                                  "flex",
                                  "items-center",
                                  "justify-end",
                                  "pr-2",
                                ])}
                                style={{
                                  color:
                                    type === INCOME_TYPE
                                      ? rgbaObjectToString(
                                          CHART_COLORS.INCOME.bg
                                        )
                                      : rgbaObjectToString(
                                          CHART_COLORS.EXPENSE.bg
                                        ),
                                }}
                              >
                                {type === INCOME_TYPE ? (
                                  <MdArrowDownward size={30} />
                                ) : (
                                  <MdArrowUpward size={30} />
                                )}
                              </div>
                              {!isHidden("payTo") && (
                                <div
                                  className={clsx([
                                    "px-1",
                                    "whitespace-nowrap",
                                    "text-xs md:text-sm",
                                    "truncate",
                                  ])}
                                >
                                  {payTo}
                                </div>
                              )}

                              <div
                                className={clsx([
                                  "px-1",
                                  "whitespace-nowrap",
                                  "justify-start",
                                  "items-center",
                                  "flex",
                                  "text-sm md:text-lg font-medium",
                                  {
                                    "blur-sm hover:blur-none":
                                      blurAmounts && type === INCOME_TYPE,
                                  },
                                ])}
                              >
                                {getCurrencyDataByCode({
                                  code: sharedBy?.currencyCode,
                                })?.symbol?.toUpperCase?.() ?? ""}
                                {formatNumber(manualSubTotal || 0)}
                                {Number(fee) > 0 && (
                                  <span className={clsx(["text-xs"])}>
                                    +
                                    {getCurrencySymbol({
                                      account,
                                    })?.toUpperCase?.()}
                                    {formatNumber(fee)}
                                  </span>
                                )}
                              </div>
                              {sharedBy && (
                                <div className="text-xs px-1">
                                  Compartida el{" "}
                                  {moment(sharedBy?.date).format("yyyy-MM-DD")}{" "}
                                  a las{" "}
                                  {moment(sharedBy?.date).format("hh:mm A")}
                                </div>
                              )}
                            </div>
                            <div>
                              <ButtonGroup
                                gap={1}
                                buttons={[
                                  {
                                    icon:
                                      type === INCOME_TYPE ? (
                                        <MdArrowForward size={30} />
                                      ) : (
                                        <MdArrowForward size={30} />
                                      ),
                                    onClick: (e: any) => {
                                      e?.stopPropagation?.();
                                      onRowClick(e, {
                                        transactionId: invoice,
                                        transactionType: type,
                                      });
                                    },
                                    tooltip: "Ver",
                                    kind:
                                      type === INCOME_TYPE ? "save" : "delete",
                                  },
                                ]}
                                align="right"
                              />
                            </div>
                          </div>
                        );
                      }
                    )}
                  </div>
                </div>
              </>
            ) : (
              <EmptyComponentState
                message={t("shared.no_elements_found")}
                buttons={[
                  {
                    icon: <MdOutlineShoppingCart size={32} />,
                    tooltip: t("shared.add_expense"),
                    onClick: () => navigate("/transactions/expense"),
                    isLoading: false,
                    disabled: false,
                  },
                  {
                    icon: <MdAttachMoney size={32} />,
                    tooltip: t("shared.add_income"),
                    onClick: () => navigate("/transactions/income"),
                    isLoading: false,
                    disabled: false,
                  },
                ]}
              />
            )}
          </>
        )}
      </div>
      {openedProfileUserData && (
        <NetworkProfile
          profileInfo={openedProfileUserData}
          onClose={() => setOpenedProfileUserData(null)}
          actions={[]}
          title={
            openedProfileUserData?.name
              ? `Acerca de ${getShortName(openedProfileUserData?.name)}`
              : "Perfil"
          }
        />
      )}
    </>
  );
};

export default TransactionsCards;
