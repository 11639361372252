import clsx from "clsx";
import { MdArrowRight } from "react-icons/md";

interface IExpenserHorizontalLogo {
  color?: string;
}

const ExpenserHorizontalLogo: React.FC<IExpenserHorizontalLogo> = ({
  color = "text-gray-700",
}) => {
  return (
    <div
      className={clsx(["flex", "flex-row", "items-center", "md:justify-end"])}
    >
      <MdArrowRight size={80} className="text-red-600" />
      <h1 className={clsx(["text-4xl", "font-medium", color])}>
        eXpenser
        <span
          className={clsx([
            "text-xs",
            "text-red-600",
            "uppercase",
            {
              hidden: true, //isPwa(),
            },
          ])}
        >
          .app
        </span>
      </h1>
    </div>
  );
};

export default ExpenserHorizontalLogo;
