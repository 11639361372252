import clsx from "clsx";
import { ReactElement } from "react";

interface IBadge {
  kind?: "danger" | "info" | "success" | "warning";
  icon?: ReactElement;
  text?: string;
  onClick?: (text: any) => void;
  hasBackground?: boolean;
}

const Badge: React.FC<IBadge> = ({
  kind = "info",
  icon,
  text,
  onClick = () => {},
  hasBackground = true,
}) => {
  const theClasses = () => {
    switch (kind) {
      case "danger": {
        return [
          "text-md",
          // "font-medium",
          "me-2",
          "px-2.5",
          "py-0.5",
          "rounded",
          {
            "bg-red-900": hasBackground,
          },
          "text-red-300",
        ];
      }
      case "success": {
        return [
          "text-green-800",
          "text-md",
          // "font-medium",
          "me-2",
          "px-2.5",
          "py-0.5",
          "rounded",
          {
            "bg-green-100": hasBackground,
            "dark:bg-green-900": hasBackground,
          },
          "dark:text-green-300",
        ];
      }
      case "warning": {
        return [
          "text-yellow-800",
          "text-md",
          // "font-medium",
          "me-2",
          "px-2.5",
          "py-0.5",
          "rounded",
          {
            "bg-yellow-100": hasBackground,
            "dark:bg-yellow-900": hasBackground,
          },
          "dark:text-yellow-300",
        ];
      }
      default: {
        return [
          "text-gray-800",
          "text-xs",
          "px-3",
          "py-0.5",
          "rounded-full",
          {
            "bg-yellow-400": hasBackground,
          },
        ];
      }
    }
  };

  return (
    <div
      className={clsx([
        "inline-flex",
        "flex-row",
        "gap-1",
        "items-center",
        ...theClasses(),
      ])}
      onClick={() => onClick(text)}
    >
      {icon && icon}
      {text && <span>{text}</span>}
    </div>
  );
};

export default Badge;
