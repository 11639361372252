import clsx from "clsx";
import TwoSidebars from "../../layouts/TwoSidebars/TwoSidebars";
import {
  MdAddComment,
  MdArrowBack,
  MdCheck,
  MdDelete,
  MdInfoOutline,
  MdOutlineArchive,
  MdOutlineUnarchive,
} from "react-icons/md";
import ButtonGroup from "../../components/ButtonGroup/ButtonGroup";
import { useTranslation } from "react-i18next";
import PageTitle from "../../components/PageTitle/PageTitle";
import {
  BUTTON_STYLES,
  ICON_SIZES,
  MOBILE_BREAKPOINT_MEDIA_QUERY,
} from "../../helpers/constants";
import { useMediaQuery } from "react-responsive";
import SectionTitle from "../../components/SectionTitle/SectionTitle";
import CommentBubble from "../../components/CommentBubble/CommentBubble";
import { useNavigate, useParams } from "react-router-dom";
import useNotes from "../../hooks/useNotes";
import { useCallback, useEffect, useState } from "react";
import { deleteNote, updateNote } from "../../api/notes";
import { showNotification } from "../../helpers/utils";
import ConfirmDialog from "../../components/ConfirmDialog/ConfirmDialog";
import InputDialog from "../../components/InputDialog/InputDialog";
import moment from "moment";
import InfoBlock from "../../components/InfoBlock/InfoBlock";

const FORM_ID = "update-note-form";

const NotePage: React.FC = () => {
  const { t } = useTranslation();

  const [noteContent, setNoteContent] = useState<string>("");
  const [comments, setComments] = useState<Array<any>>([]);
  const [archived, setArchived] = useState<boolean>(false);
  const isMobile = useMediaQuery({ query: MOBILE_BREAKPOINT_MEDIA_QUERY });
  const { getNoteById, resetCache: resetNotesCache } = useNotes();
  const { noteId: noteParam } = useParams();

  const [isLoadingNote, setIsLoadingNote] = useState<boolean>(true);
  const loadNote = useCallback(() => {
    setIsLoadingNote(false);
    if (noteParam) {
      const data = getNoteById({ task: noteParam });

      if (data) {
        setNoteContent(data?.content || []);
        setComments(data?.comments || []);
        setArchived(data?.archived);
      }
    }
    setIsLoadingNote(false);
  }, [getNoteById, noteParam]);

  const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false);
  const [showNewCommentDialog, setShowNewCommentDialog] =
    useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  const [showUpdateDialog, setShowUpdateDialog] = useState<boolean>(false);
  const [commentIndexToDelete, setCommentIndexToDelete] = useState<string>("");
  const [showArchiveDialog, setShowArchiveDialog] = useState<boolean>(false);

  const navigate = useNavigate();

  useEffect(() => {
    loadNote();
  }, []);

  return (
    <>
      <TwoSidebars>
        <div
          className={clsx([
            "flex",
            "items-center",
            "justify-center",
            "w-full",
            "gap-4",
            "flex-col",
            "text-gray-500",
          ])}
        >
          <div className={clsx(["flex", "w-full", "flex-col"])}>
            <PageTitle
              title={t("notes.title")}
              subtitle={t("Editar nota")}
              rightSide={
                <ButtonGroup
                  align="right"
                  gap={1}
                  buttons={[
                    {
                      tooltip: "Eliminar",
                      icon: <MdDelete size={ICON_SIZES.SM} />,
                      onClick: async () => {
                        setShowDeleteDialog(true);
                      },
                      classes: isMobile ? BUTTON_STYLES.HEADING : "",
                    },
                    {
                      icon: <MdArrowBack size={ICON_SIZES.SM} />,
                      onClick: () => {
                        navigate("/notes");
                      },
                      classes: isMobile ? BUTTON_STYLES.HEADING : "",
                    },
                  ]}
                />
              }
            />

            <div className={clsx(["flex", "flex-col", "gap-2"])}>
              {!!archived && (
                <div
                  className={clsx([
                    "flex",
                    "flex-row",
                    "gap-1",
                    "items-center",
                    "text-xs",
                    "font-medium",
                    "uppercase",
                    "justify-center",
                  ])}
                >
                  <MdInfoOutline size={ICON_SIZES.SM} />
                  Archivada
                </div>
              )}

              <form
                className={clsx(["w-full", "flex", "flex-col", "gap-4"])}
                onSubmit={(e) => {
                  e?.preventDefault?.();
                  setShowUpdateDialog(true);
                }}
                id={FORM_ID}
              >
                {/* <SectionTitle title="Editar nota" /> */}
                <div
                  className={clsx([
                    "flex",
                    "bg-blue-50",
                    "w-full",
                    "p-3",
                    "rounded-md",
                    "shadow-md",
                  ])}
                >
                  <textarea
                    form={FORM_ID}
                    // x-webkit-speech
                    required
                    rows={10}
                    disabled={false}
                    className={clsx([
                      "w-full",
                      "border",
                      "text-sm",
                      "md:text-base",
                      "rounded-sm",
                      "p-1",
                      "placeholder:text-gray-400",
                      "placeholder:text-sm",
                      "placeholder:font-light",
                      "bg-transparent",
                      "border-0",
                      "text-black",
                    ])}
                    placeholder={t("notes.add_note_placeholder")}
                    onChange={(e) => setNoteContent(e?.target?.value)}
                    value={noteContent}
                  />
                </div>
                <div
                  className={clsx([
                    "w-full",
                    "flex",
                    "flex-row",
                    "justify-between",
                    "items-center",
                  ])}
                >
                  <ButtonGroup
                    gap={1}
                    align="left"
                    buttons={[
                      {
                        hide: archived,
                        icon: <MdOutlineArchive size={ICON_SIZES.MD} />,
                        onClick: () => {
                          setShowArchiveDialog(true);
                        },
                        kind: "normal",
                        disabled: isUpdating,
                      },
                      {
                        hide: !archived,
                        icon: <MdOutlineUnarchive size={ICON_SIZES.MD} />,
                        onClick: () => {
                          setShowArchiveDialog(true);
                        },
                        kind: "normal",
                        disabled: isUpdating,
                      },
                    ]}
                  />
                  <ButtonGroup
                    gap={1}
                    align="right"
                    buttons={[
                      {
                        text: "",
                        icon: <MdAddComment size={ICON_SIZES.MD} />,
                        onClick: () => {
                          setShowNewCommentDialog(true);
                        },
                        kind: "edit",
                        disabled: isUpdating,
                        hide: !comments?.length,
                      },
                      {
                        type: "submit",
                        text: "Actualizar",
                        icon: <MdCheck size={ICON_SIZES.MD} />,
                        isLoading: isUpdating,
                        onClick: () => {},
                        kind: "save",
                        form: FORM_ID,
                      },
                    ]}
                  />
                </div>
                <SectionTitle title="Comentarios" />
                {comments?.length ? (
                  <div className={clsx(["flex", "flex-col", "gap-4"])}>
                    {comments?.map?.((comment, key) => {
                      return (
                        <CommentBubble
                          text={comment?.content}
                          date={comment?.date}
                          key={key}
                          actions={[
                            {
                              onClick: async () => {
                                setCommentIndexToDelete(`${key}`);
                              },
                              icon: <MdDelete size={ICON_SIZES.SM} />,
                              disabled: isUpdating,
                              kind: "delete",
                            },
                          ]}
                        />
                      );
                    })}
                  </div>
                ) : (
                  <InfoBlock
                    message="Puedes agregar comentarios a tu nota para dar seguimiento."
                    title="Agrega comentarios"
                    buttons={[
                      {
                        text: "",
                        icon: <MdAddComment size={ICON_SIZES.SM} />,
                        onClick: () => {
                          setShowNewCommentDialog(true);
                        },
                        kind: "edit",
                        disabled: isUpdating,
                      },
                    ]}
                  />
                )}
              </form>
            </div>
          </div>
        </div>
      </TwoSidebars>
      {showDeleteDialog && (
        <ConfirmDialog
          description="¿Deseas eliminar esta nota?"
          title="Eliminar nota"
          isLoading={isDeleting}
          onConfirm={async () => {
            setIsDeleting(true);
            await deleteNote({ noteId: noteParam || "" });
            await resetNotesCache();
            setIsDeleting(false);

            setShowDeleteDialog(false);
            showNotification({
              kind: "success",
              message: "Nota eliminada",
            });
            navigate("/notes", { replace: true });
          }}
          onClose={() => setShowDeleteDialog(false)}
          confirmKind="delete"
        />
      )}

      {commentIndexToDelete && (
        <ConfirmDialog
          description="¿Deseas eliminar este comentario?"
          title="Eliminar comentario"
          isLoading={isUpdating}
          onConfirm={async () => {
            setIsUpdating(true);
            const newComments = comments?.filter?.(
              (el, index) => `${index}` !== commentIndexToDelete
            );

            await updateNote({
              noteId: noteParam || "",
              fieldsToUpdate: {
                comments: newComments,
              },
            })
              .then(async () => {
                await resetNotesCache();
                showNotification({
                  kind: "success",
                  message: "Comentario eliminado",
                });
                setComments(newComments);
              })
              .finally(() => {
                setIsUpdating(false);
                setCommentIndexToDelete("");
              });
          }}
          onClose={() => setCommentIndexToDelete("")}
          confirmKind="delete"
        />
      )}

      {showUpdateDialog && (
        <ConfirmDialog
          description="¿Deseas actualizar esta nota?"
          title="Actualizar nota"
          isLoading={isUpdating}
          onConfirm={async () => {
            setIsUpdating(true);
            await updateNote({
              noteId: noteParam || "",
              fieldsToUpdate: { content: noteContent },
            })
              .then(async () => {
                await resetNotesCache();
                showNotification({
                  kind: "success",
                  message: "Nota actualizada",
                });
              })
              .finally(() => {
                setIsUpdating(false);
                setShowUpdateDialog(false);
              });
          }}
          onClose={() => setShowUpdateDialog(false)}
          confirmKind="save"
        />
      )}

      {showArchiveDialog && (
        <ConfirmDialog
          description={`¿Deseas ${
            !!archived ? "desarchivar" : "archivar"
          } esta nota?`}
          title={`${!!archived ? "Desarchivar" : "Archivar"} nota`}
          isLoading={isUpdating}
          onConfirm={async () => {
            setIsUpdating(true);
            await updateNote({
              noteId: noteParam || "",
              fieldsToUpdate: { archived: !archived },
            })
              .then(async () => {
                await resetNotesCache();
                showNotification({
                  kind: "success",
                  message: `Nota ${!!archived ? "desarchivada" : "archivada"}`,
                });
                setArchived(!archived);
              })
              .finally(() => {
                setIsUpdating(false);
                setShowArchiveDialog(false);
              });
          }}
          onClose={() => setShowArchiveDialog(false)}
          confirmKind="save"
        />
      )}

      {showNewCommentDialog && (
        <InputDialog
          type="text"
          description="Agrega un comentario:"
          title="Comentario"
          isLoading={isUpdating}
          onClose={() => setShowNewCommentDialog(false)}
          onConfirm={async (answer: string) => {
            setIsUpdating(true);
            await updateNote({
              noteId: noteParam || "",
              fieldsToUpdate: {
                comments: [
                  ...comments,
                  {
                    content: answer,
                    date: moment(),
                  },
                ],
              },
            })
              .then(async () => {
                await resetNotesCache();
                showNotification({
                  kind: "success",
                  message: "Comentario agregado",
                });
                setComments([{ date: moment(), content: answer }, ...comments]);
              })
              .finally(() => {
                setIsUpdating(false);
                setShowUpdateDialog(false);
              });

            setShowNewCommentDialog(false);
          }}
        />
      )}
    </>
  );
};

export default NotePage;
