import clsx from "clsx";
import { ReactElement } from "react";

interface ICellWithBackground {
  value: string;
  color: string;
  background: string;
  blur?: boolean;
  icon?: ReactElement;
  onClick?: () => void;
}

const CellWithBackground: React.FC<ICellWithBackground> = ({
  value,
  color,
  background,
  blur = false,
  icon,
  onClick = () => {},
}) => {
  return (
    <div
      className={clsx([
        "inline-flex",
        "whitespace-nowrap",
        "rounded-full md:rounded-full",
        "py-1",
        "justify-start",
        "items-center",
        "gap-1",
        "px-2",
        "shrink-0",
        {
          "blur-sm hover:blur-none": blur,
        },
      ])}
      style={{ color, background }}
      onClick={() => onClick?.()}
    >
      {icon && icon}
      {value}
    </div>
  );
};

export default CellWithBackground;
