import { useQuery, useQueryClient } from "@tanstack/react-query";
import _ from "lodash";
import { CACHE_KEYS } from "../helpers/constants";
import { getNotes } from "../api/notes";

export default function useNotes() {
  const queryClient = useQueryClient();

  const notes = useQuery({
    queryFn: async () => await getNotes(),
    queryKey: [CACHE_KEYS.CURRENT_USER_NOTES],
  });

  const getNoteById = ({ task }: { task: string }) => {
    return _.find(notes?.data?.data, {
      task,
    });
  };

  const resetCache = async () => {
    return await queryClient.refetchQueries({
      queryKey: [CACHE_KEYS.CURRENT_USER_NOTES],
    });
  };

  return {
    allNotesList: notes,
    getNoteById,
    resetCache,
  };
}
