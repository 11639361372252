import clsx from "clsx";
import TwoSidebars from "../../layouts/TwoSidebars/TwoSidebars";
import { useNavigate, useParams } from "react-router-dom";

import PageTitle from "../../components/PageTitle/PageTitle";
import ButtonGroup from "../../components/ButtonGroup/ButtonGroup";
import {
  MdArrowBack,
  MdAttachMoney,
  MdCategory,
  MdCompareArrows,
  MdFilterAlt,
  MdOutlineShoppingCart,
  MdPalette,
  MdViewSidebar,
} from "react-icons/md";
import userPreferences from "../../store/userPreferences";
import { useCallback, useEffect, useState } from "react";
import CategoryEditPanel from "../../components/CategoryEditPanel/CategoryEditPanel";
import LoadingWithText from "../../components/LoadingWithText/LoadingWithText";
import { ALL_USER_ICONS } from "../../helpers/user-icons";
import { useMediaQuery } from "react-responsive";
import { useTranslation } from "react-i18next";
import SettingsBlocks from "../../components/SettingsBlocks/SettingsBlocks";
import {
  BUTTON_STYLES,
  ICON_SIZES,
  MOBILE_BREAKPOINT_MEDIA_QUERY,
} from "../../helpers/constants";
import useMainFilter from "../../hooks/useMainFilter";
import { getRandomRgbaColor } from "../../helpers/utils";
import useCategories from "../../hooks/useCategories";

interface ICategoryIcon {
  name: string;
}

export const CategoryIcon: React.FC<ICategoryIcon> = ({ name }) => {
  const Icon = ALL_USER_ICONS.find(
    ({ name: iconName }) => name === iconName
  )?.icon;

  if (Icon) {
    return (
      <>
        <Icon size={ICON_SIZES.SM} className="hidden md:flex" />
        <Icon size={ICON_SIZES.SM} className="md:hidden" />
      </>
    );
  }

  return (
    <>
      <MdCategory size={ICON_SIZES.SM} className="hidden md:flex" />
      <MdCategory size={ICON_SIZES.SM} className="md:hidden" />
    </>
  );
};

const CategoryPage: React.FC = () => {
  const { rightPanelIsVisible, setRightPanelIsVisible } = userPreferences();
  const isMobile = useMediaQuery({ query: MOBILE_BREAKPOINT_MEDIA_QUERY });
  const { t } = useTranslation();
  const [categoryData, setCategoryData] = useState<any>({});
  const [categoryLoaded, setCategoryLoaded] = useState<boolean>(false);
  const { setMainFilter } = useMainFilter();
  const { setTransactionsActiveTab } = userPreferences();
  const { getCategoryByName, allCategoryList } = useCategories();

  const navigate = useNavigate();

  const { category: categoryParam } = useParams();

  const loadCategory = useCallback(() => {
    if (categoryParam) {
      const hello = getCategoryByName(categoryParam);

      if (hello) {
        setCategoryData({
          settings: hello?.settings,
          category: hello?.category,
        });
        setCategoryLoaded(true);
      } else {
        setCategoryLoaded(true);
      }
    } else {
      // setCategoryLoaded(true);
    }
  }, [categoryParam, allCategoryList?.isLoading, setCategoryLoaded]);

  useEffect(() => {
    loadCategory();
  }, [categoryParam, loadCategory]);

  const isLoadingSomething = [
    !categoryLoaded,
    !!allCategoryList?.isLoading,
  ]?.includes(true);

  return (
    <TwoSidebars
      rightClasses={clsx(["flex-col-reverse md:flex-col"])}
      right={
        <>
          {/* <SettingsBlocks layout="vertical" /> */}
          {/*<SettingsBlocks layout="vertical" columns={isMobile ? 2 : 1} />*/}

          {<SettingsBlocks layout="vertical" columns={isMobile ? 2 : 1} />}
        </>
      }
    >
      <div className={clsx(["flex", "w-full", "flex-col", "gap-0"])}>
        <PageTitle
          title={"Categorias"}
          subtitle={"Editar categoria"}
          isLoading={false}
          titleLink="/settings"
          rightSide={
            <>
              <ButtonGroup
                align="right"
                gap={1}
                buttons={[
                  {
                    icon: <MdPalette size={ICON_SIZES.SM} />,
                    tooltip: "Color",
                    onClick: () => {
                      setCategoryData({
                        ...categoryData,
                        settings: {
                          ...categoryData?.settings,
                          bg: getRandomRgbaColor(1),
                          color: getRandomRgbaColor(1),
                        },
                      });
                    },
                    hide: !isMobile,
                    classes: isMobile ? BUTTON_STYLES.HEADING : "",
                  },
                  {
                    icon: <MdFilterAlt size={ICON_SIZES.SM} />,
                    classes: isMobile ? BUTTON_STYLES.HEADING : "",
                    onClick: () => {
                      setTransactionsActiveTab(0);

                      setMainFilter({
                        dateFrom: "",
                        dateTo: "",
                        type: "",
                        status: [],
                        payTo: [],
                        // account: "",
                        searchTerm: "",
                        paymentMethod: [],
                        tags: [],
                        // category: [],
                        currency: [],
                        category: [categoryParam],
                      });
                      navigate("/transactions");
                    },
                    kind: "normal",
                  },
                  {
                    icon: <MdArrowBack size={ICON_SIZES.SM} />,
                    classes: isMobile ? BUTTON_STYLES.HEADING : "",

                    tooltip: "Atras",
                    onClick: () => {
                      navigate("/settings/categories");
                    },
                    hide: !isMobile,
                  },
                  {
                    icon: <MdOutlineShoppingCart size={ICON_SIZES.SM} />,
                    tooltip: "Añadir gasto",
                    onClick: () => {
                      navigate("/transactions/expense");
                    },
                    hide: isMobile,
                  },

                  {
                    icon: <MdAttachMoney size={ICON_SIZES.SM} />,
                    tooltip: "Añadir ingreso",
                    hide: isMobile,
                    onClick: () => {
                      navigate("/transactions/income");
                    },
                  },
                  {
                    icon: <MdCompareArrows size={ICON_SIZES.SM} />,
                    tooltip: "Transacciones",
                    onClick: () => {
                      navigate("/transactions");
                    },
                    hide: isMobile,
                  },

                  {
                    icon: <MdViewSidebar size={ICON_SIZES.SM} />,
                    tooltip: "Panel lateral",
                    kind: rightPanelIsVisible ? "primary" : "secondary",
                    hide: isMobile,
                    onClick: () => {
                      setRightPanelIsVisible(!rightPanelIsVisible);
                    },
                  },
                ]}
              />
            </>
          }
        />

        {isLoadingSomething ? (
          <div className={clsx(["flex", "w-full", "justify-start"])}>
            <LoadingWithText
              text={t("shared.loading_categories", { count: 1 })}
            />
          </div>
        ) : (
          <>
            {categoryParam && categoryLoaded && !allCategoryList?.isLoading ? (
              <CategoryEditPanel category={categoryData} />
            ) : null}
          </>
        )}
      </div>
    </TwoSidebars>
  );
};

export default CategoryPage;
