import { MdClose, MdEmail, MdFlag, MdPerson, MdPhone } from "react-icons/md";
import Modal from "../Modal/Modal";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import { FILE_STORAGE_SERVER_URL, ICON_SIZES } from "../../helpers/constants";
import ButtonGroup from "../ButtonGroup/ButtonGroup";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { getCountryDataByCode } from "../../helpers/utils";
import { Link } from "react-router-dom";

interface INetworkProfile {
  onClose: () => void;
  profileInfo: any;
  actions: any;
  title?: string;
}

const NetworkProfile: React.FC<INetworkProfile> = ({
  onClose,
  profileInfo,
  actions = [],
  title = "Perfil",
}) => {
  const { t } = useTranslation();
  return (
    <Modal onEscape={() => onClose()}>
      <Modal.Header title={title} onClose={() => onClose()} />
      <Modal.Body>
        <div className={clsx(["flex", "h-full", "flex-col", "gap-4", "pb-2"])}>
          <div className={clsx(["flex", "flex-row", "gap-3"])}>
            <div className="flex items-center justify-start flex-shrink-0 gap-1 overflow-hidden shadow rounded-md">
              {profileInfo.picture ? (
                <LazyLoadImage
                  src={`${FILE_STORAGE_SERVER_URL}/profile-thumbnail-${profileInfo.picture}`}
                  alt={profileInfo.name}
                  className={clsx(["h-[100px]", "w-[100px]", "object-cover"])}
                />
              ) : (
                <MdPerson
                  className={clsx([
                    "h-[100px]",
                    "w-[100px]",
                    "object-cover",
                    "rounded-full",
                    "text-gray-300",
                    "bg-white",
                  ])}
                />
              )}
            </div>
            <div
              className={clsx([
                "flex",
                "flex-col",
                "gap-1",
                "items-start",
                "justify-center",
                "w-full",
              ])}
            >
              <div
                className={clsx([
                  "flex",
                  "flex-row",
                  "text-sm",
                  "items-center",
                  "gap-1",
                  "font-normal",
                ])}
              >
                {profileInfo?.name}
              </div>
              <div
                className={clsx([
                  "flex",
                  "flex-row",
                  "text-xs",
                  "items-center",
                  "gap-1",
                ])}
              >
                <MdEmail size={ICON_SIZES.XS} />
                {profileInfo?.email}
              </div>
              {profileInfo?.mobile && (
                <div
                  className={clsx([
                    "flex",
                    "flex-row",
                    "text-xs",
                    "items-center",
                    "gap-1",
                  ])}
                >
                  <Link
                    to={`tel:${profileInfo?.mobile}`}
                    className={clsx([
                      "inline-flex",
                      "items-center",
                      "flex-row",
                      "gap-1",
                    ])}
                  >
                    <MdPhone size={ICON_SIZES.XS} />
                    <span>{profileInfo?.mobile}</span>
                  </Link>
                </div>
              )}
              {profileInfo?.country && (
                <div
                  className={clsx([
                    "flex",
                    "flex-row",
                    "text-xs",
                    "items-center",
                    "gap-1",
                  ])}
                >
                  <MdFlag size={ICON_SIZES.XS} />
                  {getCountryDataByCode({ code: profileInfo?.country })?.name ||
                    "País desconocido"}
                </div>
              )}
            </div>
          </div>
          {profileInfo?.intro && (
            <div
              className={clsx([
                "flex",
                "flex-col",
                "gap-3",
                "overflow-auto",
                "max-h-[200px]",
                "my-4",
              ])}
            >
              <p className={clsx(["text-xs", "text-left", "text-black"])}>
                {profileInfo?.intro || "No hay más detalles del usuario."}
              </p>
            </div>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer
        leftContent={<ButtonGroup align="left" buttons={actions} gap={1} />}
        actions={[
          {
            icon: <MdClose size={ICON_SIZES.SM} />,
            onClick: () => onClose(),
            text: t("shared.close"),
          },
        ]}
      />
    </Modal>
  );
};

export default NetworkProfile;
