import { useTranslation } from "react-i18next";
import ButtonGroup from "../ButtonGroup/ButtonGroup";
import clsx from "clsx";
import i18next from "i18next";
import { ICON_SIZES } from "../../helpers/constants";
import { MdCheckCircle, MdRadioButtonUnchecked } from "react-icons/md";

const LanguageSwitcher: React.FC = () => {
  const { i18n } = useTranslation();

  const changeLanguage = (lang: string) => {
    i18n.changeLanguage(lang);
  };

  const current = i18next.language?.toLowerCase?.() || "EN";

  return (
    <ButtonGroup
      align="right"
      gap={1}
      buttons={[
        {
          onClick: () => changeLanguage("en"),
          classes: clsx([
            "!bg-transparent",
            "!text-white",
            "!border-0",
            "!h-auto",
            "py-1",
          ]),
          text: "English",
          icon: current?.startsWith?.("es") ? (
            <MdRadioButtonUnchecked size={ICON_SIZES.SM} />
          ) : (
            <MdCheckCircle
              className={clsx(["text-lime-400"])}
              size={ICON_SIZES.SM}
            />
          ),
        },
        {
          onClick: () => changeLanguage("es"),
          icon: current?.startsWith?.("es") ? (
            <MdCheckCircle
              className={clsx(["text-lime-400"])}
              size={ICON_SIZES.SM}
            />
          ) : (
            <MdRadioButtonUnchecked size={ICON_SIZES.SM} />
          ),
          classes: clsx([
            "!bg-transparent",
            "!text-white",
            "!border-0",
            "!h-auto",
            "py-1",
          ]),
          text: "Español",
        },
      ]}
    />
  );
};

export default LanguageSwitcher;
