import clsx from "clsx";
import { useEffect, useId, useState } from "react";
import { MdAccountBox, MdCheck, MdClose } from "react-icons/md";
import { CONFIRM_DIALOG_ICON_SIZE } from "../../constants/icons";
import TextInput from "../TextInput/TextInput";
import Modal from "../Modal/Modal";
import apiClient from "../../helpers/api-client";
import {
  API_ENDPOINT_UPDATE_PROFILE,
  FILE_STORAGE_SERVER_URL,
  ICON_SIZES,
} from "../../helpers/constants";
import { LazyLoadImage } from "react-lazy-load-image-component";
import LoadingWithText from "../LoadingWithText/LoadingWithText";
import ConfirmDialog from "../ConfirmDialog/ConfirmDialog";
import { Tooltip } from "react-tooltip";
import { useTranslation } from "react-i18next";
import Select from "../Select/Select";
import InfoBlock from "../InfoBlock/InfoBlock";
import { showNotification } from "../../helpers/utils";
import useCurrentUser from "../../hooks/useCurrentUser";

interface IProfileEditModal {
  onClose: () => void;
  onUpdate?: () => void;
}

const FORM_ID = "update-profile";

const ProfileEditModal: React.FC<IProfileEditModal> = ({
  onClose,
  onUpdate = () => {},
}) => {
  const [isUpdatingProfile, setIsUpdatingProfile] = useState<boolean>(false);
  const [file, setFile] = useState<any>(null);
  const [showConfirmDialog, setShowConfirmDialog] = useState<boolean>(false);
  const tooltipId = useId();
  const { t } = useTranslation();
  const { userInfo } = useCurrentUser();

  const [name, setName] = useState<string>(userInfo?.name || "");
  const [picture, setPicture] = useState<string>(
    userInfo?.picture ? `${FILE_STORAGE_SERVER_URL}/${userInfo?.picture}` : ""
  );

  useEffect(() => {
    setName(userInfo?.name || "");
    setPicture(
      userInfo?.picture ? `${FILE_STORAGE_SERVER_URL}/${userInfo?.picture}` : ""
    );
  }, [userInfo]);

  const updateProfile = () => {
    setIsUpdatingProfile(true);

    const formData = new FormData();

    if (file) {
      formData.append("file", file);
    }

    formData.append("name", name);

    apiClient
      .put(API_ENDPOINT_UPDATE_PROFILE, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then(async (res: any) => {
        showNotification({
          message: "Perfil actualizado correctamente",
          kind: "success",
        });

        setShowConfirmDialog(false);
        onUpdate();
      })
      .catch((e) => {
        showNotification({
          message: "Error actualizando tu perfil",
          kind: "error",
        });
      })
      .finally(() => {
        setIsUpdatingProfile(false);
      });
  };

  return (
    <>
      <Modal
        onEscape={() => {
          if (!isUpdatingProfile) {
            onClose();
          }
        }}
      >
        <Modal.Header
          onClose={() => {
            if (!isUpdatingProfile) {
              onClose();
            }
          }}
          title={t("profile_page.title")}
          icon={<MdAccountBox size={CONFIRM_DIALOG_ICON_SIZE} />}
        />
        <Modal.Body>
          <div className="grid grid-cols-1 gap-6">
            <input
              className="hidden"
              accept="image/*"
              type="file"
              id="select-file-to-upload"
              onChange={async (e: any) => {
                const attachment = e?.target?.files;

                if (attachment?.length) {
                  setFile(attachment?.[0]);
                  setPicture(URL.createObjectURL(attachment?.[0]));
                } else {
                  setFile(null);
                  setPicture("");
                }
              }}
              required
            />
            <LazyLoadImage
              alt=""
              className={clsx([
                "w-[150px]",
                "h-[150px]",
                "cursor-pointer",
                "m-auto",
                "rounded-full",
              ])}
              data-tooltip-id={tooltipId}
              data-tooltip-content="Insertar imagen"
              data-tooltip-float
              data-tooltip-position-strategy="fixed"
              data-tooltip-offset={20}
              src={picture}
              placeholder={
                <div
                  className={clsx([
                    "flex",
                    "h-full",
                    "flex",
                    "items-center",
                    "justify-center",
                    "w-full",
                  ])}
                >
                  <LoadingWithText />
                </div>
              }
              onClick={() => {
                const el = document?.getElementById?.("select-file-to-upload");

                if (el) {
                  el?.click?.();
                }
              }}
            />

            <form
              className={clsx([
                "grid",
                "grid-cols-1",
                "md:grid-cols-2",
                "gap-6",
              ])}
              id={FORM_ID}
              onSubmit={async (e: any) => {
                e?.preventDefault?.();
                setShowConfirmDialog(true);
              }}
            >
              <TextInput
                value={name}
                onChange={(e: any) => setName(e?.target?.value)}
                label={t("profile_page.name_label")}
                placeholder={t("profile_page.name_placeholder")}
                required
              />
              <Select options={[]} value="" onChange={() => {}} label="País" />
              <TextInput
                value=""
                onChange={(e: any) => {}}
                label="Sitio web"
                placeholder=""
              />
              <TextInput
                value=""
                onChange={(e: any) => {}}
                label="Teléfono"
                placeholder=""
              />
            </form>
            <InfoBlock message="Tus datos personales se utilizan únicamente para que tus contactos puedan" />
          </div>
        </Modal.Body>
        <Modal.Footer
          actions={[
            {
              text: t("shared.update"),
              onClick: () => {},
              kind: "save",
              form: FORM_ID,
              type: "submit",
              icon: <MdCheck size={ICON_SIZES.MD} />,
              disabled: isUpdatingProfile,
              isLoading: isUpdatingProfile,
            },
            {
              text: t("shared.cancel"),
              onClick: onClose,
              icon: <MdClose size={ICON_SIZES.MD} />,
              disabled: isUpdatingProfile,
            },
          ]}
        />
        <Tooltip id={tooltipId} />
      </Modal>
      {showConfirmDialog && (
        <ConfirmDialog
          icon={<MdAccountBox size={CONFIRM_DIALOG_ICON_SIZE} />}
          title={t("dialogs.profile.update_dialog_title")}
          description={t("dialogs.profile.update_dialog_question")}
          onClose={() => {
            setShowConfirmDialog(false);
          }}
          confirmKind="save"
          onConfirm={() => {
            updateProfile();
          }}
          isLoading={isUpdatingProfile}
        />
      )}
    </>
  );
};

export default ProfileEditModal;
