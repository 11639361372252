import { EXPENSE_TYPE, INCOME_TYPE } from "../../helpers/constants";
import clsx from "clsx";
import { MdArrowDownward, MdArrowUpward } from "react-icons/md";
import userPreferences from "../../store/userPreferences";

interface ITransactionTypeCell {
  type: string;
  currency: string;
  children: any;
}

const TransactionTypeCell: React.FC<ITransactionTypeCell> = ({
  type,
  currency,
  children,
}) => {
  const { blurAmounts } = userPreferences();
  return (
    <div
      className={clsx([
        "inline-flex",
        "whitespace-nowrap",
        "gap-0",
        "flex-row",
        "items-center",
        "shrink-0",
        {
          "blur-sm hover:blur-none": blurAmounts,
        },
      ])}
    >
      {currency}
      {children}
      {type === INCOME_TYPE && (
        <MdArrowDownward className={clsx(["text-green-500"])} size={20} />
      )}
      {type === EXPENSE_TYPE && (
        <MdArrowUpward className={clsx(["text-red-400"])} size={20} />
      )}
    </div>
  );
};

export default TransactionTypeCell;
