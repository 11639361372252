import { useId, useMemo, useState } from "react";
import tasksStore from "../../store/tasksStore";
import clsx from "clsx";
import { MdAdd, MdOutlineNoteAlt } from "react-icons/md";
import ConfirmDialog from "../ConfirmDialog/ConfirmDialog";

import SectionTitle from "../SectionTitle/SectionTitle";
import { Tooltip } from "react-tooltip";
import LoadingWithText from "../LoadingWithText/LoadingWithText";
import EndpointError from "../EndpointError/EndpointError";
import useModalsStore from "../../store/modalsStore";
import { CONFIRM_DIALOG_ICON_SIZE, DELETE_ICON } from "../../constants/icons";
import Slider from "react-slick";
import { useTranslation } from "react-i18next";
import SliderLeftRight from "../Slider/SliderArrowLeft";
import SliderArrowRight from "../Slider/SliderArrowRight";
import { useMediaQuery } from "react-responsive";
import {
  ICON_SIZES,
  MOBILE_BREAKPOINT_MEDIA_QUERY,
} from "../../helpers/constants";
import { useNavigate } from "react-router-dom";
import useNotes from "../../hooks/useNotes";
import ButtonGroup from "../ButtonGroup/ButtonGroup";

interface ITasksListPanel {
  kind?: "archived" | "unarchived" | "all" | undefined;
}

const TasksListPanel: React.FC<ITasksListPanel> = ({ kind }) => {
  const { isLoadingTasks, errorLoadingTasks } = tasksStore();
  const {
    allNotesList: { data: tasks, isLoading },
  } = useNotes();
  const [showTaskDoneDialog, setShowTaskDoneDialog] = useState<boolean>(false);
  const { setShowCreateNewTask } = useModalsStore();
  const [isDeletingTask, setIsDeletingTask] = useState<boolean>(false);
  const isMobile = useMediaQuery({ query: MOBILE_BREAKPOINT_MEDIA_QUERY });
  const navigate = useNavigate();

  const leData = useMemo(() => {
    if (kind === "archived") {
      return tasks?.data?.filter?.(
        ({ archived }: { archived: boolean }) => archived
      );
    } else if (kind === "unarchived") {
      console.log(tasks);
      return tasks?.data?.filter?.(
        ({ archived }: { archived: boolean }) => !archived
      );
    } else {
      return tasks?.data;
    }
  }, [kind, tasks]);

  const { t } = useTranslation();

  const settings = {
    adaptiveHeight: true,
    arrows: false,
    prevArrow: <SliderLeftRight />,
    nextArrow: <SliderArrowRight />,
    responsive: [
      {
        breakpoint: 100000,
        settings: "unslick" as "unslick",
      },
    ],
  };

  //   setIsDeletingTask(true);
  //   deleteTask({
  //     onError: (e) => {
  //       setShowTaskDoneDialog(false);
  //       setIsDeletingTask(false);
  //     },
  //     taskId: taskToMarkAsDone,
  //     onResolved: () => {
  //       showNotification({
  //         message: t("notes.deleted_message"),
  //         kind: "success",
  //       });
  //       setShowTaskDoneDialog(false);
  //       // getTasks({
  //       //   onResolved: () => {
  //       //     // setIsLoading(false);
  //       //     setIsDeletingTask(false);
  //       //   },
  //       //   onError: () => {
  //       //     setIsLoading(false);
  //       //     setIsDeletingTask(false);
  //       //   },
  //       // });
  //     },
  //   });
  // };

  // useEffect(() => {
  //   getTasks({
  //     onResolved: () => setIsLoading(false),
  //     onError: () => setIsLoading(false),
  //   });
  // }, [getTasks]);

  const tooltipId = useId();

  const stillLoading = isLoadingTasks || isLoading;

  return (
    <>
      <div
        className="grid grid-cols-1 gap-2 w-full"
        onDragOver={(e) => e.preventDefault()}
      >
        {stillLoading ? (
          <div className={clsx(["flex", "w-full", "justify-start"])}>
            <LoadingWithText
              text={t("shared.loading_notes", { count: 1111 })}
            />
          </div>
        ) : errorLoadingTasks ? (
          <EndpointError message="No se pudo cargar la lista de notas." />
        ) : tasks?.data?.length ? (
          <>
            {!isMobile && <SectionTitle title={t("notes.title")} />}
            <Slider
              {...settings}
              className={clsx(["gap-2", "flex", "flex-col"])}
            >
              {leData?.map?.(
                ({ task, content }: { task: any; content: any }) => (
                  <div
                    key={task}
                    className={clsx([
                      "flex",
                      "flex-row",
                      "bg-blue-50 md:bg-yellow-200",
                      "md:shadow-sm",
                      "text-gray-800",
                      "px-3",
                      "py-3",
                      "rounded-md",
                      "gap-1",
                      "items-start",
                      "transition-colors",
                      "relative",
                      "overflow-hidden",
                    ])}
                    onClick={() => {
                      // setTaskToMarkAsDone(task);
                      // setShowTaskDoneDialog(true);
                      navigate(`/notes/${task}`);
                    }}
                  >
                    <div className="flex flex-col gap-3">
                      <div
                        className={clsx([
                          "flex",
                          "flex-row",
                          "gap-2",
                          "items-start",
                        ])}
                      >
                        {/* <div className="cursor-pointer hover:text-yellow-300 dark:text-red-500 dark:hover:text-red-600">
                        <div
                          data-tooltip-id={tooltipId}
                          data-tooltip-content={t("shared.delete")}
                          data-tooltip-float
                          data-tooltip-position-strategy="fixed"
                          data-tooltip-offset={20}
                        >
                          <MdTaskAlt size={32} />
                        </div>
                      </div> */}
                        <p className="text-sm break-words pt-1">{content}</p>
                      </div>
                    </div>
                    <Tooltip id={tooltipId} />
                  </div>
                )
              )}
            </Slider>
          </>
        ) : (
          <>
            {/* <SectionTitle title={t("notes.title")} /> */}

            <div className={clsx(["flex", "flex-col", "gap-2", "w-full"])}>
              <div
                className={clsx([
                  "flex",
                  "items-center",
                  "justify-center",
                  "py-8",
                ])}
              >
                <MdOutlineNoteAlt
                  size={ICON_SIZES.XL * 2}
                  className="text-gray-400"
                />
              </div>
              <div className="text-gray-800 text-center pb-10">
                No tienes notas
              </div>
              <div className="flex flex-col w-full pb-10">
                <ButtonGroup
                  align="center"
                  buttons={[
                    {
                      icon: <MdAdd size={ICON_SIZES.MD} />,
                      onClick: () => {
                        setShowCreateNewTask(true);
                        // navigate("/settings/network");
                      },
                      text: "Agregar",
                      kind: "save",
                    },
                  ]}
                />
              </div>
            </div>
            {/* <EmptyComponentState
              message={t("notes.empty_state")}
              buttons={[
                {
                  kind: "primary",
                  icon: <MdAdd size={32} />,
                  text: t("shared.add"),
                  onClick: () => setShowCreateNewTask(true),
                  isLoading: isAddingTask,
                  disabled: isAddingTask,
                },
              ]}
            /> */}
          </>
        )}
        {/* {isAddingTask && (
          <div>
            <LoadingWithText text="Agregando nota..." />
          </div>
        )} */}
      </div>
      {showTaskDoneDialog && (
        <ConfirmDialog
          description={t("notes.delete_question")}
          title={t("notes.delete_note")}
          confirmKind="save"
          onClose={() => {
            setShowTaskDoneDialog(false);
          }}
          onConfirm={() => {
            // markTaskAsDone();
          }}
          icon={<DELETE_ICON size={CONFIRM_DIALOG_ICON_SIZE} />}
          isLoading={isDeletingTask}
        />
      )}
    </>
  );
};

export default TasksListPanel;
