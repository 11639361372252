import { ReactElement } from "react";
import Sidebar from "../../components/Sidebar/Sidebar";
import clsx from "clsx";
import userPreferences from "../../store/userPreferences";
import MediaQuery from "react-responsive";
import MobileNavigation from "../../components/Navigations/MobileNavigation";

interface ITwoSidebars {
  children: ReactElement;
  right?: ReactElement | string | null;
  rightClasses?: string;
  customTopBackground?: any;
  isLoading?: boolean;
}

const TwoSidebars: React.FC<ITwoSidebars> = ({
  children,
  right = null,
  rightClasses = "",
  customTopBackground = null,
  isLoading = false,
}) => {
  const { rightPanelIsVisible } = userPreferences();

  return (
    <>
      <div
        className={clsx([
          "flex",
          "flex-col",
          "md:flex-row",
          "md:h-[100vh]",
          {
            "h-[100vh]": isLoading,
          },
          "pb-[75px]",
          "md:pb-0",
        ])}
      >
        <div
          className={clsx(["flex", "flex-shrink-0", "pb-[40px]", "md:pb-0"])}
        >
          <Sidebar />
        </div>
        <div
          className={clsx([
            "flex",
            "p-4",
            "pt-0 md:pt-4",
            "h-full",
            "md:overflow-y-scroll",
            "md:overflow-x-hidden",
            "flex-col",
            "flex-grow",
            "flex-shrink",
          ])}
        >
          <div
            className={clsx([
              "md:pt-0",
              "pb-0",
              "md:pb-5",
              "min-h-full",
              "flex",
              "flex-col",
              "flex-grow",
            ])}
          >
            <div
              className={clsx([
                'content-[""]',
                "md:hidden",
                "absolute",
                "top-0",
                "left-0",
                "right-0",
                {
                  "h-[185px]": customTopBackground?.bg,
                  "h-[45px]": !customTopBackground?.bg,
                },
                [customTopBackground?.bg || "bg-gray-800"],

                "-z-10",
              ])}
            />
            <div className={clsx(["flex", "flex-grow"])}>{children}</div>
            <div className={clsx(["md:min-h-[15px]", "flex"])} />
          </div>
        </div>
        {right && (
          <MediaQuery maxWidth={767}>
            <div
              className={clsx([
                "flex",
                "flex-col",
                "md:w-[350px]",
                "md:overflow-auto",
                "p-4",
                "pt-0 md:pt-4",
                {
                  "md:bg-[#F5F8FF]/40 border-l border-primary/5": false,
                  "md:bg-gray-100": true,
                },
                "flex-shrink-0",
                "gap-6",
                "md:gap-10",
                rightClasses,
              ])}
            >
              {right}
            </div>
          </MediaQuery>
        )}
        {rightPanelIsVisible && right && (
          <MediaQuery minWidth={767}>
            <div
              className={clsx([
                "flex",
                "flex-col",
                "md:w-[350px]",
                "md:overflow-auto",
                "p-4",
                "pt-0 md:pt-4",
                {
                  "md:border-l md:border-primary/5": true,
                },
                "flex-shrink-0",
                "gap-6",
                "md:gap-10",
                rightClasses,
              ])}
            >
              {right}
            </div>
          </MediaQuery>
        )}
      </div>
      <MobileNavigation />
    </>
  );
};

export default TwoSidebars;
