import { create } from "zustand";
import {
  API_ENDPOINT_ADD_ACCOUNT,
  API_ENDPOINT_DELETE_ACCOUNT,
  API_ENDPOINT_GET_ACCOUNT,
  API_ENDPOINT_UPDATE_ACCOUNT,
} from "../helpers/constants";
import apiClient from "../helpers/api-client";
import { transformToSelectOptions } from "../helpers/utils";

const useAccountsStore = (set: any, get: any) => ({
  allAccounts: [],
  allCurrenciesUsed: [],
  error: null,
  isLoading: true,

  setAllCurrenciesUsed: () => {
    const a = Array.from(
      new Set(
        get().allAccounts?.map?.((account: any) => account.currency) || []
      )
    );

    set({ allCurrenciesUsed: a });
  },

  registerNewAccount: async ({
    account,
    name,
    bank,
    number,
    currency,
    settings = {},
  }: {
    account: string;
    name: string;
    bank: string;
    number: string;
    currency: string;
    settings: Object;
  }) => {
    return await apiClient
      .post(API_ENDPOINT_ADD_ACCOUNT, {
        account,
        name,
        bank,
        number,
        currency,
        settings,
      })
      .then(async () => {
        return {
          success: true,
        };
      })
      .catch((e: any) => {
        return {
          success: false,
          error: e,
        };
      });
  },

  updateExistingAccount: async ({
    account,
    name,
    bank,
    number,
    currency,
    settings = {},
  }: {
    account: string;
    name: string;
    bank: string;
    number: string;
    currency: string;
    settings: Object;
  }) => {
    return await apiClient
      .put(`${API_ENDPOINT_UPDATE_ACCOUNT}`, {
        account,
        bank,
        name,
        number,
        currency,
        settings,
      })
      .then(async () => {
        return {
          success: true,
        };
      })
      .catch((e: any) => {
        return {
          success: false,
          error: e,
        };
      });
  },

  deleteAccount: async ({ account }: { account: string }) => {
    return await apiClient
      .delete(`${API_ENDPOINT_DELETE_ACCOUNT}/${account}`)
      .then(async (data) => {
        return {
          success: true,
        };
      })
      .catch((e: any) => {
        return {
          success: false,
          error: e,
        };
      });
  },

  getAccount: async ({ account }: { account: string }) => {
    return await apiClient
      .get(`${API_ENDPOINT_GET_ACCOUNT}/${account}`)
      .then((data: any) => {
        return {
          success: true,
          data,
        };
      })
      .catch((e) => {
        return {
          success: false,
          error: e,
        };
      });
  },

  getAccountsListForSelect: () => {
    const allAccounts = get().allAccounts || [];

    if (allAccounts?.length) {
      return transformToSelectOptions({
        data: allAccounts,
        labelField: "name",
        valueField: "account",
      });
    }

    return [];
  },

  getFirstAccountInTheList: () => {
    const accounts = get().allAccounts;

    if (accounts?.length) {
      const [firstAccount] = accounts;

      return firstAccount;
    }

    return null;
  },
});

export default create(useAccountsStore);
