import React, { useCallback, useMemo, useState } from "react";
import DataTable from "react-data-table-component";
import {
  EXPENSE_TYPE,
  ICON_SIZES,
  INCOME_TYPE,
  PAGINATION_MIN,
  PAGINATION_ROWS_PER_PAGE_OPTIONS,
} from "../../helpers/constants";
import { MdArrowDownward, MdFilterAlt, MdFilterAltOff } from "react-icons/md";
import { useTranslation } from "react-i18next";
import CellWithBackground from "../../pages/Transactions/BackgroundCell";

import TransactionTypeCell from "../../pages/Transactions/TransactionTypeCell";
import clsx from "clsx";
import {
  formatNumber,
  getCurrencyDataByCode,
  getPaymentMethodById,
  getTotalByPayTo,
  getUniquePaidToFromTransactions,
} from "../../helpers/utils";
import ButtonGroupCell from "../../pages/Transactions/ButtonGroupCell";
import { useNavigate } from "react-router-dom";
import userPreferences from "../../store/userPreferences";
import useMainFilter from "../../hooks/useMainFilter";
import TableSearch from "../TableSearch/TableSearch";

interface IClientProviderSummaryTable {
  transactions: Array<any>;
  currency: string;
}

const ClientProviderSummaryTable: React.FC<IClientProviderSummaryTable> = ({
  transactions,
  currency,
}) => {
  const [searchTerm, setSearchTerm] = useState<string>("");
  const { tableResultsPerPage, setTableResultsPerPage } = userPreferences();
  const { mainFilter, setMainFilter } = useMainFilter();
  const currencySymbol =
    getCurrencyDataByCode({
      code: currency,
    })?.symbol || "";

  const navigate = useNavigate();

  const availablePaymentMethods = getUniquePaidToFromTransactions({
    transactions,
  });

  const formattedData = useCallback(() => {
    return availablePaymentMethods?.map((paymentMethod: any) => {
      return {
        name: paymentMethod,
        income: getTotalByPayTo({
          type: INCOME_TYPE,
          transactions,
          payTo: paymentMethod,
        }),
        expense: getTotalByPayTo({
          type: EXPENSE_TYPE,
          transactions,
          payTo: paymentMethod,
        }),
        ...getPaymentMethodById(paymentMethod),
      };
    });
  }, [transactions, availablePaymentMethods]);

  const { t } = useTranslation();
  const COLUMNS = [
    {
      cell: (row: any) => {
        return (
          <CellWithBackground
            background={"#34d399"}
            color={"#fff"}
            value={row.name}
            onClick={() => {
              setMainFilter({
                payTo: [row?.name],
              });
              navigate(`/transactions/${mainFilter?.account || ""}`);
            }}
          />
        );
      },
      selector: (row: any) => row.name,
      name: t("transactions_table.origin_destination"),
      sortable: true,
    },
    {
      name: t("shared.income"),
      selector: (row: any) => row.income,
      sortable: true,
      cell: (row: any) => {
        return (
          <TransactionTypeCell currency={currencySymbol} type={INCOME_TYPE}>
            {formatNumber(row.income)}
          </TransactionTypeCell>
        );
      },
      omit: mainFilter?.type === EXPENSE_TYPE,
      right: true,
    },
    {
      name: t("shared.expenses"),
      selector: (row: any) => row.expense,
      sortable: true,
      cell: (row: any) => {
        return (
          <TransactionTypeCell currency={currencySymbol} type={EXPENSE_TYPE}>
            {formatNumber(row.expense)}
          </TransactionTypeCell>
        );
      },
      right: true,
      omit: mainFilter?.type === INCOME_TYPE,
    },
    {
      cell: (row: any) => {
        return (
          <div
            className={clsx(["flex", "w-full", "justify-end", "items-center"])}
          >
            <ButtonGroupCell
              buttons={[
                // {
                //   icon: <MdAttachment size={ICON_SIZES.SM} />,
                //   onClick: () => {},
                //   hide: !row?.attachments?.length,
                // },
                {
                  onClick: (e: any) => {
                    // if (e?.metaKey || e?.altKey) {
                    //   window.open(
                    //     `/transactions/${mainFilter?.account || ""}`,
                    //     "_blank"
                    //   );
                    // } else {
                    //   navigate(`/transactions/${mainFilter?.account || ""}`);
                    // }

                    if (
                      mainFilter?.payTo?.length &&
                      mainFilter?.payTo?.includes(row?.name as never)
                    ) {
                      setMainFilter({
                        payTo:
                          mainFilter?.payTo?.filter?.(
                            (cat) => cat !== row.name
                          ) || [],
                      });
                    } else {
                      setMainFilter({
                        payTo: [row?.name],
                      });
                      // navigate(`/transactions/${mainFilter?.account || ""}`);
                    }
                  },
                  icon:
                    mainFilter?.payTo?.length &&
                    mainFilter?.payTo?.includes(row?.name as never) ? (
                      <MdFilterAltOff size={ICON_SIZES.SM} />
                    ) : (
                      <MdFilterAlt size={ICON_SIZES.SM} />
                    ),
                },
              ]}
            />
          </div>
        );
      },
      selector: (row: any) => row,
      name: "Filtrar",
      sortable: false,
      right: true,
    },
  ];

  const localSearch = useMemo(() => {
    const lowerCaseTerm = searchTerm?.toLowerCase?.()?.trim?.();
    const data = formattedData();

    if (lowerCaseTerm) {
      return (
        data?.filter?.(({ name }: { name: any }) => {
          return name?.toLowerCase?.()?.includes?.(lowerCaseTerm);
        }) || []
      );
    }

    return data;
  }, [formattedData, searchTerm]);

  return (
    <div className={clsx(["flex", "flex-col", "gap-2"])}>
      <TableSearch
        onChange={(value) => setSearchTerm(value)}
        onSubmit={() => {}}
      />
      <DataTable
        columns={COLUMNS}
        data={localSearch}
        pagination={availablePaymentMethods?.length > PAGINATION_MIN}
        highlightOnHover={false}
        persistTableHead
        sortIcon={<MdArrowDownward />}
        selectableRowsVisibleOnly
        paginationRowsPerPageOptions={PAGINATION_ROWS_PER_PAGE_OPTIONS}
        paginationPerPage={tableResultsPerPage}
        customStyles={{
          cells: {
            style: {
              marginRight: "10px",
            },
          },
          headCells: {
            style: {
              marginRight: "10px",
            },
          },
        }}
        paginationComponentOptions={{
          selectAllRowsItem: true,
          selectAllRowsItemText: t("pagination.all"),
          rowsPerPageText: t("pagination.rows_per_page"),
          rangeSeparatorText: t("pagination.separator"),
        }}
        onChangeRowsPerPage={(rows) => {
          setTableResultsPerPage(rows);
        }}
        noDataComponent={
          <div
            className={clsx([
              "px-2",
              "py-4",
              "flex",
              "items-center",
              "justify-center",
              "text-sm",
              "md:text-base",
            ])}
          >
            {t("empty_states.no_records")}
          </div>
        }
        // striped
      />
    </div>
  );
};

export default ClientProviderSummaryTable;
