import clsx from "clsx";
import { ReactElement, useId } from "react";

interface ITextInput {
  label?: string | ReactElement;
  placeholder?: string;
  id?: string;
  required?: boolean;
  value?: string;
  onChange: (e: any) => void;
  type?: string;
  disabled?: boolean;
  readOnly?: boolean;
  autoFocus?: boolean;
  list?: string;
  fontBold?: boolean;
  form?: string;
  onKeyDown?: (e: any) => void;
  className?: string;
  autoComplete?: string;
  step?: number;
  inputMode?:
    | "text"
    | "numeric"
    | "tel"
    | "email"
    | "url"
    | "search"
    | "none"
    | "decimal"
    | undefined;
}

const TextInput: React.FC<ITextInput> = ({
  label,
  placeholder,
  id,
  required,
  value = "",
  onChange,
  onKeyDown = () => {},
  type = "text",
  disabled = false,
  readOnly = false,
  autoFocus = false,
  list = "",
  fontBold = false,
  form = "",
  className = "",
  autoComplete,
  inputMode = "",
  step = 0.1,
}) => {
  const inputAutoId = useId();
  const getInputMode = () => {
    if (inputMode) {
      return inputMode;
    }

    switch (type) {
      case "number": {
        return "numeric";
      }
      case "tel": {
        return "tel";
      }
      case "email": {
        return "email";
      }
      case "url": {
        return "url";
      }
      default: {
        return "text";
      }
    }
  };

  return (
    <div className="flex flex-col w-full">
      {label && (
        <label
          htmlFor={id || inputAutoId}
          className={clsx([
            "text-sm",
            "md:text-base",
            "text-gray-700",
            "font-medium",
          ])}
        >
          {label}
          {required && (
            <>
              {value ? (
                <span className="ml-1 text-red-500">*</span>
              ) : (
                <span className="ml-1 text-red-500">*</span>
              )}
            </>
          )}
        </label>
      )}
      <input
        // x-webkit-speech
        value={value}
        id={id || inputAutoId}
        placeholder={placeholder}
        required={required}
        type={type}
        autoFocus={autoFocus}
        list={list}
        {...(form ? { form } : {})}
        {...(step && type === "number" ? { step } : {})}
        autoComplete={autoComplete}
        className={clsx([
          "text-gray-900",
          "border-solid",
          "border-b-2",
          "text-sm",
          "md:text-base",
          "w-full",
          "h-[40px]",
          "md:h-[46px]",
          "rounded-sm",
          // "focus:border-gray-500",
          "focus:outline-none",
          "placeholder:text-sm",
          "placeholder:font-light",
          "appearance-none",
          "min-w-full",
          "bg-white",
          "px-1",
          "md:px-2",
          {
            "border-red-300": required && !value,
            "border-gray-400": required && value,
            "placeholder:text-gray-500": !required,
            "placeholder:text-red-500": required,
            "border-gray-300": !required,
            "font-light": !fontBold,
          },
          className,
        ])}
        onChange={onChange}
        disabled={disabled}
        readOnly={readOnly}
        onKeyDown={onKeyDown}
        inputMode={getInputMode() as any}
      />
    </div>
  );
};

export default TextInput;
