import { create } from "zustand";
import {
  API_ENDPOINT_ADD_INVOICE,
  API_ENDPOINT_UPDATE_INVOICE,
} from "../helpers/constants";
import apiClient from "../helpers/api-client";

const transactionsStore = (set: any, get: any) => ({
  allTransactionsList: [],
  filters: {
    main: {
      dateFrom: "",
      dateTo: "",
      type: "",
      status: [],
      payTo: [],
      account: "",
      searchTerm: "",
      paymentMethod: [],
      tags: [],
      category: [],
      currency: [],
    },
    year: {
      selectedYear: "",
    },
    currency: {
      selectedCurrency: "",
    },
    lastSevenDaysTransactions: {
      selectedDay: "",
    },
  },
  lastUsedAccount: "",
  statsChartIsActive: false,
  errorSearchingTransactions: false,
  isSearchingTransactions: false,

  setFilter: ({ name, values }: { name: string; values: any }) => {
    const leFilters = {
      ...get().filters,
      [name]: {
        ...get().filters[name],
        ...values,
      },
    };
    set({ filters: leFilters });
  },

  setLastUsedAccount: ({ account }: { account: string }) => {
    set({ lastUsedAccount: account });
  },

  updateTransactions: async ({
    transactionIds,
    params,
  }: {
    transactionIds: Array<string>;
    params: any;
  }) => {
    return await apiClient
      .put(API_ENDPOINT_UPDATE_INVOICE, {
        transactionIds: JSON.stringify(transactionIds),
        params,
      })
      .then((data) => {
        return {
          error: false,
          success: true,
          data,
        };
      })
      .catch((error) => {
        return {
          success: false,
          error,
          data: [],
        };
      });
  },

  moveTransactionsToAccount: async ({
    to,
    transactions,
  }: {
    to: string;
    transactions: Array<string>;
  }) => {
    return await get()
      .updateTransactions({
        transactionIds: transactions,
        params: {
          account: to,
          shared: false,
        },
      })
      .then((result: any) => {
        return {
          error: false,
          success: true,
          data: result,
        };
      })
      .catch(() => {
        return {
          success: false,
          data: [],
          error: true,
        };
      });
  },

  archiveTransactions: async ({
    transactionIds,
  }: {
    transactionIds: Array<string>;
  }) => {
    let hasError = false;

    try {
      const result = await get().updateTransactions({
        transactionIds,
        params: {
          archived: true,
        },
      });

      return {
        error: false,
        success: true,
        data: result,
      };
    } catch (_e) {
      hasError = true;
    }

    return {
      success: !hasError,
      data: [],
      error: hasError,
    };
  },

  unarchiveTransactions: async ({
    transactionIds,
  }: {
    transactionIds: Array<string>;
  }) => {
    let hasError = false;

    try {
      const result = await get().updateTransactions({
        transactionIds,
        params: {
          archived: false,
        },
      });

      return {
        error: false,
        success: true,
        data: result,
      };
    } catch (_e) {
      hasError = true;
    }

    return {
      success: !hasError,
      data: [],
      error: hasError,
    };
  },

  saveTransaction: async ({
    transactionId,
    params,
  }: {
    transactionId: string;
    params: any;
  }) => {
    return await apiClient
      .post(API_ENDPOINT_ADD_INVOICE, {
        invoice: transactionId,
        ...params,
      })
      .then((data) => {
        return {
          error: false,
          success: true,
          data,
        };
      })
      .catch((error) => {
        return {
          success: false,
          error,
        };
      });
  },

  getTransactionsNavigation: () => {},
});

export default create(transactionsStore);
