import { useQuery, useQueryClient } from "@tanstack/react-query";
import { getAllUsers } from "../api/users";
import _ from "lodash";
import { CACHE_KEYS } from "../helpers/constants";

export default function useUsers() {
  const queryClient = useQueryClient();

  const users = useQuery({
    queryFn: async () => await getAllUsers(),
    queryKey: [CACHE_KEYS.ALL_USERS],
  });

  const getUserById = ({ _id }: { _id: string }) => {
    return _.find(users?.data?.data, {
      _id,
    });
  };

  const resetCache = async () => {
    return await queryClient.refetchQueries({
      queryKey: [CACHE_KEYS.ALL_USERS],
    });
  };

  return {
    allUserList: users,
    getUserById,
    resetCache,
  };
}
