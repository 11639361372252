import clsx from "clsx";
import BlocksNavigation from "../Navigation/BlocksNavigation";
import { useTranslation } from "react-i18next";
import {
  MdArrowDownward,
  MdArrowUpward,
  MdCompareArrows,
  MdQueryStats,
} from "react-icons/md";
import { useLocation } from "react-router-dom";
import useMainFilter from "../../hooks/useMainFilter";
import { ICON_SIZES } from "../../helpers/constants";

const MobileNavigation: React.FC = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const { mainFilter } = useMainFilter();

  const isActiveLink = (link: string) => pathname?.startsWith(link);

  return (
    <div
      className={clsx([
        "fixed",
        "bottom-0",
        "right-0",
        "left-0",
        "bg-[#212f3c]/95",
        "py-1 px-2",
        "md:hidden",
        "z-40",
        "border-t-0 border-t-gray-100",
      ])}
    >
      <div
        className={clsx([
          "justify-center",
          "item-center",
          "flex",
          "w-full",
          "z-40",
          "gap-2",
          "flex-col",
        ])}
      >
        <BlocksNavigation
          links={[
            {
              link: `/reports/${mainFilter?.account || ""}`,
              text: t("menu.reports"),
              icon: <MdQueryStats size={ICON_SIZES.SM} />,
              activeClasses: clsx(["!text-cyan-500"]),
              active: isActiveLink("/reports"),
            },
            {
              link: `/transactions/${mainFilter?.account || ""}`,
              text: t("menu.transactions"),
              icon: <MdCompareArrows size={ICON_SIZES.SM} />,
              activeClasses: clsx(["!text-purple-500"]),
              active: [
                "/transactions",
                `/transactions/${mainFilter?.account}`,
              ].includes(pathname),
            },
            {
              link: "/transactions/income",
              text: t("menu.income"),
              icon: <MdArrowDownward size={ICON_SIZES.SM} />,
              activeClasses: clsx(["!text-green-500"]),
              active: isActiveLink("/transactions/income"),
            },
            {
              link: "/transactions/expense",
              text: t("menu.expense"),
              icon: <MdArrowUpward size={ICON_SIZES.SM} />,
              activeClasses: clsx(["!text-red-500"]),
              active: isActiveLink("/transactions/expense"),
            },
          ]}
        />
        <div
          className={clsx([
            "flex",
            "w-full",
            "h-[5px]",
            "bg-gray-800/0",
            "rounded-full",
            "overflow-hidden",
            "hidden",
          ])}
        />
      </div>
    </div>
  );
};

export default MobileNavigation;
